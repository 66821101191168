import BaseFormModal from '@/common/components/BaseFormModal';
import useCurrentLoginUserInfo from '@/hooks/useCurrentLoginUserInfo';
import AddForm from '@/pages/masterDataManagement/labelAdmin/components/AddForm';
import {
  apiAddPatientTag,
  apiLabelsAdd, apiLabelsDelete,
  apiLabelsList,
  apiLabelsUpdate,
} from '@/services/masterData';
import {
  Button,
  Checkbox,
  Input,
  Modal,
  Space,
  message,
} from '@sinohealth/butterfly-ui-components';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import styles from './index.less';
import { useLocales } from '@/Locales';

const AddPatientTag = (props: any, ref: any) => {
  const { selectedData = [], patientId, onAdded } = props;
  const formModal = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([...selectedData]);
  const [tagData, setTagData] = useState([]);
  const [addTagText, setAddTagText] = useState<string>();
  const [userInfo] = useCurrentLoginUserInfo();
  const { t } = useLocales();

  useEffect(() => {
    fetchData();
  }, []);

  useImperativeHandle(ref, () => {
    return {
      handleOpen,
    };
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const fetchData = () => {
    apiLabelsList({
      assistantId: userInfo?.user.id,
      isDefault: false,
    }).then((res) => {
      setTagData(res);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = () => {
    return apiLabelsAdd({
      assistantId: userInfo?.user.id,
      name: addTagText,
      weight: 1,
      type: 1,
    }).then(() => {
      message.success(t('创建成功'));
      setAddTagText('');
      fetchData();
    });
  };

  const handleDelete = (item: any) => {
    apiLabelsDelete({
      id: item.id,
    }).then(() => {
      message.success(t('删除成功'));
      fetchData();
    });
  };

  const handleOpenChangeModal = (itemData: any) => {
    formModal.current?.openModal({
      id: itemData.id,
      name: itemData.name,
      type: itemData.type,
      weight: itemData.weight,
    });
  };

  const handleUpdate = (formValue: any) => {
    return apiLabelsUpdate(formValue).then(() => {
      message.success(t('修改成功'));
      fetchData();
    });
  };

  const defaultTags = tagData.filter((item: any) => item.type === 0);
  const selfTags = tagData.filter((item: any) => item.type !== 0);

  const onChange = (v: any) => {
    setSelectedTags(v);
  };

  const handleSubmit = () => {
    // 没有修改选项
    if (selectedTags.length === 0) {
      handleClose();
      return;
    }
    const tags = tagData
      .filter((item: any) => selectedTags.includes(item.id))
      .map((itemData: any) => {
        return {
          labelId: itemData.id,
          name: itemData.name,
          type: itemData.type,
          assistantId: userInfo?.user.id,
          patientId,
        };
      });
    apiAddPatientTag(tags).then(() => {
      handleClose();
      onAdded && onAdded();
    });
  };

  const renderItem = (itemData: any, canEdit: boolean = false) => {
    return (
      <div className={styles.tagItem} key={itemData.id}>
        <Checkbox
          disabled={selectedTags.length >= 3 && !selectedTags.includes(itemData.id)}
          value={itemData.id}
        >
          {itemData.name}
        </Checkbox>
        {canEdit && (
          <div className={styles.action}>
            <Space>
              <a onClick={() => handleOpenChangeModal(itemData)}>{t('修改')}</a>
              <a onClick={() => handleDelete(itemData)}>{t('删除')}</a>
            </Space>
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      <Modal
        width={560}
        title={t('添加病例标签')}
        open={open}
        onOk={handleSubmit}
        onCancel={handleClose}
      >
        <Checkbox.Group defaultValue={selectedTags} className={styles.tagList} onChange={onChange}>
          <div className={styles.listType}>
            <div className="but-title">{t('默认标签')}（{defaultTags.length}）</div>
            <div className={styles.listBox}>{defaultTags.map((d) => renderItem(d))}</div>
          </div>
          <div className={styles.listType}>
            <div className="but-title">{t('自定义标签')}（{selfTags.length}）</div>
            <div className={styles.listBox}>{selfTags.map((d) => renderItem(d, true))}</div>
          </div>
        </Checkbox.Group>
        <div className={styles.footer}>
          <Space.Compact style={{ width: '100%' }}>
            <Input
              value={addTagText}
              onChange={(e: any) => setAddTagText(e.target.value)}
              maxLength={15}
              showCount
              placeholder={t('输入自定义标签名称…')}
            />
            <Button disabled={!addTagText} type="primary" ghost onClick={handleAdd}>
              {t('创建标签')}
            </Button>
          </Space.Compact>
        </div>
      </Modal>
      <BaseFormModal formModal={formModal} FormContent={AddForm} onSubmit={handleUpdate} />
    </>
  );
};

export default React.forwardRef(AddPatientTag);
