import useCurrentLoginUserInfo from '@/hooks/useCurrentLoginUserInfo';
import { CaretDownOutlined, ExclamationCircleFilled, UserOutlined } from '@ant-design/icons';
import { Dropdown, Modal, Space } from '@sinohealth/butterfly-ui-components';
import { useNavigate } from 'react-router-dom';
import styles from './index.less';
import { removeToken } from '@/utils/cookies';
import Image from '@/components/Image';
import { useLocales } from '@/Locales';

const UserInfo = () => {
  const [userInfo] = useCurrentLoginUserInfo();
  const { t } = useLocales();
  const navigate = useNavigate();
  const handleMenuClick = (e: any) => {
    if (e.key === 'userCenter') {
      navigate('/personal/userinfo');
    }
    if (e.key === 'quitLogin') {
      Modal.confirm({
        title: t('确认退出当前账号?'),
        icon: <ExclamationCircleFilled />,
        content: t('退出将转跳登录页，需重新登录才可访问系统'),
        onOk() {
          removeToken();
          navigate('/login');
        },
      });
    }
  };
  const items = [
    {
      key: 'userCenter',
      label: t('个人中心'),
    },
    {
      key: 'quitLogin',
      label: t('退出登录'),
    },
  ];
  return (
    <div className={styles.userInfo}>
      <div className={styles.avtar}>
        {
          userInfo.user?.avatar ? <Image src={userInfo.user?.avatar} /> : <UserOutlined />
        }
      </div>
      <Dropdown menu={{ items, onClick: handleMenuClick }}>
        <Space>
          <span>{userInfo?.user?.realName}</span>
          <CaretDownOutlined style={{ color: 'rgba(11, 33, 69, 0.65)' }} />
        </Space>
      </Dropdown>
    </div>
  );
};

export default UserInfo;
