/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/*
 * @Description: 病历库、共享病例库
 * @Author: likaifeng
 * @Date: 2023-11-29 14:39:28
 * @LastEditTime: 2024-08-15 11:57:39
 * @LastEditors: likaifeng
 */
import { formatToDate } from '@/utils';
import { cstatus } from '@/utils/constant';
import { Badge } from '@sinohealth/butterfly-ui-components';

export const getColumns = (options: any) => {
  const { type, t, renderAction } = options;

  // 默认数据
  const columns: any = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 100,
      render(text: string, record: any, index: number): JSX.Element {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: '主题',
      dataIndex: 'consultationName',
      key: 'consultationName',
      minWidth: 180,
    },
    {
      title: '会诊时间',
      dataIndex: 'createAt',
      key: 'createAt',
      minWidth: 180,
      sorter: (a: any, b: any) => a.createAt - b.createAt,
      render(text: any[]): JSX.Element {
        return <span>{formatToDate(text, 'YYYY-MM-DD HH:mm:ss')}</span>;
      },
    },
    {
      title: '会诊患者',
      dataIndex: 'patientName',
      key: 'patientName',
      minWidth: 180,
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      key: 'action',
      width: 270,
      render: renderAction,
    },
  ];

  // 首页-我要讲病例
  const homeLecture = [
    {
      title: '会诊时间',
      dataIndex: 'createAt',
      key: 'createAt',
      minWidth: 180,
      render(text: any[]): JSX.Element {
        return <span>{formatToDate(text, 'YYYY-MM-DD HH:mm:ss')}</span>;
      },
    },
    {
      title: '会诊主题',
      dataIndex: 'consultationName',
      key: 'consultationName',
      minWidth: 180,
    },
    {
      title: '会诊患者',
      dataIndex: 'patientName',
      key: 'patientName',
      minWidth: 180,
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      key: 'action',
      width: 270,
      render: renderAction,
    },
  ];

  // 首页-进入会议
  const homeLectureConferen = [
    {
      title: '会诊时间',
      dataIndex: 'createAt',
      key: 'createAt',
      minWidth: 180,
      render(text: any[]): JSX.Element {
        return <span>{formatToDate(text, 'YYYY-MM-DD HH:mm:ss')}</span>;
      },
    },
    {
      title: '会诊主题',
      dataIndex: 'consultationName',
      key: 'consultationName',
      minWidth: 180,
    },
    {
      title: '会诊状态',
      dataIndex: 'status',
      key: 'status',
      minWidth: 180,
      render(text: number): JSX.Element {
        const color = ['#4288FF', '#FAAD14', '#E44444', 'rgba(11, 33, 69, 0.25)', '#00B793'];
        return <Badge color={color[text]} text={t(cstatus[text])} />;
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      key: 'action',
      width: 270,
      render: renderAction,
    },
  ];

  switch (type) {
    case 'homeLecture':
      return homeLecture;
    case 'homeLectureConferen':
      return homeLectureConferen;
    default:
      return columns;
  }
};

export const TABS = [
  { value: 1, label: '基本信息', name: 'baseInfo' },
  { value: 2, label: '入院记录', name: 'medicalRecord' },
  { value: 3, label: '出院记录', name: 'hospitalDischargeRecords' },
  { value: 4, label: '影像报告', name: 'imageReport' },
  { value: 5, label: '手术信息', name: 'surgicalRecord' },
  { value: 6, label: '病理报告', name: 'pathologyReport' },
  { value: 7, label: '检验报告', name: 'inspectionReport' },
  { value: 9, label: '基因检测', name: 'geneticTesting' },
  { value: 8, label: '用药记录', name: 'drugRecord' },
  { value: 9, label: '参考文献', name: 'otherInformation' },
];

/** 基本信息 */
export const BaseInfoCheckbox = [
  { key: 'name', label: '姓名', value: 'name' },
  { key: 'gender', label: '性别', value: 'gender' },
  { key: 'age', label: '年龄', value: 'age' },
  { key: 'idCard', label: '身份证', value: 'idCard' },
  { key: 'hospitalName', label: '医院名称', value: 'hospitalName' },
  { key: 'tumorStage', label: '肿瘤分期', value: 'tumorStage' },
  { key: 'latestDiagnosis', label: '最新诊断', value: 'latestDiagnosis' },
  { key: 'history', label: '病史摘要', value: 'history' },
];

/** 入院记录 */
export const MedicalRecordCheckbox = [
  { key: 'recordTime', value: '入院时间' },
  { key: 'imagePath', value: '报告图片' },
  { key: 'chiefComplaint', value: '主诉' },
  { key: 'presentIllness', value: '现病史' },
  { key: 'pastHistory', value: '既往史' },
  { key: 'physicalCheck', value: '体格检查' },
  { key: 'other', value: '其他' },
];

/** 出院记录 */
export const HospitalDischargeRecordsCheckbox = [
  { key: 'dischargeTime', value: '出院时间' },
  { key: 'imagePath', value: '报告图片' },
  { key: 'admitTime', value: '入院时间' },
  { key: 'admitDescription', value: '入院情况' },
  { key: 'treatmentCourse', value: '治疗经过' },
  { key: 'dischargeDescription', value: '出院情况' },
  { key: 'dischargeAdvice', value: '出院医嘱' },
];

/** 影像报告 */
export const ImageReportCheckbox = [
  { key: 'recordTime', value: '检查时间' },
  { key: 'reportImage', value: '报告图片/视频' },
  { key: 'imagesType', value: '影像类型' },
  { key: 'parts', value: '检查部位' },
  { key: 'zhWedcmTask', value: 'DICOM' },
  { key: 'dicomQrcodeUrl', value: 'DICOM预览链接' },
  { key: 'reportDescription', value: '检查所见' },
  { key: 'reportDiagnose', value: '检查结论' },
  { key: 'remarks', value: '备注' },
];

/** 手术信息 */
export const SurgicalRecordCheckbox = [
  { key: 'recordTime', value: '手术时间' },
  { key: 'reportImage', value: '报告图片' },
  { key: 'surgeryName', value: '手术名称' },
  { key: 'procedures', value: '手术经过' },
  { key: 'seenDuringSurgery', value: '术中所见' },
];

/** 病理报告 */
export const PathologyReportCheckbox = [
  { key: 'recordTime', value: '检查时间' },
  { key: 'imagePath', value: '报告图片' },
  { key: 'summary', value: '病理概述' },
  { key: 'tumorStaging', value: '病理分期' },
  { key: 'immunohistochemistry', value: '免疫组化' },
  { key: 'other', value: '其他信息' },
];

/** 检验报告 */
export const InspectionReportCheckbox = [
  { key: 'recordTime', value: '检验时间' },
  { key: 'imagePath', value: '报告图片' },
  { key: 'zhInspectionItems', value: '检验项目' },
  { key: 'type', value: '检验项目类别' },
  { key: 'tumorMarkers', value: '指标名称' },
  { key: 'unit', value: '单位' },
  { key: 'resultValueItem', value: '结果' },
  { key: 'referenceItem', value: '参考区间' },
  { key: 'description', value: '描述' },
];

/** 用药记录 */
export const DrugRecordCheckbox = [
  { key: 'recordTime', value: '医嘱时间' },
  { key: 'imagePath', value: '报告图片' },
  { key: 'useTime', value: '用药时期' },
  { key: 'drugPlan', value: '用药方案' },
  { key: 'adverseReactions', value: '不良反应' },
  { key: 'treatmentEffect', value: '治疗效果' },
];

/** 基因检测 */
export const GeneticTestingCheckbox = [
  { key: 'recordTime', value: '检查时间' },
  { key: 'imagePath', value: '报告图片' },
  { key: 'description', value: '结果描述' },
];

/** 参考文献 */
export const OtherInformationCheckbox = [
  { key: 'informationDate', value: '资料时间' },
  { key: 'reportImage', value: '报告图片' },
  { key: 'informationType', value: '资料类型' },
  { key: 'description', value: '描述' },
];

/** 详细内容的时间字段 */
export const MainDetailTime: any = {
  medicalRecord: '入院时间',
  hospitalDischargeRecords: '出院时间',
  imageReport: '检查时间',
  surgicalRecord: '手术时间',
  pathologyReport: '检查时间',
  inspectionReport: '检查时间',
  drugRecord: '医嘱时间',
  geneticTesting: '检查时间',
  otherInformation: '资料时间',
};

export default {
  getColumns,
};
