import React, { useState, useEffect } from 'react';
import { Modal, Table, Button } from '@sinohealth/butterfly-ui-components';
import { getPatientScoreDetails } from '@/services/patient';
import useCurrentLoginUserInfo from '@/hooks/useCurrentLoginUserInfo';
import { useLocales } from '@/Locales';

const PatientScore = (props: { patientId: string, value: string, style?: any }) => {
  const { patientId, value, style = {} } = props;
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState([]);
  const [userInfo] = useCurrentLoginUserInfo();
  const { t } = useLocales();

  useEffect(() => {
    setDetails([]);
  }, [patientId]);

  const handleChangeOpen = () => {
    const newOpen = !open;
    setOpen(newOpen);
    if (newOpen && details.length === 0) {
      fetchPatientScoreDetails();
    }
  };

  const fetchPatientScoreDetails = () => {
    getPatientScoreDetails({
      patientId,
      userId: userInfo.user.id,
    }).then((res) => {
      setDetails(res);
    });
  };

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render(text: string, record: any, index: number): JSX.Element {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: '评分指标',
      dataIndex: 'indicatorName',
      key: 'indicatorName',
    },
    {
      title: '指标权重',
      dataIndex: 'weight',
      key: 'weight',
      width: 100,
      render(text: string): JSX.Element {
        return <span>{`${text}%`}</span>;
      },
    },
    {
      title: '得分',
      dataIndex: 'score',
      key: 'score',
      width: 80,
    },
  ].map((item) => ({ ...item, title: t(item.title) }));
  return (
    <>
      <a onClick={handleChangeOpen} style={style}>{value}</a>
      <Modal width={640} title={t('病历评分详情')} open={open} onCancel={handleChangeOpen} footer={(<Button onClick={handleChangeOpen}>{t('关闭')}</Button>)}>
        <Table size="small" bordered dataSource={details} columns={columns} pagination={false} />
      </Modal>
    </>
  );
};
export default PatientScore;
