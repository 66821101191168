import React, { createContext, useContext, useMemo } from 'react';
import { Form as AntdForm, Row, Col, FormItemProps } from '@sinohealth/butterfly-ui-components';
import useDictOption from '@/hooks/dict/useDictOptions';
import useDict from '@/hooks/dict/useDict';
import { isNull } from '@/utils/validate';
import dayjs from 'dayjs';
import styles from './index.less';
import { useLocales } from '@/Locales';

interface MyFormItemProps extends FormItemProps {
  span?: number;
  dictKey?: string;
  children?: any;
  isDate?: boolean;
  transformText?: (val: any) => any;
}
const formatDate = (value: any) => {
  if (value) {
    // @ts-ignore
    return Array.isArray(value) ? value.map((v: any) => dayjs(v)) : dayjs(value);
  }
  return value;
};
const formatDateToString = (value: any) => {
  if (value) {
    // @ts-ignore
    return Array.isArray(value) ? value.map((d: any) => d.format('YYYY-MM-DD')) : value.format('YYYY-MM-DD');
  }
  return value;
};
const RenderDateInput = (props: any) => {
  const { value, onChange, children } = props;
  const defaultProps = {
    style: { width: '100%' },
  };
  return React.cloneElement(children, {
    ...defaultProps,
    ...children.props,
    value: formatDate(value),
    onChange: (v: any) => {
      onChange(formatDateToString(v));
    },
  });
};
const RenderValue = (p: any) => {
  const { value, dictKey, dict, transformText, child } = p;

  const renderDictValue = () => {
    if (Array.isArray(value)) {
      return value.map((v) => {
        return dict[v];
      }).join('，');
    }
    return dict[value] || value;
  };
  const text = dictKey ? renderDictValue() : value;
  if (child) {
    return React.cloneElement(child, {
      readOnly: true,
      ...p,
    });
  }
  // eslint-disable-next-line no-nested-ternary
  if (transformText) {
    return transformText(text);
  }
  return isNull(text) ? '--' : text;
};
export const FormItem = (props: MyFormItemProps) => {
  const { children, span = 24, transformText, dictKey, isDate, ...other } = props;
  const { readOnly } = useContext(FormContext);
  const dict = useDict(dictKey);
  const options = useDictOption(dictKey);
  const { t } = useLocales();
  const defaultProps = {
    options,
    style: { width: '100%' },
    placeholder: t('请输入'),
    maxLength: 50,
  };
  let newChildren: any;
  if (isDate) {
    newChildren = <RenderDateInput>{children}</RenderDateInput>;
  } else {
    newChildren = React.cloneElement(children, {
      ...defaultProps,
      ...children.props,
    });
  }
  return (
    <Col span={span}>
      <AntdForm.Item {...other}>
        {
          readOnly ? <RenderValue dictKey={dictKey} dict={dict} transformText={transformText} child={children.props.selfRenderReadOnly ? newChildren : null} /> : newChildren
        }
      </AntdForm.Item>
    </Col>
  );
};
export const FormTitle = (props: any) => {
  const { children, span = 24 } = props;
  return (
    <Col span={span}>
      <div className="but-title">
        {children}
      </div>
    </Col>
  );
};
const formCol: any = {
  labelWrap: true,
};
const FormContext = createContext<any>({});
export const Form = (props: any) => {
  const { readOnly, children, ...other } = props;
  const contextValue = useMemo(() => {
    return {
      readOnly,
    };
  }, [readOnly]);
  return (
    <FormContext.Provider value={contextValue}>
      <AntdForm className={styles.form} colon={false} {...other} {...formCol}>
        { children }
      </AntdForm>
    </FormContext.Provider>
  );
};
Form.useForm = AntdForm.useForm;

// @ts-ignore
export default Form;
