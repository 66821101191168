import { TABS } from '@/pages/speechLecture/hook/config';
import { getLocalStorage } from '@/utils/cookies';
import { atom } from 'recoil';

/**
 * @description: 病历摘要 - 需要记录的 tab，用于快捷预览过滤数据
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechMainDetailTabsAtom = atom<any>({
  key: 'speechMainDetailsTab',
  default: null,
});

/**
 * @description: 右侧详细信息-内容 { id: '', detail: {} }
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechMainDetailsAtom = atom<any>({
  key: 'speechMainDetails',
  default: null,
});

/**
 * @description: 议题是否显示，为了计算高度，提取全局
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechIssueFlagsAtom = atom<any>({
  key: 'speechIssueFlags',
  default: true,
});

/**
 * @description: 演讲稿的字体大小设计
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechFontSizesAtom = atom<any>({
  key: 'speechFontSizes',
  default: 2, // 1 小号(正常) 2 中号(+2) 3 大号(+2)
});

/**
 * @description: 演讲稿的字体大小设计
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechTabEnumsAtom = atom<any>({
  key: 'speechTabEnums',
  default: TABS,
});

/**
 * @description: tab 枚举中文
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:34:55
 */
export const speechTabEnumKeysAtom = atom<any>({
  key: 'speechTabEnumKeys',
  default: {
    baseInfo: '基本信息',
    medicalRecord: '入院记录',
    hospitalDischargeRecords: '出院记录',
    imageReport: '影像报告',
    surgicalRecord: '手术信息',
    pathologyReport: '病理报告',
    inspectionReport: '检验报告',
    drugRecord: '用药记录',
    geneticTesting: '基因检测',
    otherInformation: '参考文献',
    compareImage: '对比图',
    correlationCharts: '趋势图',
  },
});

/**
 * @description: 讲稿大纲的 tab
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechSideTabsAtom = atom<any>({
  key: 'speechSideTabs',
  default: 'baseInfo',
});

/**
 * @description: 讲稿大纲的 tab
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechMakingDetailkKeyAtom = atom<any>({
  key: 'speechMakingDetailkKey',
  default: null,
});

/**
 * @description: 是否隐藏
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechDisabledsAtom = atom<any>({
  key: 'speechDisableds',
  default: {
    baseInfo: true,
    medicalRecord: true,
    hospitalDischargeRecords: true,
    imageReport: true,
    surgicalRecord: true,
    pathologyReport: true,
    inspectionReport: true,
    drugRecord: true,
    geneticTesting: true,
    otherInformation: true,
  },
});

/**
 * @description: 讲稿左侧菜单
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechSideMenusAtom = atom<any>({
  key: 'speechSideMenus',
  default: [],
});

/**
 * @description: 制作讲稿过滤出的数据
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechFilterMakeDatasAtom = atom<any>({
  key: 'speechFilterMakeDatas',
  default: null,
});

/**
 * @description: 讲稿编辑功能
 * @author: KaifengLi
 * @version: 2.20.0
 * @Date: 2024-08-06 10:40:57
 */
export const speechFilterEditAtom = atom<any>({
  key: 'speechFilterEdit',
  default: false,
});

/**
 * @description: 多少秒后自动更新
 * @author: KaifengLi
 * @version: v2.20.0
 * @Date: 2024-08-08 11:24:54
 */
export const speechUpdateTimeAtom = atom<any>({
  key: 'speechUpdateTime',
  default: 6000,
});

/**
 * @description: 所有的原始数据
 * @author: KaifengLi
 * @version: v2.20.0
 * @Date: 2024-08-06 14:12:58
 */
export const speechOriginDataAtom = atom<any>({
  key: 'speechOriginData',
  default: {
    baseInfo: null,
    medicalRecord: null,
    hospitalDischargeRecords: null,
    imageReport: null,
    surgicalRecord: null,
    pathologyReport: null,
    inspectionReport: null,
    drugRecord: null,
    geneticTesting: null,
    otherInformation: null,
  },
});

/**
 * @description: 存储所有的数据
 * @author: KaifengLi
 * @version: 2.20.0
 * @Date: 2024-08-06 11:24:27
 */
export const speechEditMenuObjAtom = atom<any>({
  key: 'speechEditMenuObj',
  default: {
    baseInfo: null,
    medicalRecord: null,
    hospitalDischargeRecords: null,
    imageReport: null,
    surgicalRecord: null,
    pathologyReport: null,
    inspectionReport: null,
    drugRecord: null,
    geneticTesting: null,
    compareImage: null,
    correlationCharts: null,
    otherInformation: null,
  },
});

/**
 * @description: 是否正在编辑
 * @author: KaifengLi
 * @version: v2.20.0
 * @Date: 2024-08-12 16:31:36
 */
export const speechEditImageAtom = atom<any>({
  key: 'speechEditImage',
  default: getLocalStorage('EDIT_IMAGES_FLAG') || false,
});

/**
 * @description: 顶部提交时的loading
 * @author: KaifengLi
 * @version: v2.20.0
 * @Date: 2024-08-12 16:45:02
 */
export const speechSaveLoadingAtom = atom<any>({
  key: 'speechSaveLoading',
  default: false,
});
