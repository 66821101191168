import { request } from '@/common/request';
/**
 * @Description: 获取机构配置评分策略
 */
export const httpGetAgencyScoringStrategy = (userId: string) => {
  const url = '/agency/getAgencyScoringStrategy';
  return request.post(url, {
    userId,
  });
};

export default {
  httpGetAgencyScoringStrategy,
};
