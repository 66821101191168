import React, { useEffect, useState, useCallback } from 'react';
import theme from '@/config/theme';
import Empty from '@/components/Empty';
import zhCN from '@sinohealth/butterfly-ui-antd/lib/locale/zh_CN';
import enUS from '@sinohealth/butterfly-ui-antd/lib/locale/en_US';
import { ConfigProvider, Spin } from '@sinohealth/butterfly-ui-components/lib';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';
import { atom, useRecoilState } from 'recoil';
import { useTranslation, Trans } from 'react-i18next';
import lodash from 'lodash';
import enUsData from '@/assets/locales/en_US.json';

import 'moment/locale/zh-cn';

import 'dayjs/locale/zh-cn';
import 'dayjs/locale/en';

dayjs.extend(tz);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Shanghai');
process.env.TZ = 'Asia/Shanghai';

export const getLocales = () => {
  return window.localStorage.getItem('imdt-locales') || (window.navigator.language === 'zh-CN' ? 'zh-cn' : 'en');
};

export const LocalesAtom = atom({
  key: 'locales',
  default: getLocales(),
});

const d = window.localStorage.getItem('localesConfigJson');
export const localesConfigJson: any = d ? JSON.parse(d) : {}; // 为了方便开发，记录一份自动翻译的key

const savaLocalesConfigJson = lodash.debounce(() => {
  window.localStorage.setItem('localesConfigJson', JSON.stringify(localesConfigJson));
}, 2000);

export const useLocales = () => {
  const [locales, setLocales] = useRecoilState(LocalesAtom);

  useEffect(() => {
    setLocalesEntry(locales);
  }, []);

  const handleSetLocales: any = (newLocales: string) => {
    setLocales(newLocales);
    window.localStorage.setItem('imdt-locales', newLocales);
    setLocalesEntry(newLocales);
  };

  const setLocalesEntry = (newLocales: string) => {
    moment.locale(newLocales);
    dayjs.locale(newLocales);
  };

  const translate = useCallback((str, params?: any) => {
    if (typeof str !== 'string') return str;
    let translateStr = str;
    // 翻译成英文
    if (locales === 'en') {
      // @ts-ignore
      const text = enUsData[str];
      if (text) {
        translateStr = text;
      } else {
        localesConfigJson[str] = `${str}(英文)`;
        savaLocalesConfigJson();
      }
    }
    // 解析插值
    if (params) {
      Object.keys(params).forEach((key) => {
        translateStr = translateStr.replace(`{{${key}}}`, params[key]);
      });
    }
    return translateStr;
  }, [locales]);

  return {
    locales,
    changeLocales: handleSetLocales,
    t: translate,
  };
};

const Locales = (props: any) => {
  const { locales } = useLocales();

  return (
    <ConfigProvider theme={theme} locale={locales === 'zh-cn' ? zhCN : enUS} renderEmpty={Empty}>
      {props.children}
    </ConfigProvider>
  );
};

export default Locales;
