import React from 'react';
import AsyncSelect from '@/components/AsyncSelect';
import { getJobTitleList } from '@/services/common';
import { useLocales } from '@/Locales';

const JobTitleSelect = (props: any) => {
  const { t } = useLocales();
  const fetchJobTitleList = (v: string) => {
    return getJobTitleList({
      pageSize: 100,
      currentPage: 1,
      name: v,
    })
      .then((res) => {
        return res.list.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      });
  };
  return <AsyncSelect allowClear {...props} fetchOptions={fetchJobTitleList} placeholder={t('请选择临床职称')} />;
};

export default JobTitleSelect;
