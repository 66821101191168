/* eslint-disable indent */
/*
 * @Description: DMT 地市分布 - 地图
 * @Author: likaifeng
 * @Date: 2023-12-26 16:16:27
 * @LastEditTime: 2024-01-04 11:21:35
 * @LastEditors: likaifeng
 */
import usePermission from '@/hooks/usePermission';
import { Spin } from '@sinohealth/butterfly-ui-components';
import * as echarts from 'echarts';
import React, { useEffect, useRef, useState } from 'react';
import { useProvinceTotal } from '../../hook';
import { chinaMapConfig, mapNames } from '../../hook/config';
import geoJson from '../../hook/geojson';
import EmptyCanvas from '../EmptyCanvas';
import styles from './index.less';
import { useLocales } from '@/Locales';

type MapCanvasProps = {};

/**
 * @description: DMT 地市分布 - 地图
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-26 16:18:52
 */
const MapCanvas: React.FC<MapCanvasProps> = (props) => {
  const mapRef = useRef<any>(null);
  let mapInstance: any = null;

  const [display, setDisplay] = useState('block');
  const { provinceSource, loadingProvinceTotal } = useProvinceTotal();
  const permission = usePermission('mdtStatistics');
  const { t, locales } = useLocales();

  console.log('provinceSource');
  console.log(provinceSource);
  const renderMap = () => {
    const renderedMapInstance = echarts.getInstanceByDom(mapRef?.current);
    if (renderedMapInstance) {
      mapInstance = renderedMapInstance;
    } else {
      mapInstance = echarts.init(mapRef?.current);
    }
    const keys = Object.keys(mapNames);
    const mapData = provinceSource?.provinceData?.map((el: any) => {
      return {
        name: locales === 'en' ? mapNames[el.provinceName] : el?.provinceName,
        value: el?.hospitalNum,
        doctorNum: el?.doctorNum,
      };
    }) || [];
    // 合并地图数据，有数据的省份取数据，没有的设置成0
    const mergeData = keys.map((key) => {
      const name = locales === 'en' ? mapNames[key] : key;
      const d = mapData.filter((item: any) => item.name === name);
      return d[0] || {
        name,
        value: 0,
        doctorNum: 0,
      };
    });
    mapInstance.setOption(
      chinaMapConfig({
        data: mergeData,
        // max: resData.max,
        min: 0,
        t,
        locales,
      }),
    );
  };

  useEffect(() => {
    echarts.registerMap('china', { geoJSON: geoJson } as any);
  }, []);

  useEffect(() => {
    window.onresize = function () {
      mapInstance.resize();
    };
    return () => {
      mapInstance && mapInstance.dispose();
    };
  }, []);

  useEffect(() => {
    renderMap();
    mapInstance && mapInstance.resize();
  }, [mapInstance, provinceSource, t]);

  useEffect(() => {
    if (!permission) return;
    if (!permission?.statisticalProvinceTotal) {
      setDisplay('none');
    } else {
      setDisplay('block');
    }
  }, [permission]);

  return (
    <Spin spinning={loadingProvinceTotal} tip={t('加载中...')}>
      <div className={styles.container} style={{ display }}>
        <div className={styles['chart-title']}>{t('MDT地市分布')}</div>
        <ul className={styles.city}>
          <li className={styles['city-item']}>
            <div className={styles.name}>{t('合作机构')}</div>
            <div className={styles.count}>{provinceSource?.orgNum || 0}</div>
          </li>
          <li className={styles['city-item']}>
            <div className={styles.name}>{t('合作医院')}</div>
            <div className={styles.count}>{provinceSource?.hospitalNum || 0}</div>
          </li>
          <li className={styles['city-item']}>
            <div className={styles.name}>{t('分布省份')}</div>
            <div className={styles.count}>{provinceSource?.provinceNum || 0}</div>
          </li>
          <li className={styles['city-item']}>
            <div className={styles.name}>{t('合作医生')}</div>
            <div className={styles.count}>{provinceSource?.doctorNum || 0}</div>
          </li>
        </ul>
        <div
          className={styles['map-canvas']}
          style={{
            height: 492,
            width: '100%',
          }}
          ref={mapRef}
        />
      </div>
      {!permission?.statisticalProvinceTotal ? (
        <div className={styles.container}>
          <EmptyCanvas permission={permission?.statisticalProvinceTotal} height={644} />
        </div>
      ) : null}
    </Spin>
  );
};

export default MapCanvas;
