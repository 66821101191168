/*
 * @Description: 自定义图片-大屏预览
 * @Author: likaifeng
 * @Date: 2024-01-09 09:45:23
 * @LastEditTime: 2024-01-09 14:42:14
 * @LastEditors: likaifeng
 */
import { download } from '@/services';
import React, { useEffect, useState } from 'react';
import Viewer from 'react-viewer';
import ViewerProps, { ImageDecorator } from 'react-viewer/lib/ViewerProps';

export interface ReactViewerProps extends ViewerProps {
  request?: boolean;
}

/**
 * @description: 自定义图片-大屏预览
 * @author: likaifeng
 * @return {*}
 * @Date: 2024-01-09 09:46:20
 */
const ReactViewer: React.FC<ReactViewerProps> = (props) => {
  const { images, request, onClose, ...other } = props;

  const [visible, setVisible] = useState(false);
  const [imagesFile, setImagesFile] = useState<ImageDecorator[]>([]);

  /**
   * @description: 关闭图片预览
   * @author: likaifeng
   * @return {*}
   * @Date: 2024-01-09 14:32:52
   */
  const handleCloseViewer = () => {
    setVisible(false);
    onClose && onClose();
  };

  /**
   * @description: 根据key下载图片地址
   * @author: likaifeng
   * @param {ImageDecorator} img
   * @return {*}
   * @Date: 2024-01-09 14:32:37
   */
  const handleDownloadImage = (img: ImageDecorator[]) => {
    Promise.all(
      img.map((el) => {
        return download(el.src);
      }),
    ).then((res) => {
      const dd: any[] = res.map((el) => {
        return {
          src: el,
        };
      });
      setImagesFile(dd);
      setVisible(dd.length > 0);
    });
  };

  useEffect(() => {
    if (!images?.length) return;
    if (!request) {
      // 无需请求下载图片地址接口
      setVisible(images?.length > 0);
      // console.log(images);
      setImagesFile(images);
    } else {
      // console.log('需要请求下载图片地址接口');
      handleDownloadImage(images);
    }
  }, [images, request]);

  return (
    <div>
      <Viewer visible={visible} images={imagesFile} onClose={handleCloseViewer} {...other} />
    </div>
  );
};

export default ReactViewer;
