import React, { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
// @ts-ignore
import Sortable from 'sortablejs';
import { HolderOutlined } from '@ant-design/icons';
import { Space, Popconfirm } from '@sinohealth/butterfly-ui-components';
import styles from './index.less';
import usePermission from '@/hooks/usePermission';
import { PatientInfoStatus } from '@/pages/patient/detail';
import Text from '@/components/Text';

const SortList = (props: any) => {
  const { datasource = [], onSorted, onEdit, onDelete } = props;
  const listDom = useRef<any>();

  const permission = useRecoilValue(PatientInfoStatus);

  useEffect(() => {
    if (permission.edit) {
      initSortable();
    }
  }, [permission]);
  const initSortable = () => {
    const el: any = listDom.current;
    if (!el) {
      return;
    }
    let isSortIng = false;
    const save = _.debounce(() => {
      if (isSortIng) {
        return;
      }
      const ids = [];
      for (let i = 0; i < el.children.length; i += 1) {
        const item = el.children[i];
        if (item.id) {
          ids.push({
            id: item.id,
            sort: i,
          });
        }
      }
      handleSaveSort(ids);
    }, 2000);
    // eslint-disable-next-line no-new
    new Sortable(el, {
      handle: '.handle',
      onStart() {
        isSortIng = true;
      },
      onEnd() {
        isSortIng = false;
        save();
      },
    });
  };
  const handleSaveSort = (ids: any) => {
    onSorted(ids);
  };
  return (
    <div ref={listDom} className={styles.sortList}>
      {
        datasource.map((item: any) => {
          return (
            <div className={styles.item} id={item.id} key={item.id}>
              <div className={styles.header}>
                <span className="handle">
                  <HolderOutlined />
                </span>
                {item.title}
                {
                  permission.edit && (
                    <span className={styles.action}>
                      <Space size={12}>
                        <a onClick={() => onEdit && onEdit(item)}><Text>编辑</Text></a>
                        <Popconfirm
                          title={<span><Text>确认删除数据</Text>?</span>}
                          onConfirm={() => onDelete && onDelete(item)}
                          okText={<Text>移除</Text>}
                          cancelText={<Text>取消</Text>}
                        >
                          <a style={{ color: 'red' }}><Text>删除</Text></a>
                        </Popconfirm>
                      </Space>
                    </span>
                  )
                }
              </div>
              <div className={styles.body}>
                {item.content}
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default SortList;
