import { Form, Input } from '@sinohealth/butterfly-ui-components/lib';
import styles from './index.less';
import { useLocales } from '@/Locales';

const FormModalContent = (props: any) => {
  const { form } = props;
  const { t } = useLocales();

  return (
    <div className={styles.addForm}>
      <Form {...props} labelCol={{ flex: '0 0 90px' }}>
        <Form.Item
          label={t('登录密码')}
          name="password"
          rules={[{ required: true, message: t('请输入登录密码') }]}
        >
          <Input.Password autoComplete="new-password" placeholder={t('请输入登录密码')} />
        </Form.Item>
        <Form.Item
          label={t('上次登录时间')}
          name="lastLoginTime"
        >
          <Input disabled autoComplete="new-password" placeholder="--" />
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormModalContent;
