/*
 * @Description: 讲稿演示-主要内容-时间轴
 * @Author: likaifeng
 * @Date: 2023-11-30 16:31:41
 * @LastEditTime: 2023-12-03 20:28:13
 * @LastEditors: likaifeng
 */
import React from 'react';
import styles from './index.less';

/**
 * @description: 讲稿演示-主要内容-时间轴
 * @author: likaifeng
 * @param {*} props
 * @return {*}
 * @Date: 2023-12-04 14:28:09
 */
const SpeechStep: React.FC<any> = (props) => {
  return (
    <div id={props?.id} className={styles.step}>
      <div className={styles['step-header']}>
        <span className={styles['step-icon']} />
        <span className={styles['step-title']}>{props.time}</span>
      </div>
      {props.children ? <div className={styles['step-container']}>{props.children}</div> : null}
    </div>
  );
};

export default SpeechStep;
