import { useState, useEffect } from 'react';
import useCurrentLoginUserInfo from '@/hooks/useCurrentLoginUserInfo';
import localCodeNames from './permissionCodes';

export const usePermission = (pageName?: string): any => {
  const [userInfo] = useCurrentLoginUserInfo();
  const [permissionData, setPermissionData] = useState<any>({});

  useEffect(() => {
    if (userInfo?.menuList) {
      transFormCodes();
    }
  }, [userInfo]);

  // 根据localCodeNames结构组织权限树
  const transFormCodes = () => {
    const codeData = userInfo.menuList.map((item: any) => item.resources);
    const mapPages = (codeObj: any): any => {
      const perData: any = {};
      const codeItems = Object.keys(codeObj);
      codeItems.forEach((codeKey) => {
        if (typeof codeObj[codeKey] === 'string') {
          perData[codeKey] = codeData.includes(codeObj[codeKey]);
        } else {
          perData[codeKey] = mapPages(codeObj[codeKey]);
        }
      });
      return perData;
    };
    const newPermissionData = mapPages(localCodeNames);
    setPermissionData(newPermissionData);
  };

  return pageName ? (permissionData[pageName] || {}) : permissionData;
};

export default usePermission;
