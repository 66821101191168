import TumorMarkersSelect from '@/components/TumorMarkersSelect';
import { useLocales } from '@/Locales';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Form, Input, Radio } from '@sinohealth/butterfly-ui-components';
import styles from './index.less';

const requiredRule = { required: true, message: '该字段为必填项' };

const InspectionItem = (props: any) => {
  const { remove, name, ...restField } = props;
  const form = Form.useFormInstance();
  const type = Form.useWatch(['zhInspectionItems', name, 'type'], form);
  const { t } = useLocales();

  const handleSelectTumorMarkers = (val: any, options: any) => {
    const formValue = form.getFieldsValue();
    const zhInspectionItems = formValue.zhInspectionItems;
    zhInspectionItems[name] = {
      ...zhInspectionItems[name],
      unit: options.unit,
      referenceItem: options.referenceItem,
    };
    form.setFieldsValue({
      zhInspectionItems,
    });
  };
  return (
    <div className={styles.item}>
      <MinusCircleOutlined
        style={{ fontSize: 24 }}
        className={styles.removeBtn}
        onClick={() => remove(name)}
      />
      <Form.Item {...restField} name={[name, 'type']} label={t('检验项目')} rules={[requiredRule]}>
        <Radio.Group>
          <Radio value="0">{t('肿瘤标志物')}</Radio>
          <Radio value="1">{t('其他')}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        {...restField}
        name={[name, 'tumorMarkers']}
        label={t('指标名称')}
        rules={[requiredRule]}
      >
        {type === '0' ? (
          <TumorMarkersSelect onSelect={handleSelectTumorMarkers} />
        ) : (
          <Input placeholder={t('请输入指标名称')} />
        )}
      </Form.Item>
      <Form.Item {...restField} name={[name, 'unit']} label={t('单位')}>
        <Input placeholder={t('请输入单位')} disabled={type === '0'} />
      </Form.Item>
      <Form.Item
        {...restField}
        name={[name, 'resultValueItem']}
        label={t('结果')}
        rules={[requiredRule]}
      >
        <Input placeholder={t('请输入结果')} showCount maxLength={100} />
      </Form.Item>
      <Form.Item
        {...restField}
        name={[name, 'referenceItem']}
        label={t('参考区间')}
        rules={[requiredRule]}
      >
        <Input placeholder={t('请输入参考区间')} showCount maxLength={100} />
      </Form.Item>
    </div>
  );
};

export default InspectionItem;
