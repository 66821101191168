import OcrInfo from '@/pages/patient/detail/components/OcrInfo';
import { OcrContext } from '@/pages/patient/detail/components/PatientTabs/components/TabItem';
import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.less';

const OcrFlex = (props: any) => {
  const [ocrConfig, setOcrConfig] = useState<any>({});

  const ocrContextValue = useMemo(() => {
    return {
      ocrConfig,
      setOcrConfig,
    };
  }, [ocrConfig, setOcrConfig]);

  const containerRef = useRef<HTMLDivElement>(null); // 新增 ref 用于引用 DOM 节点
  const [height, setHeight] = useState<number>(0); // 新增状态用于保存高度
  useEffect(() => {
    const currentContainer: any = containerRef.current;
    if (currentContainer.hasChildNodes()) {
      setHeight(currentContainer?.firstChild?.offsetHeight); // 设置高度
    } else {
      setHeight(currentContainer.offsetHeight); // 设置高度
    }
  }, [containerRef, ocrConfig, props?.change]); // 当 ref 更新时重新计算高度

  return (
    <OcrContext.Provider value={ocrContextValue}>
      <div className={styles.flex}>
        <div className={styles.left} ref={containerRef}>
          {props?.children}
        </div>
        <OcrInfo ocrConfig={ocrConfig} height={height - 90} />
      </div>
    </OcrContext.Provider>
  );
};

export default OcrFlex;
