/* eslint-disable indent */
/*
 * @Description: 病例分析
 * @Author: likaifeng
 * @Date: 2023-12-29 09:53:37
 * @LastEditTime: 2024-01-04 13:45:55
 * @LastEditors: likaifeng
 */
import usePermission from '@/hooks/usePermission';
import { Col, Row } from '@sinohealth/butterfly-ui-components';
import React from 'react';
import { useAge, useGeneAndPathology, useImage, useSex } from '../../hook';
import {
  defaultBarSeriesColor,
  defaultLineOption,
  defaultPieOption,
  defaultPieSeries,
} from '../../hook/config';
import { ImdtChartOption } from '../../hook/echartType';
import styles from '../../index.less';
import CasesIncreaseTrend from '../CasesIncreaseTrend';
import ImdtChart from '../ImdtChart';
import useDict from '@/hooks/dict/useDict';
import { useLocales } from '@/Locales';

const keyName: any = {
  bothCheck: '两项都做',
  bothUncheck: '均未检测',
  genetic: '基因检测',
  pathology: '免疫组化',
};

const typeName: any = {
  1: 'CT',
  2: 'X光',
  3: 'US',
  4: 'MRI',
  5: 'PET-CT',
  6: '其他',
};

/**
 * @description: 病例分析
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-29 09:54:11
 */
const CaseAnalysis: React.FC = () => {
  const { ageSource, loadingAge } = useAge();
  const { sexSource, loadingSex } = useSex();
  const { geneAndPathologySource, loadingPathology } = useGeneAndPathology();
  const { imageSource, loadingImage } = useImage();
  const permission = usePermission('mdtStatistics');
  const { t, locales } = useLocales();

  /** 性别 配置 */
  const options: ImdtChartOption = {
    ...defaultPieOption(),
    // 数据
    series: [
      {
        ...defaultPieSeries,
        data: sexSource?.map((el) => {
          return {
            name: el?.sex === 'male' ? t('男') : t('女'),
            value: el?.value,
          };
        }),
      },
    ],
  };

  /** 检测比例 */
  const radiographedOptions: ImdtChartOption = {
    ...defaultPieOption(),
    // 数据
    series: [
      {
        data: geneAndPathologySource
          ? Object.keys(geneAndPathologySource).map((el: string) => {
              return {
                name: t(keyName[el]),
                value: geneAndPathologySource[el],
              };
            })
          : [],
        ...defaultPieSeries,
      },
    ],
  };

  /** 影像类型比例 */
  const imageTypeRatio: ImdtChartOption = {
    ...defaultPieOption(),
    // 数据
    series: [
      {
        data: imageSource.map((el) => {
          return {
            name: t(typeName[el?.type]),
            value: el?.total,
          };
        }),
        ...defaultPieSeries,
      },
    ],
  };

  /** 患者年龄分布 */
  const barOptions: ImdtChartOption = {
    ...defaultLineOption({
      // 整体布局
      grid: [{ left: 40, bottom: 40 }],
      tooltip: {
        formatter: `${t('患者年龄')}: {b0}<br /> ${t('人数')}: {c0}`,
      },
    }),
    xAxis: {
      type: 'category',
      data: ageSource?.map((el) => el?.name),
      axisLine: {
        show: true,
      },
      axisTick: {
        alignWithLabel: true,
      },
    },
    // 数据
    series: [
      {
        data: ageSource?.map((el) => el?.value),
        type: 'bar',
        itemStyle: {
          borderRadius: [4, 4, 0, 0],
          color: (params) => defaultBarSeriesColor(params),
        },
        selectedMode: 'series',
      },
    ],
  };

  return (
    <>
      <div className={styles['statistics-header']}>
        <Row gutter={16}>
          <Col span={18}>
            <CasesIncreaseTrend />
          </Col>
          <Col span={6}>
            <ImdtChart
              loading={loadingAge}
              title={t('患者年龄分布')}
              height={447}
              backgroundImage
              option={barOptions}
              empty={!ageSource.length || !permission?.statisticalAge}
              permission={permission?.statisticalAge}
            />
          </Col>
        </Row>
      </div>
      <div className={styles['statistics-footer']}>
        <Row gutter={16}>
          <Col span={8}>
            <ImdtChart
              loading={loadingSex}
              height={400}
              backgroundImage
              title={t('性别')}
              option={options}
              empty={!sexSource.length || !permission?.statisticalSex}
              permission={permission?.statisticalSex}
            />
          </Col>
          <Col span={8}>
            <ImdtChart
              loading={loadingPathology}
              height={400}
              backgroundImage
              title={t('检测比例')}
              option={radiographedOptions}
              empty={!geneAndPathologySource || !permission?.statisticalPathology}
              permission={permission?.statisticalPathology}
            />
          </Col>
          <Col span={8}>
            <ImdtChart
              loading={loadingImage}
              height={400}
              backgroundImage
              title={t('影像类型比例')}
              option={imageTypeRatio}
              empty={!imageSource.length || !permission?.statisticalImage}
              permission={permission?.statisticalImage}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CaseAnalysis;
