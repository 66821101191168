/* eslint-disable no-shadow */
// MDT议题类型
enum MDT_ISSUES_TYPE {
  // 投票型
  VOTING = 'vote',
  // 讨论型
  DISCUSSION = 'discuss',
}

const LETTER_LIST = ['A', 'B', 'C', 'D', 'E'];

export default {
  MDT_ISSUES_TYPE,
  LETTER_LIST,
};
