/*
 * @Description: 讲稿演示-左侧大纲
 * @Author: likaifeng
 * @Date: 2023-11-30 16:02:22
 * @LastEditTime: 2024-08-13 11:29:08
 * @LastEditors: likaifeng
 */
import { useLocales } from '@/Locales';
import {
  speechEditMenuObjAtom,
  speechMainDetailsAtom,
  speechMainDetailTabsAtom,
  speechSideMenusAtom,
  speechSideTabsAtom,
} from '@/store/speech';
import { Popover } from '@sinohealth/butterfly-ui-components';
import classNames from 'classnames';
import React, { memo, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styles from './index.less';

const SpeechSide = memo(() => {
  const [speechSideMenu, setSpeechSideMenu] = useRecoilState(speechSideMenusAtom);
  const speechEditMenuObj = useRecoilValue(speechEditMenuObjAtom);
  const [speechSideTab, setSpeechSideTab] = useRecoilState(speechSideTabsAtom);
  const setSpeechMainDetailTab = useSetRecoilState(speechMainDetailTabsAtom);
  const setSpeechMainDetail = useSetRecoilState(speechMainDetailsAtom);
  const { t } = useLocales();

  /**
   * @description: 切换左侧讲稿大纲
   * @author: likaifeng
   * @param {string} key
   * @return {*}
   * @Date: 2023-11-30 20:24:45
   */
  const handleChangeSideMenu = (key: string) => {
    setSpeechSideTab(key);
    setSpeechMainDetailTab(null);
    setSpeechMainDetail(null);
    document.querySelector(`#${key}`)?.scrollIntoView();
  };

  useEffect(() => {
    if (speechEditMenuObj) {
      const defaultMenu = [
        { sort: 0, label: 'baseInfo', name: '患者信息' },
        { sort: 1, label: 'patientHistory', name: '病史摘要' },
      ];
      const values = Object.values(speechEditMenuObj);
      const memu: any[] = values.filter((el) => el).sort((a: any, b: any) => a.sort - b.sort);
      // console.log(memu);
      if (memu.length) {
        setSpeechSideMenu(defaultMenu.concat(memu));
      } else {
        setSpeechSideMenu(defaultMenu);
      }
    }
  }, [speechEditMenuObj]);

  return (
    <div className={styles['speech-side']}>
      <div className={styles['side-header']}>
        <div className={styles.side}>
          <i className="iconfont icon-fuzhiwenjian" style={{ marginRight: 5 }} />
          {t('讲稿大纲')}
        </div>
      </div>
      <ul className={styles['side-container']}>
        {speechSideMenu?.map((menu: any, index: number) => (
          <li key={menu?.label} className={styles['side-step']}>
            <div
              id={`menu_${menu?.label}`}
              className={classNames(
                styles['step-title'],
                speechSideTab.indexOf(menu?.label) !== -1 && styles['step-title-active'],
              )}
              onClick={() => handleChangeSideMenu(menu?.label)}
            >
              {index + 1}. {t(menu?.name)}
              {menu?.children ? (
                <span className={styles['title-count']}>{menu?.children.length}</span>
              ) : null}
            </div>
            {menu?.children ? (
              <div className={styles.container}>
                {menu?.children.map((child: any) => (
                  <React.Fragment key={child?.label}>
                    <div
                      key={child?.label}
                      className={classNames(
                        styles['step-item'],
                        speechSideTab === child?.label && styles['step-item-active'],
                      )}
                      onClick={() => handleChangeSideMenu(child?.label)}
                    >
                      {child?.remarks ? (
                        <Popover
                          content={
                            <div style={{ maxWidth: 200, color: '#FFF' }}>
                              {menu?.name === '影像报告' ? t('备注') : t('资料类型')}：
                              {child?.remarks}
                            </div>
                          }
                          placement="rightTop"
                          color="#00244ce6"
                          title={
                            <div style={{ color: '#FFF', fontWeight: 'normal' }}>
                              {`${t(menu?.name)}: ${child?.name}`}
                            </div>
                          }
                        >
                          <div className={classNames(styles.label)}>
                            <span
                              className={classNames(
                                styles.step,
                                speechSideTab === child?.label && styles['step-active'],
                              )}
                            >
                              {child.name}
                            </span>
                            {child?.type ? (
                              <span className={styles['step-ct']}>{child?.type}</span>
                            ) : null}
                          </div>
                          {child.remarks ? (
                            <div
                              className={classNames(
                                styles['label-remarks'],
                                speechSideTab === child?.label && styles['label-remarks-active'],
                              )}
                            >
                              {child?.remarks}
                            </div>
                          ) : null}
                        </Popover>
                      ) : (
                        <div className={classNames(styles.label)}>
                          <span
                            className={classNames(
                              styles.step,
                              speechSideTab === child?.label && styles['step-active'],
                            )}
                          >
                            {child?.name}
                          </span>
                          {child?.type ? (
                            <span className={styles['step-ct']}>{child?.type}</span>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
});

export default SpeechSide;
