/*
 * @Description: 自定义 echart 组件
 * @Author: likaifeng
 * @Date: 2023-12-26 17:08:57
 * @LastEditTime: 2024-01-03 15:55:12
 * @LastEditors: likaifeng
 */
import { Spin } from '@sinohealth/butterfly-ui-components';
import classNames from 'classnames';
import * as echarts from 'echarts/core';
import { EChartsType } from 'echarts/core';
import { ECElementEvent } from 'echarts/types/dist/echarts';
import React, { ForwardedRef, useEffect, useImperativeHandle, useRef } from 'react';
import { ImdtChartProps } from '../../hook/echartType';
import EmptyCanvas from '../EmptyCanvas';
import styles from './index.less';

export interface ImdtChartRef {
  instance(): EChartsType | undefined;
}

/**
 * @description: 自定义 echart 组件
 * @author: likaifeng
 * @param {*} param1
 * @return {*}
 * @Date: 2023-12-26 17:29:18
 */
const ImdtChart: React.ForwardRefRenderFunction<ImdtChartRef, ImdtChartProps> = (
  {
    option,
    style,
    width,
    title,
    titleBorder = true,
    backgroundImage,
    height,
    className,
    permission,
    loading = false,
    empty,
    onClick,
  },
  ref: ForwardedRef<ImdtChartRef>,
): any => {
  const cRef = useRef<HTMLDivElement>(null);
  const cInstance = useRef<echarts.EChartsType>();

  /** 重新适配大小并开启过渡动画 */
  const resize = () => {
    cInstance.current?.resize({
      animation: { duration: 300 },
    });
  };

  /** 获取实例 */
  const instance = () => {
    return cInstance.current;
  };

  /** 对父组件暴露的方法 */
  useImperativeHandle(ref, () => ({
    instance,
  }));

  /** 初始化注册组件，监听 cRef 和 option 变化 */
  useEffect(() => {
    if (cRef.current) {
      // 校验 Dom 节点上是否已经挂载了 ECharts 实例，只有未挂载时才初始化
      cInstance.current = echarts.getInstanceByDom(cRef.current);
      if (!cInstance.current) {
        cInstance.current = echarts.init(cRef.current, undefined, {
          renderer: 'svg',
        });

        // 绑定鼠标点击事件
        cInstance.current.on('click', (event) => {
          const ec = event as ECElementEvent;
          if (ec && onClick) onClick(ec);
        });
      }
      // 设置配置项
      if (option) cInstance.current?.setOption(option);
    }
  }, [cRef, option]);

  /** 监听窗口大小变化重绘 */
  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [option]);

  /** 展示加载中 */
  // useEffect(() => {
  //   if (loading) cInstance.current?.showLoading();
  //   else cInstance.current?.hideLoading();
  // }, [loading]);

  /** 监听高度变化 */
  useEffect(() => {
    resize();
  }, [width, height]);

  return (
    <Spin spinning={loading} tip="数据加载中.">
      <div
        className={classNames(
          styles['imdt-chart'],
          backgroundImage && styles['chart-bgi'],
          className,
        )}
        style={{ ...style, height: '100%' }}
      >
        {title ? (
          <div className={classNames(styles['chart-title'], titleBorder && styles['title-border'])}>
            {title}
          </div>
        ) : null}
        {!empty ? (
          <div ref={cRef} style={{ width: width || '100%', height }} />
        ) : (
          <EmptyCanvas permission={permission} height={height} width={width || '100%'} />
        )}
      </div>
    </Spin>
  );
};

export default React.forwardRef(ImdtChart);
