/*
 * @Description: 讲稿演示-MDT议题
 * @Author: likaifeng
 * @Date: 2023-11-30 19:23:34
 * @LastEditTime: 2023-12-04 14:27:16
 * @LastEditors: likaifeng
 */
import { speechFilterMakeDataAtom, speechIssueFlagAtom } from '@/store/lecture';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styles from './index.less';
import localEnum from '@/hooks/dict/localEnum';
import IssueBarEchart from '@/pages/consultationCenter/components/IssueBarEchart';
import { useIssueVoteStatistics } from '@/pages/speechLecture/hook/make';
import { useLocales } from '@/Locales';

/**
 * @description: 讲稿演示-MDT议题
 * @author: likaifeng
 * @param {*} props
 * @return {*}
 * @Date: 2023-12-04 14:27:11
 */
const SpeechIssue: React.FC<any> = (props: any) => {
  const { consultationId } = props;

  const [beforeSeriesDataMap, setBeforeSeriesDataMap] = useState<any>({});
  const [afterSeriesDataMap, setAfterSeriesDataMap] = useState<any>({});
  const [speechIssueFlag, setSpeechIssueFlag] = useRecoilState(speechIssueFlagAtom);
  const speechFilterMakeData = useRecoilValue(speechFilterMakeDataAtom);
  const { t } = useLocales();

  const { issueVoteStatistics } = useIssueVoteStatistics(consultationId) as any;

  const { MDT_ISSUES_TYPE, LETTER_LIST } = localEnum;

  useEffect(() => {
    if (issueVoteStatistics && issueVoteStatistics?.length > 0) {
      const tempBefore = {} as any;
      const tempAfter = {} as any;
      issueVoteStatistics.forEach((item: any) => {
        if (item.timing === 'before') {
          tempBefore[item.issueId] = {
            percentageResult: item.percentageResult || [],
            peopleResult: item.peopleResult || [],
          };
        } else {
          tempAfter[item.issueId] = {
            percentageResult: item.percentageResult || [],
            peopleResult: item.peopleResult || [],
          };
        }
      });
      if (Object.keys(tempBefore).length) {
        setBeforeSeriesDataMap(tempBefore);
      }
      if (Object.keys(tempAfter).length) {
        setAfterSeriesDataMap(tempAfter);
      }
    }
  }, [issueVoteStatistics]);

  return speechIssueFlag ? (
    <div className={styles['speech-issue']}>
      <div className={styles['issue-header']}>
        <div className={styles.issue}>
          <i className="iconfont icon-fuzhiwenjian" style={{ marginRight: 5 }} />
          {t('MDT议题')}
        </div>
        <i
          className={classNames('iconfont icon-choutizhankai', styles['issue-close'])}
          onClick={() => setSpeechIssueFlag(false)}
        />
      </div>
      <div className={styles['issue-container']}>
        {speechFilterMakeData?.mdtIssues?.map((el: any, index: number) => (
          <div key={el.id} className={styles['issue-item']}>
            <div className={styles['item-header']}>
              <div className={styles['header-icon']}>{t('议题')}{index + 1}</div>
            </div>
            <div className={styles.container}>{el.issue}</div>

            {el.type === MDT_ISSUES_TYPE.VOTING && el.options && (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {el.options.map((opt: string, inx: number) => {
                  const key = `${opt}-${inx.toString()}`;
                  return (
                    <span key={key} style={{ marginRight: '16px' }}>
                      {LETTER_LIST[inx]}.{opt}
                    </span>
                  );
                })}
              </div>
            )}

            {speechFilterMakeData.pushedIssueVote === true &&
              el.type === MDT_ISSUES_TYPE.VOTING && (
                <div>
                  {beforeSeriesDataMap[el.id] && (
                    <div style={{ marginTop: '8px' }}>
                      <IssueBarEchart title="MDT前" seriesData={beforeSeriesDataMap[el.id]} />
                    </div>
                  )}
                  {afterSeriesDataMap[el.id] && (
                    <div style={{ marginTop: '8px' }}>
                      <IssueBarEchart title="MDT后" seriesData={afterSeriesDataMap[el.id]} />
                    </div>
                  )}
                </div>
            )}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className={styles['issue-open']} onClick={() => setSpeechIssueFlag(!speechIssueFlag)}>
      <div>MDT<br />{t('议题')}</div>
      <i className="iconfont icon-choutishouqi" />
    </div>
  );
};

export default SpeechIssue;
