/* eslint-disable react/no-array-index-key */
/*
 * @Description: 医生MDT次数排名TOP10
 * @Author: likaifeng
 * @Date: 2023-12-28 14:53:21
 * @LastEditTime: 2024-01-03 16:12:14
 * @LastEditors: likaifeng
 */
import usePermission from '@/hooks/usePermission';
import { agencyIdsAtom } from '@/store/statistics';
import { Spin } from '@sinohealth/butterfly-ui-components';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useDoctor } from '../../hook';
import EmptyCanvas from '../EmptyCanvas';
import styles from './index.less';
import { useLocales } from '@/Locales';

interface FrequencyTopProps {
  startDate?: string;
  endDate?: string;
  agencyId?: number;
}

/**
 * @description: 医生MDT次数排名TOP10
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-28 14:54:00
 */
const FrequencyTop: React.FC<FrequencyTopProps> = (props) => {
  const { agencyId, startDate, endDate } = props;
  const agencyIds = useRecoilValue(agencyIdsAtom);

  const [doctorParams, setDoctorParams] = useState<any>();

  const { doctorSource, loadingDoctor } = useDoctor(doctorParams);
  const permission = usePermission('mdtStatistics');
  const { t } = useLocales();

  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate).getTime();
      const end = new Date(endDate).getTime();
      const type = end - start > 30 * 24 * 60 * 60 * 1000 ? 0 : 1;
      setDoctorParams({
        ...doctorParams,
        agencyIds: agencyId === 0 ? agencyIds : [agencyId],
        startDate,
        endDate,
        type,
      });
    }
  }, [startDate, endDate, agencyIds, agencyId]);

  return (
    <Spin spinning={loadingDoctor} tip={t('加载中...')}>
      <div className={styles['frequency-top']}>
        <div className={styles.title}>{t('医生MDT次数排名TOP10')}</div>
        <ul className={styles['top-container']} style={{ height: 370 }}>
          {permission?.statisticalDoctor &&
            doctorSource?.map((el, index) => (
              <li key={`${el?.name}_${index}`} className={styles.top}>
                <div className={classNames(styles.rank, styles[`rank-${index + 1}`])}>
                  {index + 1}
                </div>
                <div className={styles.name}>{el?.doctorName}</div>
                <div className={styles.count}>{el?.total}</div>
              </li>
            ))}
          {!doctorSource.length || !permission?.statisticalDoctor ? (
            <EmptyCanvas height={370} permission={permission?.statisticalDoctor} />
          ) : null}
        </ul>
      </div>
    </Spin>
  );
};

export default FrequencyTop;
