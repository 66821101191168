import React, { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Input, Form as AntdForm, Select } from '@sinohealth/butterfly-ui-components';
import { Form, FormItem } from '@/components/BaseForm';
import TabHeader, { TabHeaderInput } from '@/pages/patient/detail/components/TabHeader';
import Label from '@/components/Label';
import RecordImgUpload from '@/pages/patient/detail/components/RecordImgUpload';
import {
  addPatientImages,
  addPatientMedical,
  createOrUpdateDischarge,
  updatePatientImages,
  updatePatientMedical,
} from '@/services/patient';
import { PatientInfoAtom, PatientInfoStatus } from '@/pages/patient/detail';
import moment from 'moment';
import DicomUpload from '@/pages/patient/detail/components/DicomUpload';
import DicomLink from '@/pages/patient/detail/components/DicomLink';
import classnames from 'classnames';
import styles from './index.less';
import { useLocales } from '@/Locales';

const ImageReportsTab = (props: any) => {
  const { data } = props;
  const [form] = AntdForm.useForm();
  const [readOnly, setReadOnly] = useState(false);
  const patientInfo = useRecoilValue(PatientInfoAtom);
  const [patientInfoStatus, setPatientInfoStatus] = useRecoilState(PatientInfoStatus);
  const { t } = useLocales();

  useEffect(() => {
    setReadOnly(!!data?.id);
  }, []);

  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    return () => {
      // 组件销毁时，如果是编辑状态，把全局的可编辑状态还原
      if (isEditing) {
        setPatientInfoStatus({
          edit: true,
        });
      }
    };
  }, [isEditing]);

  const handleChangeReadOnly = () => {
    const newReadOnly = !readOnly;
    setReadOnly(newReadOnly);
    setPatientInfoStatus({
      edit: newReadOnly,
    });
    setIsEditing(!newReadOnly);
    if (newReadOnly) {
      patientInfo.update();
    }
    form.resetFields();
  };

  const handleCancel = () => {
    patientInfo.update();
    handleChangeReadOnly();
  };

  const handleSubmit = () => {
    form.validateFields()
      .then((formValue) => {
        const params = {
          ...formValue,
          patientId: patientInfo.patient.id,
        };
        const api = params.id ? updatePatientImages : addPatientImages;
        api(params)
          .then(() => {
            patientInfo.update();
            handleChangeReadOnly();
          });
      });
  };
  const action = readOnly ? (
    <a onClick={handleChangeReadOnly}>{t('编辑')}</a>
  ) : (
    <>
      <a onClick={handleCancel}>{t('取消')}</a>
      <a onClick={handleSubmit}>{t('保存')}</a>
    </>
  );
  const mapImage = (urls: string) => {
    return <RecordImgUpload label={t('报告图片/视频')} value={urls} disabled />;
  };

  return (
    <Form className="record-form" form={form} readOnly={readOnly} initialValues={data} layout={readOnly ? 'horizontal' : 'vertical'}>
      <FormItem name="id" hidden><Input /></FormItem>
      <FormItem noStyle transformText={(text) => <TabHeader label={t('检查时间')} title={moment(text).format('YYYY-MM-DD')} action={action} />} name="recordTime" span={24}>
        <TabHeaderInput label={t('检查时间')} action={action} />
      </FormItem>
      <FormItem name="reportImage" transformText={mapImage}>
        <RecordImgUpload label={t('报告图片/视频')} accept={['image/*', '.mp4']} />
      </FormItem>
      <FormItem label={<Label>影像类型</Label>} name="imagesType" dictKey="imageType">
        <Select />
      </FormItem>
      <FormItem label={<Label>检查部位</Label>} name="parts">
        <Input maxLength={200} showCount />
      </FormItem>
      <FormItem label={<Label>DICOM</Label>} name="storeUid" transformText={(v) => <DicomUpload disabled value={v} id={data.id} />}>
        <DicomUpload id={data.id} />
      </FormItem>
      <FormItem
        label={<Label>DICOM预览链接</Label>}
        name="dicomQrcodeUrl"
        transformText={(text) => {
          return text ? <a href={text} target="_blank" rel="noreferrer">{text}</a> : '--';
        }}
      >
        <DicomLink />
      </FormItem>
      <FormItem label={<Label>检查所见</Label>} name="reportDescription">
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
      <FormItem label={<Label>检查结论</Label>} name="reportDiagnose">
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
      <FormItem
        label={(
          <>
            <Label>备注</Label>
            {
            !readOnly && <span style={{ fontSize: '14px', color: '#00000073' }}>（{t('备注内容将显示在讲稿左侧目录')}）</span>
          }
          </>
        )}
        name="remarks"
      >
        <Input maxLength={20} showCount />
      </FormItem>
    </Form>
  );
};

export default ImageReportsTab;
