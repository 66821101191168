import React from 'react';
import { useRecoilValue } from 'recoil';
import { Empty, Button } from '@sinohealth/butterfly-ui-components';
import emptyImg from '@/assets/images/patient/empty.png';
import { PatientInfoStatus } from '@/pages/patient/detail';
import { useLocales } from '@/Locales';

const RecordEmpty = (props:any) => {
  const { btnText = '新增数据', onCreate } = props;
  const patientInfoStatus = useRecoilValue(PatientInfoStatus);
  const { t } = useLocales();
  return (
    <Empty
      style={{ margin: 'auto' }}
      image={emptyImg}
      imageStyle={{ height: 120 }}
      description={
        <span style={{ color: '#0b214540' }}>
          {t('暂无数据')}
        </span>
      }
    >
      <Button disabled={!patientInfoStatus.edit} type="primary" shape="round" onClick={onCreate}>{btnText}</Button>
    </Empty>
  );
};

export default RecordEmpty;
