/*
 * @Description: 全屏
 * @Author: likaifeng
 * @Date: 2023-11-30 19:30:06
 * @LastEditTime: 2023-11-30 19:32:50
 * @LastEditors: likaifeng
 */
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Button } from '@sinohealth/butterfly-ui-components';
import React, { useState } from 'react';
import styles from './index.less';

/**
 * @description: 全屏
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:24:26
 */
const Screenfull: React.FC = () => {
  const [fullscreen, setFullscreen] = useState(false);

  const handleFullScreen = () => {
    setFullscreen(!fullscreen);
    if (fullscreen) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  return (
    <Button
      className={styles.fullBtn}
      type="text"
      icon={!fullscreen ? <FullscreenOutlined /> : <FullscreenExitOutlined />}
      onClick={handleFullScreen}
    />
  );
};

export default Screenfull;
