/*
 * @Description: 讲稿演示-主要内容-时间轴
 * @Author: likaifeng
 * @Date: 2023-11-30 16:31:41
 * @LastEditTime: 2024-08-08 18:02:08
 * @LastEditors: likaifeng
 */
import { useLocales } from '@/Locales';
import { speechFilterEditAtom } from '@/store/speech';
import { Button, DatePicker, Space } from '@sinohealth/butterfly-ui-components';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styles from './index.less';

/**
 * @description: 讲稿演示-主要内容-时间轴
 * @author: likaifeng
 * @param {*} props
 * @return {*}
 * @Date: 2023-12-04 14:28:09
 */
const SpeechStep: React.FC<any> = (props) => {
  const { showTitle = true, flex = false } = props;
  const [dateValue, setDateValue] = useState(dayjs(props?.time || Date.now()));
  const speechFilterEditFlag = useRecoilValue(speechFilterEditAtom);

  const { t } = useLocales();

  useEffect(() => {
    props?.onChange && props?.onChange(dateValue.format('YYYY-MM-DD'));
  }, []);

  return (
    <div id={props?.id} className={styles.step}>
      <div className={styles['step-header']}>
        <span className={styles['step-icon']} />
        <Space
          className={classNames(speechFilterEditFlag && styles['step-header-space'])}
          align="center"
          size="small"
        >
          {showTitle ? (
            <div className={styles['step-title']}>
              {props.title}
              {speechFilterEditFlag ? (
                <DatePicker
                  value={dateValue}
                  allowClear={false}
                  style={{ width: 240 }}
                  onChange={(v: any) => {
                    setDateValue(v);
                    props?.onChange && props?.onChange(v.format('YYYY-MM-DD'));
                  }}
                />
              ) : (
                dayjs(props.time).format('YYYY-MM-DD')
              )}
            </div>
          ) : (
            <div className={styles['step-title']}>{props.title}</div>
          )}
          {speechFilterEditFlag ? (
            <Space size={0}>
              {props?.onEdit ? (
                <Button type="link" onClick={props?.onEdit}>
                  {t('编辑')}
                </Button>
              ) : null}
              <Button type="link" danger onClick={props?.onDelete}>
                {t('删除')}
              </Button>
            </Space>
          ) : null}
        </Space>
      </div>
      {props.children ? (
        <div
          className={classNames(styles['step-container'], flex && styles['step-container__flex'])}
        >
          {props.children}
        </div>
      ) : null}
    </div>
  );
};

export default SpeechStep;
