/* eslint-disable indent */
import { PieSeriesOption } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { ImdtChartOption } from './echartType';
import { getLocales } from '@/Locales';

/*
 * @Description: 地图配置
 * @Author: likaifeng
 * @Date: 2023-12-26 15:30:37
 * @LastEditTime: 2024-01-08 09:57:05
 * @LastEditors: likaifeng
 */
export const mapNames: any = {
  北京: 'Beijing',
  天津: 'Tianjin',
  河北: 'Hebei',
  山西: 'Shanxi',
  内蒙古: 'Inner Mongolia',
  辽宁: 'Liaoning',
  吉林: 'Jilin',
  黑龙江: 'Heilongjiang',
  上海: 'Shanghai',
  江苏: 'Jiangsu',
  浙江: 'Zhejiang',
  安徽: 'Anhui',
  福建: 'Fujian',
  江西: 'Jiangxi',
  山东: 'Shandong',
  河南: 'Henan',
  湖北: 'Hubei',
  湖南: 'Hunan',
  广东: 'Guangdong',
  广西: 'Guangxi',
  海南: 'Hainan',
  重庆: 'Chongqing',
  四川: 'Sichuan',
  贵州: 'Guizhou',
  云南: 'Yunnan',
  西藏: 'Xizang',
  陕西: 'Shaanxi',
  甘肃: 'Gansu',
  青海: 'Qinghai',
  宁夏: 'Ningxia',
  新疆: 'Xinjiang',
  台湾: 'Taiwan',
  香港: 'Hong Kong',
  澳门: 'Macao',
  南海诸岛: 'South China Sea Islands',
};
export const chinaMapConfig = (configData: any) => {
  const { data, max, min, t, locales } = configData;

  return {
    title: {
      // 标题组件
      // text: '数据地图',
      // subtext: '数据来源于 xx平台',
      // sublink: 'http://www.census.gov/popest/data/datasets.html',
      left: 'right',
      textStyle: {
        color: '#000',
      },
    },
    grid: [
      {
        show: true,
        z: 0,
        left: 50,
        top: 50,
        right: 50,
        bottom: 50,
        containLabel: false,
        // backgroundColor: 'rgba(255, 255, 255, 0.6)',
        borderWidth: 0,
        borderColor: 'rgba(66, 136, 255, 1)',
      },
    ],
    tooltip: {
      // 提示框
      trigger: 'item',
      showDelay: 0,
      transitionDuration: 0.2,
      backgroundColor: '#00244c99',
      borderWidth: 0,
      textStyle: {
        color: '#FFFFFF',
      },
      formatter(params: any) {
        // eslint-disable-next-line no-shadow
        const { data } = params;
        return data
          ? `<div>
              <div style="color: #FFFFFF">${params.name}</div>
              <div>${t('医院数')}: ${data.value}</div>
              <div>${t('医生数')}: ${data.doctorNum}</div>
              </div>`
          : '';
      },
    },
    visualMap: {
      // 视觉映射组件
      show: true,
      left: 24,
      bottom: 34,
      pieces: [
        { lt: 5, label: `${t('小于')}5`, color: '#EEEEEE' },
        { start: 5, end: 10, color: '#BAE6FF' },
        { start: 10, end: 15, color: '#69BFFF' },
        { gt: 15, label: `${t('大于')}15`, color: '#20A0FF' },
      ],
      inverse: true,
    },
    toolbox: {
      // 工具导航
      show: false,
      left: 'left',
      top: 'top',
      feature: {
        // dataView: { readOnly: false },
        restore: {},
        saveAsImage: {},
      },
    },
    dataset: {
      source: data,
    },
    nameMap: locales === 'en' ? mapNames : null,
    series: {
      // 地图,可以是数组，多个
      label: {
        show: true, // 显示省市名称
        position: [1, 100], // 相对的百分比
        fontSize: 12,
        offset: [2, 0],
        align: 'left',
      },
      itemStyle: {
        areaColor: '#fff', // 地图图形颜色
      },
      type: 'map',
      roam: true,
      map: 'china',
      zoom: 1.5, // 当前视角的缩放比例
      scaleLimit: {
        max: 1.5,
        min: 1.5, // 设置默认缩放效果
      },
      top: '30%', // 距离顶部距离
    },
  };
};

/**
 * @description: 默认的 折线图 柱状图 echarts配置
 * @author: likaifeng
 * @param {ImdtChartOption} config
 * @return {*}
 * @Date: 2023-12-29 15:47:52
 */
export const defaultLineOption = (config?: ImdtChartOption): ImdtChartOption => {
  const defaultGrid = {
    show: false,
    z: 0,
    left: 30,
    top: 20,
    right: 20,
    bottom: 50,
    containLabel: false,
    borderWidth: 2,
    borderColor: 'rgba(66, 136, 255, 1)',
  };
  const grid =
    config?.grid && Array.isArray(config?.grid)
      ? [{ ...defaultGrid, ...config?.grid[0] }]
      : [defaultGrid];
  const locale = getLocales();
  return {
    // 整体布局
    grid,
    // 线条颜色
    color: ['#76DDFB'],
    // y 轴配置
    yAxis:
      config?.yAxis && Array.isArray(config?.yAxis)
        ? config?.yAxis
        : {
            type: 'value',
            axisLine: {
              show: false,
              lineStyle: {
                color: '#485465',
              },
            },
            axisLabel: {
              color: '#485465',
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#4288ff5e',
                type: 'dashed',
                width: 0.6,
              },
            },
            ...config?.yAxis,
          },
    axisPointer: {
      show: true,
      ...config?.axisPointer,
    },
    tooltip: {
      show: true,
      trigger: 'axis',
      backgroundColor: '#00244c99',
      borderWidth: 0,
      textStyle: {
        color: '#FFFFFF',
      },
      formatter: locale === 'en' ? '{b0}<br /> New session: {c0}' : '{b0}<br /> 新增场次: {c0}',
      ...config?.tooltip,
    },
    // ...config,
  };
};

/**
 * @description: 默认的 echart 饼图 Series 配置
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-29 16:29:52
 */
export const defaultPieSeries: PieSeriesOption = {
  type: 'pie',
  radius: 100,
  left: 'center',
  top: '-10%',
  width: 500,
  itemStyle: {
    borderColor: '#fff',
    borderWidth: 0.5,
  },
  labelLine: {
    length: 30,
    length2: 0,
    maxSurfaceAngle: 60,
  },
  label: {
    formatter: '{name|{b} ({c})}\n{time|{d}%}',
    minMargin: 5,
    edgeDistance: 30,
    lineHeight: 15,
    color: '#6C808D',
    rich: {
      time: {
        fontSize: 12,
        color: '#999',
      },
    },
    alignTo: 'edge',
  },
};

/**
 * @description: 默认的 echart 饼图 配置
 * @author: likaifeng
 * @param {ImdtChartOption} config
 * @return {*}
 * @Date: 2023-12-29 16:39:49
 */
export const defaultPieOption = (config?: ImdtChartOption): ImdtChartOption => {
  return {
    legend: {
      orient: 'horizontal',
      left: 'center',
      bottom: 20,
      ...config?.legend,
    },
    tooltip: {
      show: true,
      trigger: 'item',
      formatter: '{b} : {c} ({d}%)',
      backgroundColor: '#00244c99',
      borderWidth: 0,
      textStyle: {
        color: '#FFFFFF',
      },
    },
    ...config,
  };
};

/**
 * @description: 默认的柱状图 颜色配置
 * @author: likaifeng
 * @param {any} params
 * @param {any} len 颜色组长度
 * @param {any} start 第一组 颜色
 * @param {any} end 第二组 颜色
 * @return {*}
 * @Date: 2023-12-29 16:33:16
 */
export const defaultBarSeriesColor = (
  params: any,
  len = 6,
  start = ['#306CD5', '#7cade159'],
  end = ['#3BCBFF', '#7ccee159'],
) => {
  const color = new Array(len).fill('').map((_, index) => {
    return index % 2 === 0 ? start : end;
  });

  const colorList = [...color];
  let index = params.dataIndex;
  if (index >= colorList.length) {
    index = params.dataIndex - colorList.length;
  }
  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    {
      // 代表渐变色从正上方开始
      offset: 0, // offset范围是0~1，用于表示位置，0是指0%处的颜色
      color: colorList?.[index]?.[0],
    }, // 柱图渐变色
    {
      offset: 1, // 指100%处的颜色
      color: colorList?.[index]?.[1],
    },
  ]);
};
