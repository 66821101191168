/*
 * @Description: 讲稿演示-时间轴卡片
 * @Author: likaifeng
 * @Date: 2023-11-30 16:33:14
 * @LastEditTime: 2024-08-07 11:33:29
 * @LastEditors: likaifeng
 */
import { speechFilterEditAtom } from '@/store/speech';
import classNames from 'classnames';
import React from 'react';
import { useRecoilValue } from 'recoil';
import styles from './index.less';

/**
 * @description: 讲稿演示-时间轴卡片
 * @author: likaifeng
 * @param {*} props
 * @return {*}
 * @Date: 2023-12-04 14:28:19
 */
const SpeechStepCard: React.FC<any> = (props) => {
  const { background, padding, margin, disabled = true } = props;

  const speechFilterEditFlag = useRecoilValue(speechFilterEditAtom);

  return disabled ? (
    <div
      className={classNames(
        'card-time-step',
        styles['step-card'],
        !speechFilterEditFlag && styles['step-card-background'],
      )}
      style={{ background, padding, margin }}
    >
      {props.children}
    </div>
  ) : null;
};

export default SpeechStepCard;
