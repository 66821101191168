import React, { useEffect } from 'react';

const DataBase = () => {
  useEffect(() => {
    const userinfo = window.localStorage.getItem('SYSTEM_USER_INFO') || '';
    window.localStorage.setItem('userInfo', userinfo);
  }, []);
  return (
    <iframe src="./oldImdt/index.html" style={{ width: '100vw', height: '100vh' }} title="imdt" />
  );
};

export default DataBase;
