import React, { useMemo, useRef, useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { Select, SelectProps, Spin } from '@sinohealth/butterfly-ui-components';

interface AsyncSelectProps extends SelectProps {
  fetchOptions: (searchText: string) => Promise<any>,
  debounceTimeout?: number
}
function AsyncSelect({ fetchOptions, debounceTimeout = 800, ...props }: AsyncSelectProps) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const fetchRef = useRef(0);

  useEffect(() => {
    debounceFetcher('');
  }, []);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions: any) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setOptions(newOptions);
      }).finally(() => {
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : <span>暂无数据</span>}
      {...props}
      options={options}
    />
  );
}

export default AsyncSelect;
