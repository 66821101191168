import { request } from '@/common/request';

// 患者列表
export const getPatientList = (params: any) => {
  return request.post<any, any>('/patient/listPage', params);
};
export const createPatient = (params: any) => {
  return request.post<any, any>('/patient/add', params);
};
export const deletePatientItem = (id: string) => {
  return request.post<any, any>('/patient/delete', { id });
};
export const getPatientInfo = (id: string, needDesensitization?: boolean) => {
  return request.post<any, any>('/patient/info', { id, needDesensitization });
};
export const getPatientFormInfo = (patientId: string) => {
  return request.post<any, any>('/patient/formDetail', { patientId });
};
export const savePatientFormInfo = (params: any) => {
  return request.post<any, any>('/patient/saveForm', params);
};
export const updatePatientInfo = (params: any) => {
  return request.post<any, any>('/patient/update', params);
};
export const updatePatientHistory = (params: any) => {
  return request.post<any, any>('/patient/saveHistory', params);
};
export const getPatientInfoAllImg = (params: any) => {
  return request.post<any, any>('/patient/getAllReportPic', params);
};
export const startPatientHistoryOcr = (params: any) => {
  return request.post<any, any>('/patient/ocrHistory', params);
};
export const savePatientMedicalOcr = (params: any) => {
  return request.post<any, any>('/patient/createOrUpdateOcrMedical', params);
};
export const startPatientMedicalOcr = (params: any) => {
  return request.post<any, any>('/patient/startOcrMedical', params);
};
export const restartPatientMedicalOcr = (params: any) => {
  return request.post<any, any>('/patient/restartOcr', params);
};
export const resetPatientMedicalOcr = (params: any) => {
  return request.post<any, any>('/patient/ocrReset', params);
};
export const getPatientMedicalOcrTask = (params: any) => {
  return request.post<any, any>('/patient/findPatientOcrTask', params);
};
export const addPatientMedicalResult = (params: any) => {
  return request.post<any, any>('/patient/addOcrMedicalResult', params);
};
export const deletePatientMedicalOcrItem = (id: string) => {
  return request.post<any, any>('/patient/deleteGptResult', { id });
};
export const updatePatientMedicalOcrItem = (params: any) => {
  return request.post<any, any>('/patient/updateGptResult', params);
};
export const submitPatientMedicalOcrItem = (params: any) => {
  return request.post<any, any>('/patient/multiSaveReport', params);
};
export const getPatientMedicalOcrResult = (params: any) => {
  return request.post<any, any>('/patient/ocrDetail', params);
};
export const getPatientHistoryOcrResult = (params: any) => {
  return request.post<any, any>('/patient/ocrHistoryResult', params);
};
// 病历评分详情
export const getPatientScoreDetails = (params: any) => {
  return request.post<any, any>('patient/getPatientScoreDetails', params);
};
// 分享病历二维码
export const getPatientQrCode = (patientId: string) => {
  return request.post<any, any>('/share/medical/qrCode', { patientId });
};
// 获取会诊订单二维码
export const getConsultationOrderCode = (id: string) => {
  return request.post<any, any>('/share/consultationOrder/qrCode', { id });
};
// 患者mdt报告
export const getPatientMdtList = (patientId: string) => {
  const url = '/patientMdtReport/listPage';
  return request.post(url, { patientId });
};
export const addPatientMdtItem = (params: any) => {
  const url = '/patientMdtReport/add';
  return request.post(url, params);
};
export const deletePatientMdtItem = (id: string) => {
  const url = '/patientMdtReport/delete';
  return request.post(url, { id });
};
// 患者ppt
export const getPatientPPTList = (patientId: string) => {
  const url = '/patientPPT/listPage';
  return request.post(url, { patientId });
};
// 添加患者ppt
export const addPatientPPTItem = (params: any) => {
  const url = '/patientPPT/add';
  return request.post(url, params);
};
export const deletePatientPPTItem = (id: string) => {
  const url = '/patientPPT/delete';
  return request.post(url, { id });
};
// ocr
export const startOcr = (fileUrls: string[]) => {
  const url = '/orc/multiTextRecognition';
  return request.post(url, { fileUrls });
};
// 解析文本内容
export const analyzeText = (content: string) => {
  const url = '/chat/parseMedicalRecords';
  return request.post(url, { content });
};

// ocr工具里的识别接口
export const ocrImage = (fileUrls: string) => {
  const url = '/orc/textRecognitiond';
  return request.post(url, { url: fileUrls });
};

// 入院记录
export const addPatientMedical = (params: any) => {
  const url = '/medical/add';
  return request.post(url, params);
};
export const updatePatientMedical = (params: any) => {
  const url = '/medical/update';
  return request.post(url, params);
};
export const deletePatientMedical = (params: any) => {
  const url = '/medical/delete';
  return request.post(url, params);
};

// 出院记录
export const createOrUpdateDischarge = (params: string) => {
  const url = '/hospital/discharge/createOrUpdate';
  return request.post(url, params);
};
export const deleteDischarge = (params: any) => {
  const url = '/hospital/discharge/delete';
  return request.post(url, params);
};

// 影像报告
export const addPatientImages = (params: any) => {
  const url = '/images/add';
  return request.post(url, params);
};
export const updatePatientImages = (params: any) => {
  const url = '/images/update';
  return request.post(url, params);
};
export const deletePatientImages = (params: any) => {
  const url = '/images/delete';
  return request.post(url, params);
};
// 获取微云影像上传oss的stsToken，用于dicom文件上传
export const getStsToken = () => {
  const url = '/wedcm/stsToken';
  return request.get(url);
};
// 用于dicom文件上传
export const getStoreId = () => {
  const url = '/wedcm/storeUid';
  return request.get(url);
};
// 用于dicom文件上传
export const sendUploaded = (params: any) => {
  const url = '/wedcm/uploaded';
  return request.post(url, params);
};
// dicom详情
export const getImageInfo = (params: any) => {
  const url = '/images/getOne';
  return request.post(url, params);
};
// 手术信息
export const addPatientSurgical = (params: any) => {
  const url = '/surgical/add';
  return request.post(url, params);
};
export const updatePatientSurgical = (params: any) => {
  const url = '/surgical/update';
  return request.post(url, params);
};
export const deletePatientSurgical = (params: any) => {
  const url = '/surgical/delete';
  return request.post(url, params);
};

// 病理报告
export const addPatientPathology = (params: any) => {
  const url = '/pathology/add';
  return request.post(url, params);
};
export const updatePatientPathology = (params: any) => {
  const url = '/pathology/update';
  return request.post(url, params);
};
export const deletePatientPathology = (params: any) => {
  const url = '/pathology/delete';
  return request.post(url, params);
};

// 检验报告
export const addPatientInspection = (params: any) => {
  const url = '/inspection/add';
  return request.post(url, params);
};
export const updatePatientInspection = (params: any) => {
  const url = '/inspection/update';
  return request.post(url, params);
};
export const deletePatientInspection = (params: any) => {
  const url = '/inspection/delete';
  return request.post(url, params);
};

// 基因检测
export const addPatientGeneticTesting = (params: any) => {
  const url = '/geneticTesting/add';
  return request.post(url, params);
};
export const updatePatientGeneticTesting = (params: any) => {
  const url = '/geneticTesting/update';
  return request.post(url, params);
};
export const deletePatientGeneticTesting = (params: any) => {
  const url = '/geneticTesting/delete';
  return request.post(url, params);
};

// 用药记录
export const addPatientDrugRecords = (params: any) => {
  const url = '/drugRecords/add';
  return request.post(url, params);
};
export const updatePatientDrugRecords = (params: any) => {
  const url = '/drugRecords/update';
  return request.post(url, params);
};
export const deletePatientDrugRecords = (params: any) => {
  const url = '/drugRecords/delete';
  return request.post(url, params);
};

// MDT议题
export const addPatientIssue = (params: any) => {
  const url = '/issue/add';
  return request.post(url, params);
};
export const updatePatientIssue = (params: any) => {
  const url = '/issue/update';
  return request.post(url, params);
};
export const deletePatientIssue = (params: any) => {
  const url = '/issue/delete';
  return request.post(url, params);
};
export const sortPatientIssue = (params: any) => {
  const url = '/issue/move/sort';
  return request.post(url, params);
};

// 对比图
export const addPatientCompareImg = (params: any) => {
  const url = '/patient/compariso/chart/save';
  return request.post(url, params);
};
export const deletePatientCompareImg = (params: any) => {
  const url = '/patient/compariso/chart/delete';
  return request.post(url, params);
};
export const sortPatientCompareImg = (params: any) => {
  const url = '/patient/compariso/chart/move/sort';
  return request.post(url, params);
};

// 趋势图
export const addPatientChart = (params: any) => {
  const url = '/patient/correlation/chart/save';
  return request.post(url, params);
};
export const deletePatientChart = (params: any) => {
  const url = '/patient/correlation/chart/delete';
  return request.post(url, params);
};
export const sortPatientChart = (params: any) => {
  const url = '/patient/correlation/chart/chart/move/sort';
  return request.post(url, params);
};

// 参考文献
export const savePatientOther = (params: any) => {
  const url = '/other/information/save';
  return request.post(url, params);
};
export const deletePatientOther = (params: any) => {
  const url = '/other/information/delete';
  return request.post(url, params);
};

export const startPatientInfoOcr = (params: any) => {
  const url = '/patient/intelligentRecognition';
  return request.post(url, params);
};
export const getPatientInfoOcrDetail = (params: any) => {
  const url = '/patient/intelligentRecognitionDetail';
  return request.post(url, params);
};

/**
 * @description: 获取患者入院记录
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.20.0
 * @Date: 2024-08-07 11:55:34
 */
export const getMedicalListPage = (params: any) => {
  const url = '/medical/listPage';
  return request.post(url, params);
};

/**
 * @description: 获取出院记录
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.20.0
 * @Date: 2024-08-07 11:56:33
 */
export const getDischargeListPage = (params: any) => {
  const url = '/hospital/discharge/list';
  return request.post(url, params);
};

/**
 * @description: 获取患者影像报告
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.20.0
 * @Date: 2024-08-07 15:31:41
 */
export const getImagesListPage = (params: any) => {
  const url = '/images/listPage';
  return request.post(url, params);
};

/**
 * @description: 获取患者手术信息
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.20.0
 * @Date: 2024-08-07 16:12:51
 */
export const getSurgicalListPage = (params: any) => {
  const url = '/surgical/listPage';
  return request.post(url, params);
};

/**
 * @description: 获取患者病理报告
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.20.0
 * @Date: 2024-08-07 16:43:22
 */
export const getPathologyListPage = (params: any) => {
  const url = '/pathology/listPage';
  return request.post(url, params);
};

/**
 * @description: 获取患者检验报告
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.20.0
 * @Date: 2024-08-07 17:02:34
 */
export const getInspectionListPage = (params: any) => {
  const url = '/inspection/listPage';
  return request.post(url, params);
};

/**
 * @description: 获取患者基因检测信息
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.20.0
 * @Date: 2024-08-07 17:44:51
 */
export const getGeneticTestingListPage = (params: any) => {
  const url = '/geneticTesting/listPage';
  return request.post(url, params);
};

/**
 * @description: 获取患者用药记录信息
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.20.0
 * @Date: 2024-08-07 18:11:28
 */
export const getDrugRecordsListPage = (params: any) => {
  const url = '/drugRecords/listPage';
  return request.post(url, params);
};

/**
 * @description: 获取患者其他资料
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.20.0
 * @Date: 2024-08-07 18:25:55
 */
export const getOtherInfoListPage = (params: any) => {
  const url = '/other/information/page';
  return request.post(url, params);
};

/**
 * @description: 获取患者信息
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.20.0
 * @Date: 2024-08-08 11:04:42
 */
export const getPatientDetail = (params: any) => {
  const url = '/patient/detail';
  return request.post(url, params);
};

/**
 * @description: 获取患者对比图片信息
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.20.0
 * @Date: 2024-08-08 11:34:22
 */
export const getComparisoChartList = (params: any) => {
  const url = '/patient/compariso/chart/list';
  return request.post(url, params);
};

/**
 * @description: 获取患者趋势图信息
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.20.0
 * @Date: 2024-08-08 13:50:02
 */
export const getCorrelationChartList = (params: any) => {
  const url = '/patient/correlation/chart/list';
  return request.post(url, params);
};

/**
 * @description: 获取患者MDT议题
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.20.0
 * @Date: 2024-08-08 14:38:21
 */
export const getIssueListPage = (params: any) => {
  const url = '/issue/listPage';
  return request.post(url, params);
};

/**
 * @description: 提交患者讲稿
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.21.0
 * @Date: 2024-08-15 13:37:11
 */
export const patientSpeechSubmit = (params: any) => {
  const url = '/patient/speech/submit';
  return request.post(url, params);
};

/**
 * @description: 查询患者病例录入情况
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.21.0
 * @Date: 2024-08-15 13:48:06
 */
export const patientCaseEnterPage = (params: any) => {
  const url = '/patient/case/enter/page';
  return request.post(url, params);
};

/**
 * @description: 查询病例录入情况详情
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.21.0
 * @Date: 2024-08-16 11:21:49
 */
export const patientCaseEnterDetail = (params: any) => {
  const url = '/patient/case/enter/detail';
  return request.post(url, params);
};

/**
 * @description: 首页-查询系统消息
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.21.0
 * @Date: 2024-08-16 10:04:59
 */
export const patientCaseMessagePage = (params: any) => {
  const url = '/message/system/page';
  return request.post(url, params);
};

/**
 * @description: 查询病例录入情况统计数据
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.21.0
 * @Date: 2024-08-16 11:42:04
 */
export const patientCaseStatistics = () => {
  const url = '/patient/case/enter/statistics';
  return request.post(url, {});
};

export default {};
