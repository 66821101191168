const { NODE_ENV } = process.env;
export const baseURL = NODE_ENV === 'development' ? 'https://backstage-imdt-dev.zmnyun.cn' : '';
// 全局请求前缀
export const apiPrefix = '/admin-imdt/api';
export const scope = 'zh-imdt';
export const appName = 'zh-imdt';
export const clientPrefix = '/backend';
export const isDev = NODE_ENV === 'development';
export const getTokenParams = {
  clientId: 'healthplus-oms',
  clientSecret: 'healthplus-oms',
  scope: 'zmn-rx-oms-server',
};
export const loginRememberKey = 'zk-hccm-w'; // 记住密码时保存数据的key
export const sendCodeTimeKey = 'zk-s-k'; // 记住密码时保存数据的key
export default {
  baseURL,
  getTokenParams,
};
