import React, { useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import SortList from '@/pages/patient/detail/components/SortList';
import { PatientInfoAtom, PatientInfoStatus } from '@/pages/patient/detail';
import Empty from '@/pages/patient/detail/components/Empty';
import BaseFormModal from '@/common/components/BaseFormModal';
import AddForm from './components/AddForm';
import {
  addPatientIssue,
  deletePatientIssue,
  sortPatientIssue,
  updatePatientIssue,
} from '@/services/patient';
import Label from '@/components/Label';
import { numToChinese } from '@/utils';
import { Button, message } from '@sinohealth/butterfly-ui-components';
import { PlusCircleOutlined } from '@ant-design/icons';
import localEnum from '@/hooks/dict/localEnum';
import Text from '@/components/Text';
import { useLocales } from '@/Locales';

const MdtIssuesTab = () => {
  const formModal = useRef<any>();
  const patientInfo = useRecoilValue(PatientInfoAtom);
  const patientInfoStatus = useRecoilValue(PatientInfoStatus);
  const { t, locales } = useLocales();
  const [formTitle, setFormTitle] = useState(t('新增MDT议题'));

  const { zhPatientMdtIssues = [] } = patientInfo;
  const isEmpty = zhPatientMdtIssues.length === 0;
  const { MDT_ISSUES_TYPE, LETTER_LIST } = localEnum;

  const handleCreate = () => {
    setFormTitle(t('新增MDT议题'));
    formModal.current?.openModal();
  };
  const handleSubmit = (formValue: any) => {
    const newFormValue = JSON.parse(JSON.stringify(formValue));
    const api = newFormValue.id ? updatePatientIssue : addPatientIssue;

    newFormValue.type = !newFormValue.type ? MDT_ISSUES_TYPE.VOTING : newFormValue.type;
    if (newFormValue.type === MDT_ISSUES_TYPE.VOTING) {
      if (!newFormValue?.options || newFormValue?.options.length < 2) {
        message.error('最少需要2个选项');
        return Promise.reject();
      }
    }
    return api({
      ...newFormValue,
      patientId: patientInfo.patient.id,
    }).then(() => {
      patientInfo.update();
    });
  };
  const onSorted = (ids: string[]) => {
    sortPatientIssue(ids).then(() => {
      message.success(t('排序成功'));
    });
  };
  const onEdit = (item: any) => {
    setFormTitle(t('编辑MDT议题'));
    formModal.current?.openModal(item.data);
  };
  const onDelete = (item: any) => {
    deletePatientIssue({
      id: item.data.id,
    }).then(() => {
      message.success(t('删除成功'));
      patientInfo.update();
    });
  };
  const listData = [...zhPatientMdtIssues].map((item: any, i: any) => {
    return {
      title: `${t('议题')}${locales === 'zh-cn' ? numToChinese(i + 1) : i + 1}`,
      id: item.id,
      data: item,
      content: (
        <div>
          <Label>MDT内容</Label>&nbsp;&nbsp;&nbsp;{item.issue}
          {item.type === MDT_ISSUES_TYPE.VOTING &&
            item.options &&
            item.options.map((opt: string, inx: number) => {
              const key = `${opt}-${inx.toString()}`;
              return (
                <span key={key} style={{ marginLeft: '16px' }}>
                  {LETTER_LIST[inx]}.{opt}
                </span>
              );
            })}
        </div>
      ),
    };
  });
  return (
    <>
      {isEmpty && <Empty btnText={<Text>新增MDT议题</Text>} onCreate={handleCreate} />}
      {!isEmpty && (
        <div style={{ width: '100%' }}>
          <div style={{ marginBottom: 12 }}>
            <Button
              shape="round"
              type="primary"
              disabled={!patientInfoStatus.edit}
              onClick={handleCreate}
            >
              <PlusCircleOutlined /> <Text>新增议题</Text>
            </Button>
          </div>
          <SortList datasource={listData} onSorted={onSorted} onEdit={onEdit} onDelete={onDelete} />
        </div>
      )}
      <BaseFormModal
        title={formTitle}
        formModal={formModal}
        onSubmit={handleSubmit}
        FormContent={AddForm}
        width={600}
      />
    </>
  );
};

export default MdtIssuesTab;
