import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import routerConfig from '@/config/router';

const useCurrentRoute = () => {
  const [currentRoute, setCurrentRoute] = useState<any>({});
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    mapRoute(routerConfig, (item: any) => {
      if (item.path === pathname) {
        setCurrentRoute(item);
      }
    });
  }, [location]);

  const mapRoute = (routes: any[], callback: any) => {
    routes.forEach((item) => {
      callback(item);
      if (item.children) {
        mapRoute(item.children, callback);
      }
    });
  };
  return currentRoute;
};

export default useCurrentRoute;
