/*
 * @Description: 已开展MDT场次、团队数、合作专家数、病例总数
 * @Author: likaifeng
 * @Date: 2023-12-27 16:58:56
 * @LastEditTime: 2024-01-03 15:56:15
 * @LastEditors: likaifeng
 */
import { Spin } from '@sinohealth/butterfly-ui-components';
import React, { CSSProperties } from 'react';
import EmptyCanvas from '../EmptyCanvas';
import styles from './index.less';
import { useLocales } from '@/Locales';

type FieldCanvasProps = {
  style?: CSSProperties | undefined;
  loading?: boolean;
  title?: string;
  /** 总数 */
  count?: string;
  /** 底部数据 */
  session?: string;
  /** 底部 label */
  sessionLabel?: string;
  /** 上月同比 */
  basis?: string;
  /** 上月环比 */
  ratio?: string;
  /** 没有权限 */
  empty?: boolean;
};

/**
 * @description: 已开展MDT场次、团队数、合作专家数、病例总数
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-27 17:00:17
 */
const FieldCanvas: React.FC<FieldCanvasProps> = (props) => {
  const { style, loading, title, count, sessionLabel, session, ratio, basis, empty } = props;
  const { t } = useLocales();

  return (
    <Spin spinning={loading} style={{ height: '100%' }} tip="数据加载中.">
      {empty ? (
        <div className={styles['field-canvas']} style={{ ...style, maxWidth: '100%' }}>
          <div className={styles.title}>{title}</div>
          <div className={styles.count}>{count || 0}</div>
          <div className={styles.field}>
            <div className={styles['field-item']}>
              <span className={styles['field-label']}>{t('上月同比')}：</span>
              <span className={styles['field-value']}>{basis}</span>
            </div>
            <div className={styles['field-item']}>
              <span className={styles['field-label']}>{t('上月环比')}：</span>
              <span className={styles['field-value']}>{ratio}</span>
            </div>
          </div>
          <div className={styles['field-footer']}>
            {sessionLabel}: {session}
          </div>
        </div>
      ) : (
        <div className={styles['field-canvas']} style={{ ...style, maxWidth: '100%', height: 214 }}>
          <EmptyCanvas permission={empty} height={214} />
        </div>
      )}
    </Spin>
  );
};

export default FieldCanvas;
