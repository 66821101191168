import React, { useRef } from 'react';
import { useRecoilValue } from 'recoil';
import SortList from '@/pages/patient/detail/components/SortList';
import { PatientInfoAtom, PatientInfoStatus } from '@/pages/patient/detail';
import Empty from '@/pages/patient/detail/components/Empty';
import BaseFormModal from '@/common/components/BaseFormModal';
import AddForm from './components/AddForm';
import Image from '@/components/Image';
import { addPatientCompareImg, deletePatientCompareImg, sortPatientCompareImg } from '@/services/patient';
import Label from '@/components/Label';
import { Button, message, Space } from '@sinohealth/butterfly-ui-components';
import { PlusCircleOutlined } from '@ant-design/icons';
import styles from './index.less';
import CompareImageItem from '@/components/CompareImageItem';
import { useLocales } from '@/Locales';
import Text from '@/components/Text';

const CompareImageTab = () => {
  const formModal = useRef<any>();
  const { t } = useLocales();
  const patientInfo = useRecoilValue(PatientInfoAtom);
  const patientInfoStatus = useRecoilValue(PatientInfoStatus);
  const { comparisoCharts = [] } = patientInfo;
  const isEmpty = comparisoCharts.length === 0;
  const handleCreate = () => {
    formModal.current?.openModal();
  };
  const handleSubmit = (formValue: any) => {
    return addPatientCompareImg({
      ...formValue,
      patientId: patientInfo.patient.id,
    }).then(() => {
      patientInfo.update();
    });
  };
  const onSorted = (ids: string[]) => {
    sortPatientCompareImg(ids)
      .then(() => {
        message.success(t('排序成功'));
      });
  };
  const onEdit = (item: any) => {
    formModal.current?.openModal(item.data);
  };
  const onDelete = (item: any) => {
    deletePatientCompareImg({
      id: item.data.id,
    }).then(() => {
      message.success(t('删除成功'));
      patientInfo.update();
    });
  };
  const listData = [...comparisoCharts].map((item: any, i: any) => {
    return {
      title: item.name,
      id: item.id,
      data: item,
      content: (
        <div className={styles.body}>
          <div className={styles.desc}>
            <Label>简要说明</Label>&nbsp;&nbsp;&nbsp;{item.instruction || '--'}
          </div>
          <div className={styles.images}>
            <CompareImageItem title={item.name} images={[item.previousImage, item.nextImage]} />
          </div>
        </div>),
    };
  });
  return (
    <>
      { isEmpty && <Empty btnText={t('新增对比图片')} onCreate={handleCreate} />}
      {
        !isEmpty && (
          <div style={{ width: '100%' }}>
            <div style={{ marginBottom: 12 }}>
              <Button shape="round" type="primary" disabled={!patientInfoStatus.edit} onClick={handleCreate}><PlusCircleOutlined /> <Text>新增对比图</Text></Button>
            </div>
            <SortList datasource={listData} onSorted={onSorted} onEdit={onEdit} onDelete={onDelete} />
          </div>
        )
      }
      <BaseFormModal width={800} title={t('新增对比图')} formModal={formModal} onSubmit={handleSubmit} FormContent={AddForm} />
    </>
  );
};

export default CompareImageTab;
