import menuConfig, { MenuItem } from '@/config/menu';
import useCurrentRoute from '@/hooks/useCurrentRoute';
import { Button } from '@sinohealth/butterfly-ui-components';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useLocales } from '@/Locales';
import styles from './index.less';

const Navbar = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const [menuPaths, setMenuPaths] = useState<any>([]);
  const [isShowBackBtn, setIsShowBackBtn] = useState(false);
  const currentRoute = useCurrentRoute();
  const { t } = useLocales();

  useEffect(() => {
    const paths = mapMenu(menuConfig);
    setMenuPaths(paths);
  }, []);

  useEffect(() => {
    if (currentRoute) {
      setIsShowBackBtn(!menuPaths.includes(currentRoute.path));
    }
  }, [currentRoute]);

  const handleBack = () => {
    // if (location.pathname === '/consultation-center/detail') {
    //   navigate('/consultation-center/index');
    //   return;
    // }
    navigate(-1);
  };

  const mapMenu = (menus: MenuItem[]): any => {
    const newMenu: any = [];
    const map = (menuItems: MenuItem[]) => {
      menuItems.forEach((item) => {
        if (Array.isArray(item.children) && item.children.length > 0) {
          map(item.children);
        }
        newMenu.push(item.path);
      });
    };
    map(menus);
    return newMenu;
  };

  return (
    <span className={styles.navbar}>
      {isShowBackBtn && (
        <Button size="small" type="primary" ghost shape="round" onClick={handleBack}>
          {t('返回')}
        </Button>
      )}
      <span className={styles.pageTitle}>{t(currentRoute?.name)}</span>
    </span>
  );
};

export default Navbar;
