/*
 * @Description: mdt 数据统计接口
 * @Author: likaifeng
 * @Date: 2023-12-29 17:07:24
 * @LastEditTime: 2024-01-03 10:43:57
 * @LastEditors: likaifeng
 */
import request from '@/common/request';

const apiPrefix = '';

/**
 * @description: 机构MDT排名
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-12-29 17:08:56
 */
export const httpStatisticOrganization = (params: any) => {
  return request.post(`${apiPrefix}/statistical/statisticOrganization`, params);
};

/**
 * @description: BI 配置列表
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpStatisticAgencyData = (params: any) => {
  return request.post(`${apiPrefix}/agencyData/listPage`, params);
};

/**
 * @description: 近12月MDT开展场次趋势
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-12-29 17:55:22
 */
export const httpStatisticMdtTrend = (params: any) => {
  return request.post(`${apiPrefix}/statistical/mdtTrend`, params);
};

/**
 * @description: MDT 地市分布
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-12-29 18:01:26
 */
export const httpStatisticProvinceTotal = (params: any) => {
  return request.post(`${apiPrefix}/statistical/statisticProvinceTotal`, params);
};

/**
 * @description: 药品使用排行
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2024-01-02 13:59:18
 */
export const httpStatisticDrugRank = (params: any) => {
  return request.post(`${apiPrefix}/statistical/statisticDrugRank`, params);
};

/**
 * @description: 已开展MDT场次，团队数，合作专家数，病例总数
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2024-01-02 13:59:11
 */
export const httpStatisticSync = (params: any) => {
  return request.post(`${apiPrefix}/statistical/statisticSync`, params);
};

/**
 * @description: 团队MDT排名
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2024-01-02 13:59:03
 */
export const httpStatisticRank = (params: any) => {
  return request.post(`${apiPrefix}/statistical/statisticRank`, params);
};

/**
 * @description: 科室人数占比
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2024-01-02 15:27:35
 */
export const httpStatisticDepartment = (params: any) => {
  return request.post(`${apiPrefix}/statistical/statisticDepartment`, params);
};

/**
 * @description: 医生职称占比
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2024-01-02 15:33:50
 */
export const httpStatisticJobTitle = (params: any) => {
  return request.post(`${apiPrefix}/statistical/statisticJobTitle`, params);
};

/**
 * @description: 医生MDT次数排名TOP10
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2024-01-02 15:55:37
 */
export const httpStatisticDoctor = (params: any) => {
  return request.post(`${apiPrefix}/statistical/statisticDoctor`, params);
};

/**
 * @description: MDT开展场次趋势
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2024-01-02 17:34:55
 */
export const httpStatisticTrend = (params: any) => {
  return request.post(`${apiPrefix}/statistical/statisticTrend`, params);
};

/**
 * @description: 入库病例增长趋势
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2024-01-03 09:56:27
 */
export const httpStatisticEnter = (params: any) => {
  return request.post(`${apiPrefix}/statistical/enter`, params);
};

/**
 * @description: 入库病例
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2024-01-03 10:05:39
 */
export const httpStatisticEnterSummary = (params: any) => {
  return request.post(`${apiPrefix}/statistical/statisticEnterSummary`, params);
};

/**
 * @description: 患者年龄分布
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2024-01-03 10:18:31
 */
export const httpStatisticAge = (params: any) => {
  return request.post(`${apiPrefix}/statistical/age`, params);
};

/**
 * @description: 性别
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2024-01-03 10:25:30
 */
export const httpStatisticSex = (params: any) => {
  return request.post(`${apiPrefix}/statistical/sex`, params);
};

/**
 * @description: 检测比例
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2024-01-03 10:35:02
 */
export const httpStatisticGeneAndPathology = (params: any) => {
  return request.post(`${apiPrefix}/statistical/statisticGeneAndPathology`, params);
};

/**
 * @description: 影像类型比例
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2024-01-03 10:43:53
 */
export const httpStatisticImage = (params: any) => {
  return request.post(`${apiPrefix}/statistical/statisticImage`, params);
};
