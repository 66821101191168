import React from 'react';
import styles from './index.less';
import { useLocales } from '@/Locales';

const Label = (props: any) => {
  const { children, ...other } = props;
  const { t } = useLocales();
  return <span className={styles.label} {...other}>{t(children)}</span>;
};

export default Label;
