/* eslint-disable react/no-array-index-key */
/*
 * @Description: 对比图查看
 * @Author: likaifeng
 * @Date: 2023-12-13 09:42:33
 * @LastEditTime: 2023-12-21 11:04:56
 * @LastEditors: likaifeng
 */
import ImageZoom from '@/components/ImageZoom';
import { Modal } from '@sinohealth/butterfly-ui-components';
import React, { useEffect, useState } from 'react';
import styles from './index.less';

const SpeechCompareModel: React.FC<any> = (props) => {
  const { name, images, onClose } = props;

  const [dialogVisible, setDialogVisible] = useState(false);

  const handleClose = () => {
    setDialogVisible(false);
    onClose && onClose();
  };

  useEffect(() => {
    setDialogVisible(!!images.length);
  }, [images]);

  return (
    <Modal
      title={`查看对比图 - ${name}`}
      open={dialogVisible}
      width={1048}
      onCancel={handleClose}
      footer={false}
    >
      <div className={styles.imageModal}>
        <div className={styles.item}>
          <ImageZoom src={images?.[0]?.key} />
        </div>
        <div className={styles.item}>
          <ImageZoom src={images?.[1]?.key} />
        </div>
      </div>
    </Modal>
  );
};

export default SpeechCompareModel;
