/* eslint-disable indent */
/*
 * @Description: 编辑讲稿演示-主要内容-入院记录
 * @Author: likaifeng
 * @Date: 2023-11-30 16:29:50
 * @LastEditTime: 2024-08-14 15:31:41
 * @LastEditors: likaifeng
 */
import BaseFormModal from '@/common/components/BaseFormModal';
import { useLocales } from '@/Locales';
import { useCombinateData, useMedicalList } from '@/pages/speechLecture/hook/makeSpeech';
import { addPatientMedical } from '@/services/patient';
import { Empty } from '@sinohealth/butterfly-ui-components';
import { memo, useCallback, useMemo, useRef } from 'react';
import HocForm, { ORIGIN_TYPE } from '../HocForm';
import SpeechCard from '../SpeechCard';
import AddForm from './AddForm';
import CreateForm from './CreateForm';
import styles from './index.less';

// 使用高阶组件包装 CreateForm
const HocCreateForm = HocForm<any>(CreateForm);

/**
 * @description: 讲稿演示-主要内容-入院记录
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:24:36
 */
const SpeechAdmitted = memo(() => {
  // const [temOrigin, setTemOrigin] = React.useState<any>();

  const { medicalList, setReload, setMedicalList, patientId, menus } = useMedicalList();
  const medicalListMemoized = useMemo(() => medicalList, [medicalList]);

  useCombinateData(ORIGIN_TYPE.medicalRecord, medicalListMemoized, menus);

  const { t } = useLocales();

  const formModal = useRef<any>();
  const handleSubmit = useCallback(
    (formValue: any) => {
      // console.log(formValue);
      return addPatientMedical({
        ...formValue,
        patientId,
      }).then(() => {
        setReload(true);
      });
    },
    [patientId, setReload],
  );

  const handleCreateNewMedicalRecord = useCallback(() => {
    // setTemOrigin({});
    formModal.current?.openModal({});
  }, [formModal]);

  const handleUpdate = useCallback(
    (v: any, index: number) => {
      // setReload(true);
      const temp = [...medicalList];
      const d = temp.filter((el) => el.id === v.id);
      if (d.length) {
        temp.splice(index, 1, { ...d[0], ...v });
        setMedicalList(temp);
      }
    },
    [medicalList, setMedicalList],
  );

  return (
    <SpeechCard
      titleId="medicalRecord"
      title="入院记录"
      addTitle="入院记录"
      onClick={handleCreateNewMedicalRecord}
    >
      <div className={styles['speech-admitted']}>
        {/* {temOrigin ? (
          <HocCreateForm
            item={temOrigin}
            index="medical_record_origin"
            onUpdate={() => {
              setTemOrigin(undefined);
              setReload(true);
            }}
            onDelete={() => setTemOrigin(undefined)}
          />
        ) : null} */}
        {medicalListMemoized?.map((item: any, index: number) => (
          <HocCreateForm
            key={item.id}
            item={item}
            index={index}
            onUpdate={() => setReload(true)}
            onDelete={() => setReload(true)}
          />
        ))}
        {!medicalListMemoized.length && <Empty description={t('暂无数据')} />}
      </div>
      <BaseFormModal
        width={900}
        title={t('新增入院记录')}
        formModal={formModal}
        onSubmit={handleSubmit}
        FormContent={AddForm}
      />
    </SpeechCard>
  );
});

export default SpeechAdmitted;
