/*
 * @Description: MDT数据统计
 * @Author: likaifeng
 * @Date: 2023-12-26 14:35:24
 * @LastEditTime: 2024-01-03 16:57:34
 * @LastEditors: likaifeng
 */
import usePermission from '@/hooks/usePermission';
import { Spin } from '@sinohealth/butterfly-ui-components';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import ActivityAnalysis from './components/ActivityAnalysis';
import CaseAnalysis from './components/CaseAnalysis';
import OverviewAnalysis from './components/OverviewAnalysis';
import { useAgency } from './hook';
import styles from './index.less';
import { useLocales } from '@/Locales';

/**
 * @description: MDT数据统计
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-26 14:35:54
 */
const MdtStatistics: React.FC = () => {
  const [currentTab, setCurrentTab] = useState('overview');
  const [currentTime] = useState(dayjs().format('YYYY-MM-DD HH:mm:ss'));

  const permission = usePermission('mdtStatistics');
  const { loadingAgency } = useAgency();
  const { t } = useLocales();

  const TabsList = [
    { key: 'overview', label: t('数据概览'), visiable: permission.overviewAnalysis },
    { key: 'activity', label: t('活动分析'), visiable: permission.activityAnalysis },
    { key: 'case', label: t('病例分析'), visiable: permission.caseAnalysis },
  ];

  useEffect(() => {
    const f = TabsList.filter((el) => el.visiable);
    if (f.length) {
      setCurrentTab(f[0].key);
    }
  }, [permission]);

  return (
    <Spin spinning={loadingAgency} tip={t('加载中...')}>
      <div className={styles['mdt-statistics']}>
        <ul className={styles['statistics-tab']}>
          {TabsList.map((el) =>
            el.visiable ? (
              <li
                key={el.key}
                className={classNames(styles.tab, currentTab === el.key && styles['tab-active'])}
                onClick={() => setCurrentTab(el.key)}
              >
                {el.label}
              </li>
            ) : null,
          )}
        </ul>
        <div className={styles['update-time']}>{t('最近更新时间')}：{currentTime}</div>
        {currentTab === 'overview' && permission.overviewAnalysis ? <OverviewAnalysis /> : null}
        {currentTab === 'activity' && permission.activityAnalysis ? <ActivityAnalysis /> : null}
        {currentTab === 'case' && permission.caseAnalysis ? <CaseAnalysis /> : null}
      </div>
    </Spin>
  );
};

export default MdtStatistics;
