import { TABS } from '@/pages/speechLecture/hook/config';
import { getLocalStorage } from '@/utils/cookies';
import { atom } from 'recoil';

/**
 * @description: 病历摘要 - 需要记录的 tab，用于快捷预览过滤数据
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechMainDetailTabAtom = atom<any>({
  key: 'speechMainDetailTab',
  default: null,
});

/**
 * @description: 右侧详细信息-内容 { id: '', detail: {} }
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechMainDetailAtom = atom<any>({
  key: 'speechMainDetail',
  default: null,
});

/**
 * @description: 议题是否显示，为了计算高度，提取全局
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechIssueFlagAtom = atom<any>({
  key: 'speechIssueFlag',
  default: true,
});

/**
 * @description: 演讲稿的字体大小设计
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechFontSizeAtom = atom<any>({
  key: 'speechFontSize',
  default: 2, // 1 小号(正常) 2 中号(+2) 3 大号(+2)
});

/**
 * @description: 演讲稿的字体大小设计
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechTabEnumAtom = atom<any>({
  key: 'speechTabEnum',
  default: TABS,
});

/**
 * @description: tab 枚举中文
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:34:55
 */
export const speechTabEnumKeyAtom = atom<any>({
  key: 'speechTabEnumKey',
  default: {
    baseInfo: '基本信息',
    medicalRecord: '入院记录',
    hospitalDischargeRecords: '出院记录',
    imageReport: '影像报告',
    surgicalRecord: '手术信息',
    pathologyReport: '病理报告',
    inspectionReport: '检验报告',
    drugRecord: '用药记录',
    geneticTesting: '基因检测',
    otherInformation: '参考文献',
    compareImage: '对比图',
    correlationCharts: '趋势图',
  },
});

/**
 * @description: 讲稿大纲的 tab
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechSideTabAtom = atom<any>({
  key: 'speechSideTab',
  default: 'baseInfo',
});

/**
 * @description: 讲稿大纲的 tab
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechMakingDetailkKeysAtom = atom<any>({
  key: 'speechMakingDetailkKeys',
  default: getLocalStorage('SPEECH_MAKING_DETAIL_KEY') || null,
});

/**
 * @description: 是否隐藏
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechDisabledAtom = atom<any>({
  key: 'speechDisabled',
  default: {
    baseInfo: true,
    medicalRecord: true,
    hospitalDischargeRecords: true,
    imageReport: true,
    surgicalRecord: true,
    pathologyReport: true,
    inspectionReport: true,
    drugRecord: true,
    geneticTesting: true,
    otherInformation: true,
  },
});

/**
 * @description: 讲稿左侧菜单
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechSideMenuAtom = atom<any>({
  key: 'speechSideMenu',
  default: [],
});

/**
 * @description: 制作讲稿过滤出的数据
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const speechFilterMakeDataAtom = atom<any>({
  key: 'speechFilterMakeData',
  default: null,
});
