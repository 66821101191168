import React, { useState, useEffect, useRef } from 'react';
import { Button, Space } from '@sinohealth/butterfly-ui-components';
import Label from '@/components/Label';
import { apiLabelsBindList } from '@/services/masterData';
import AddPatientTag from '@/components/AddPatientTag';
import { PlusOutlined } from '@ant-design/icons';
import styles from './index.less';
import { useLocales } from '@/Locales';

const PatientTag = (props: { tags: any[], patientId: string, onChange: any }) => {
  const { tags, patientId, onChange } = props;
  const modalRef = useRef<any>(null);
  const { t } = useLocales();
  // const [patientTags, setPatientTags] = useState([]);
  // useEffect(() => {
  //   if (patientId) {
  //     apiLabelsBindList(patientId)
  //       .then((res) => {
  //         setPatientTags(res);
  //       });
  //   }
  // }, [patientId]);

  const handleAdd = () => {
    modalRef.current?.handleOpen();
  };
  return (
    <div className={styles.tags}>
      <Space>
        <Label>病例标签</Label>
        {
          tags.map((item) => {
            return (
              <span key={item.id} className={styles.tagItem}>{item.name}</span>
            );
          })
        }
        <Button className={styles.addBtn} type="primary" ghost shape="round" size="small" onClick={handleAdd}><PlusOutlined />
          { tags.length < 3 ? t('添加标签') : t('编辑标签')}
        </Button>
      </Space>
      <AddPatientTag ref={modalRef} patientId={patientId} selectedData={tags.map((item) => item.labelId)} onAdded={onChange} />
    </div>
  );
};

export default PatientTag;
