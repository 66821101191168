import { useEffect, useState, useCallback } from 'react';
import useDictOptions from '@/hooks/dict/useDictOptions';
import { idCardReg, phoneReg } from '@/utils/validate';
import { AutoComplete, Form, Input, Select, Space, Button, Switch } from '@sinohealth/butterfly-ui-components/lib';
import styles from './index.less';
import Upload from '@/components/Upload';
import { getAgencyList, getDepartmentList, getHospitalList, getJobTitleList } from '@/services/common';
import useCurrentLoginUserInfo from '@/hooks/useCurrentLoginUserInfo';
import AsyncSelect from '@/components/AsyncSelect';
import { checkUserName, getDoctorList } from '@/services/doctor';
import { httpMdtTeamByAssistantId } from '@/services/mdtTeam';
import { randomWord } from '@/utils';
import HospitalSelect from '@/pages/doctor/components/HospitalSelect';
import DepartmentSelect from '@/pages/doctor/components/DepartmentSelect';
import JobTitleSelect from '@/pages/doctor/components/JobTitleSelect';
import MdtTeamSelect from '@/pages/doctor/components/MdtTeamSelect';
import _ from 'lodash';
import { useLocales } from '@/Locales';

const FormModalContent = (props: any) => {
  const { form, defaultFormData, isUpdate } = props;
  const [doctorListByHospital, setDoctorListByHospital] = useState([]);
  const genderOptions = useDictOptions('genderDict');
  const [userInfo] = useCurrentLoginUserInfo();
  const selectedDoctorId = Form.useWatch('id', form);
  const selectedDoctorUserId = Form.useWatch('userId', form);
  const hospital = Form.useWatch('hospital', form);
  const hospitalId = hospital?.value;
  const department = Form.useWatch('department', form);
  const departmentId = department?.value;
  const phone = Form.useWatch('phone', form);
  const { t } = useLocales();

  useEffect(() => {
    if (hospitalId && hospitalId !== defaultFormData.hospitalId && !isUpdate) {
      // 切换医院清空医生姓名和账号id
      form.setFieldValue('name', '');
      form.setFieldValue('id', '');
    }
  }, [hospitalId]);

  // 机构列表
  const fetchAgencyList = () => {
    return getAgencyList(userInfo?.user?.id)
      .then((res) => {
        const options = res.map((item: any) => {
          return {
            label: item.agencyName,
            value: item.agencyId,
          };
        });
        form.setFieldValue('agency', options[0]);
        return options;
      });
  };

  const fetchDoctorListByHospitalId: any = useCallback(_.debounce((text: string) => {
    form.setFieldValue('id', '');
    form.setFieldValue('userId', '');
    if (!hospitalId) {
      return Promise.resolve([]);
    }
    return getDoctorList({
      hospitalId,
      name: text,
      currentPage: 1,
      pageSize: 100,
    }).then((res) => {
      setDoctorListByHospital(res.list.map((item: any, index: number) => {
        return {
          label: item.name,
          value: `${item.id}id${index}`,
          data: item,
        };
      }));
    });
  }, 600), [form, hospitalId]);

  const handleSelectDoctor = (val: any, config: any) => {
    const option = config.data;
    form.resetFields(['gender', 'department', 'userName', 'password']); // 清除错误信息和值之类的
    form.setFieldValue('id', option.id);
    form.setFieldValue('name', option.name);
    form.setFieldValue('gender', option.gender);
    form.setFieldValue('phone', option.phone);
    form.setFieldValue('avatar', option.avatar);
    form.setFieldValue('introduction', option.introduction);
    form.setFieldValue('userId', option.userId || '');
    form.setFieldValue('department', {
      value: option.departmentId,
      label: option.departmentName,
    });
    form.setFieldValue('jobTitleObj', {
      value: option.jobTitleId,
      label: option.jobTitle,
    });
  };

  // 校验手机号或者用户名唯一
  const checkUserNameOrPhoneOnlyOne = (params: any) => {
    const isNull = !(params.username && params.phone);
    if (isNull) {
      return Promise.resolve();
    }
    return checkUserName(params).then((res) => {
      if (res) {
        throw (new Error(t('用户名/手机号重复')));
      }
    });
  };
  // 校验医生姓名唯一
  const checkDoctorNameOnlyOne = async (text: string) => {
    // 当没有选择机构或者是直接选择某个已有医生时，不做姓名唯一校验
    if (!departmentId || selectedDoctorId || !text.trim()) return Promise.resolve();
    return getDoctorList({
      hospitalId,
      departmentId,
      name: text,
    }).then((res) => {
      if (res.list.length > 0) {
        return Promise.reject(new Error(t('医生姓名重复')));
      }
      return res;
    });
  };

  const handleRadomPassword = () => {
    const newPassword = randomWord(true, 8, 16);
    form.resetFields(['password']);
    form.setFieldValue('password', newPassword);
  };

  const PasswordInput = (_props: any) => {
    return (
      <Space.Compact style={{ width: '100%' }}>
        <Input.Password type="password" autoComplete="new-password" placeholder={t('请输入登录密码')} {..._props} />
        <Button type="primary" ghost onClick={handleRadomPassword} disabled={_props.disabled}>{t('自动生成')}</Button>
      </Space.Compact>
    );
  };

  const handleSubmit = (notify: boolean) => {
    form.setFieldValue('notify', notify);
    form.submit();
  };

  const isSelectDoctor = !!selectedDoctorId && !isUpdate;
  const isSelectDoctorUser = !!selectedDoctorUserId && !isUpdate;
  return (
    <div>
      <div className={styles.addForm}>
        <Form layout="vertical" {...props} labelCol={{ flex: '1' }}>
          <Form.Item label={t('所属机构')} name="agency" rules={[{ required: true, message: t('请选择所属机构') }]}>
            <AsyncSelect labelInValue disabled placeholder={t('请搜索选择所属机构')} fetchOptions={fetchAgencyList} />
          </Form.Item>
          <Form.Item label={t('所属医院')} name="hospital" rules={[{ required: true, message: t('请选择所属医院') }]}>
            <HospitalSelect labelInValue />
          </Form.Item>
          { /* 账号id是选择已有医生时的id */}
          <Form.Item label="账号id" hidden name="id">
            <Input />
          </Form.Item>
          { /* 账号账号userId是选择已有医生时,该医生如果已经创建了登录账号，那这是他的账号id */}
          <Form.Item label="账号userId" hidden name="userId">
            <Input />
          </Form.Item>
          <Form.Item
            label={t('姓名')}
            name="name"
            dependencies={['id', 'department']}
            rules={[
              { required: true, message: t('请输入医生姓名') },
              { validator: (rule, text) => checkDoctorNameOnlyOne(text), message: t('医生重复(姓名+医院+科室不能有重复)，请重新输入') },
            ]}
            extra={!isUpdate && t('可输入新的医生姓名，也可以选择医生库的医生数据。')}
          >
            {
              isUpdate ? (
                <Input placeholder={t('请输入医生姓名')} />
              ) : (
                <AutoComplete
                  disabled={!hospitalId}
                  options={doctorListByHospital}
                  onSearch={(text) => fetchDoctorListByHospitalId(text)}
                  onSelect={handleSelectDoctor}
                  placeholder={t('请输入医生姓名')}
                  maxLength={15}
                />
              )
            }

          </Form.Item>
          <Form.Item label={t('性别')} name="gender" rules={[{ required: true, message: t('请选择性别') }]}>
            <Select disabled={isSelectDoctorUser} placeholder={t('请选择性别')} options={genderOptions} />
          </Form.Item>
          <Form.Item label={t('所在科室')} name="department" rules={[{ required: true, message: t('请选择所在科室') }]}>
            <DepartmentSelect disabled={isSelectDoctorUser} labelInValue />
          </Form.Item>
          <Form.Item label={t('临床职称')} name="jobTitleObj">
            <JobTitleSelect disabled={isSelectDoctorUser} labelInValue />
          </Form.Item>
          {
            !isUpdate && (
              <Form.Item label={t('所属MDT团队')} name="zhMdtTeamId">
                <MdtTeamSelect />
              </Form.Item>
            )
          }
          {
            isUpdate && (
              <Form.Item label={t('所属MDT团队')} name="mdtNames">
                <Input disabled />
              </Form.Item>
            )
          }
          <Form.Item
            label={t('用户名')}
            name="userName"
            extra={t('用户名只能由数字、字母和下划线组合。')}
            rules={[
              { required: !isSelectDoctorUser, message: t('请输入用户名') },
              { validator: (rule, text) => checkUserNameOrPhoneOnlyOne({ username: text }), message: t('用户名重复，请修改重试') },
              { pattern: /^[a-zA-Z0-9_]*$/g, message: t('用户名格式错误') },
            ]}
          >
            <Input disabled={isSelectDoctorUser} placeholder={t('请输入用户名')} autoComplete="off" maxLength={20} />
          </Form.Item>
          <Form.Item
            label={t('手机号')}
            name="phone"
            extra={t('手机号用于发送重要通知，请务必准确填写。')}
            rules={[
              { pattern: phoneReg, message: t('手机号格式有误') },
              { validator: (rule, text) => checkUserNameOrPhoneOnlyOne({ phone: text }), message: t('该手机号已被使用，请修改重试') },
            ]}
          >
            <Input disabled={isSelectDoctorUser} placeholder={t('请输入手机号')} maxLength={11} />
          </Form.Item>
          <Form.Item
            label={t('登录密码')}
            name="password"
            extra={t('登录密码必须由8-16位数字字母符号任意两种组合，不能包含空格。')}
            rules={[
              { required: !isSelectDoctorUser, message: t('请输入登录密码') },
              { pattern: /(?!.*\s)(?!^[\u4e00-\u9fa5]+$)(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.{8,16}$/, message: t('密码格式错误') },
            ]}
          >
            <PasswordInput disabled={isSelectDoctorUser} />
          </Form.Item>
          <Form.Item label={t('简介')} name="introduction">
            <Input.TextArea disabled={isSelectDoctor} placeholder={t('请输入简介')} maxLength={1000} rows={5} showCount />
          </Form.Item>
          <Form.Item label={t('头像')} name="avatar">
            <Upload disabled={isSelectDoctor} />
          </Form.Item>
          <Form.Item label="推送账号信息" name="notify" hidden><Switch /></Form.Item>
        </Form>
      </div>
      {
        !isUpdate && (
          <div className={styles.footer}>
            <Space>
              <Button onClick={() => handleSubmit(false)}>{t('仅创建账号')}</Button>
              <Button type="primary" disabled={!phone} onClick={() => handleSubmit(true)}>{t('保存并推送账号信息')}</Button>
            </Space>
          </div>
        )
      }
    </div>
  );
};

export default FormModalContent;
