import React from 'react';
import styles from './index.less';

const RichTextRender = (props: { content: string }) => {
  const { content } = props;
  return (
    <div className={styles.richRender} dangerouslySetInnerHTML={{ __html: content }} />
  );
};

export default RichTextRender;
