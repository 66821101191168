/*
 * @Description: 机构MDT排名
 * @Author: likaifeng
 * @Date: 2023-12-27 16:56:21
 * @LastEditTime: 2024-01-04 11:39:36
 * @LastEditors: likaifeng
 */
import usePermission from '@/hooks/usePermission';
import React from 'react';
import { useOrganization } from '../../hook';
import { defaultBarSeriesColor, defaultLineOption } from '../../hook/config';
import { ImdtChartOption } from '../../hook/echartType';
import ImdtChart from '../ImdtChart';
import styles from './index.less';
import { useLocales } from '@/Locales';

/**
 * @description: 机构MDT排名
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-27 16:57:58
 */
const MdtAgencyRanking: React.FC = () => {
  const { organizaSource, loadingOrganization } = useOrganization();
  const permission = usePermission('mdtStatistics');
  const { t } = useLocales();

  const barOptions: ImdtChartOption = {
    ...defaultLineOption(),
    grid: [
      {
        left: 40,
        top: 20,
        bottom: organizaSource.length > 10 ? 70 : 40,
        right: 20,
      },
    ],
    xAxis: {
      type: 'category',
      data: organizaSource.map((el: any) => el?.name),
      axisLine: {
        show: true,
      },
      axisTick: {
        alignWithLabel: true,
      },
    },
    calculable: true,
    dataZoom: [
      {
        show: organizaSource.length > 10,
        start: 0,
        end: 100,
      },
      {
        type: 'inside',
        start: 0,
        end: 10,
      },
    ],
    // 数据
    series: [
      {
        data: organizaSource.map((el: any) => el?.total),
        type: 'bar',
        barMaxWidth: 10,
        itemStyle: {
          borderRadius: [4, 4, 0, 0],
          color: (params) => defaultBarSeriesColor(params, organizaSource.length),
        },
        selectedMode: 'series',
      },
    ],
    tooltip: {
      backgroundColor: '#00244c99',
      borderWidth: 0,
      textStyle: {
        color: '#FFFFFF',
      },
      formatter: '{b0}<br /> 场次 {c0}',
    },
  };

  return (
    <ImdtChart
      loading={loadingOrganization}
      className={styles['line-chart']}
      title={t('机构MDT排名')}
      style={{ marginBottom: 0 }}
      height={261}
      option={barOptions}
      empty={!organizaSource.length || !permission?.statisticalOrganization}
      permission={permission?.statisticalOrganization}
    />
  );
};

export default MdtAgencyRanking;
