import { useLocales } from '@/Locales';
import ChartLine from '@/pages/patient/detail/components/ChartsTab/components/ChartLine';
import { uploadFile } from '@/services/common';
import { base64ToFile, getUuid } from '@/utils';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Space,
  Steps,
  Table,
  message,
} from '@sinohealth/butterfly-ui-components/lib';
import { useEffect, useState } from 'react';
import styles from './index.less';

let lock = false;
const FormModalContent = (props: any) => {
  const { form, onFinish, onCancel, ...other } = props;
  const [loading, setLoading] = useState<any>(false);
  const [current, setCurrent] = useState<any>(0);
  const [lineData, setLineData] = useState<any>({});
  const { t } = useLocales();

  useEffect(() => {
    const id = form.getFieldValue('id');
    if (id) {
      const formValue = form.getFieldsValue();
      setLineData({
        ...formValue,
        key: getUuid(),
      });
      setCurrent(2);
    }
  }, []);

  const isStep1 = current === 0;
  const isStep2 = current === 1;
  const isStep3 = current === 2;

  const handleNext = () => {
    if (lock) {
      return;
    }
    lock = true;
    setLoading(true);
    form
      .validateFields()
      .then((formValue: any) => {
        setLineData({
          ...formValue,
          key: getUuid(),
        });
        if (isStep3) {
          handleSubmit(formValue);
        } else {
          setCurrent(current + 1);
          setLoading(false);
        }
      })
      .finally(() => {
        lock = false;
        setLoading(false);
      });
  };

  const handleLast = () => {
    if (current > 0) {
      setCurrent(current - 1);
    } else {
      handleCancel();
    }
  };

  const handleSubmit = (formValue: any) => {
    // 上传缩略图
    setLoading(true);
    try {
      const file = base64ToFile(formValue.thumbnail, `${formValue.name}.png`);
      const formData = new FormData();
      formData.append('file', file);
      uploadFile(formData)
        .then((res) => {
          const newFormValue = { ...formValue, thumbnail: res };
          onFinish(newFormValue);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const getColumns = ({ fields, remove }: any) => {
    return [
      {
        title: t('X轴数值'),
        dataIndex: 'xaxis',
        key: 'xaxis',
        render(text: any, field: any) {
          const { key, name, ...restField } = field;
          return (
            <Form.Item
              {...restField}
              name={[name, 'xaxis']}
              rules={[{ required: true, message: t('请输入X轴数值') }]}
            >
              <Input
                placeholder={t('请输入X轴数值')}
                size="small"
                bordered={false}
                maxLength={10}
              />
            </Form.Item>
          );
        },
      },
      {
        title: t('Y轴数值'),
        dataIndex: 'yaxis',
        key: 'yaxis',
        render(text: any, field: any) {
          const { key, name, ...restField } = field;
          return (
            <Form.Item
              {...restField}
              name={[name, 'yaxis']}
              rules={[{ required: true, message: t('请输入Y轴数值') }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                controls={false}
                placeholder={t('请输入Y轴数值')}
                size="small"
                bordered={false}
              />
            </Form.Item>
          );
        },
      },
      {
        title: t('操作'),
        dataIndex: 'action',
        key: 'action',
        width: 80,
        render(text: any, field: any, i: any) {
          return fields.length > 2 ? (
            <span className={styles.deleteBtn} onClick={() => remove(field.name)}>
              <MinusCircleOutlined /> {t('删除')}
            </span>
          ) : null;
        },
      },
    ];
  };

  const validatorData = (_: any, v: any) => {
    if (v.length > 0) {
      return Promise.resolve();
    }
    message.error(t('请添加指标数据'));
    return Promise.reject(new Error(t('请添加指标数据')));
  };

  const items: any = [
    { title: t('填写名称、单位'), key: '1' },
    { title: t('填写指标数'), key: '2' },
    { title: t('预览趋势图'), key: '3' },
  ];

  return (
    <div className={styles.formBox}>
      <div className={styles.step}>
        <Steps current={current} items={items} type="navigation" status="process" />
      </div>
      <div className={styles.body}>
        <Form form={form} requiredMark={true} {...other} labelCol={{ flex: '0 0 146px' }}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <div className={styles.step1Box}>
            <Form.Item
              label={t('趋势图名称')}
              name="name"
              hidden={!isStep1}
              rules={[{ required: true, message: t('请输入趋势图名称') }]}
            >
              <Input placeholder={t('请输入趋势图名称')} maxLength={30} showCount />
            </Form.Item>
            <Form.Item
              label={t('X轴单位')}
              name="xaxisUnit"
              hidden={!isStep1}
              rules={[{ required: true, message: t('请输入X轴单位') }]}
            >
              <Input placeholder={t('请输入X轴单位')} maxLength={20} showCount />
            </Form.Item>
            <Form.Item
              label={t('Y轴单位')}
              name="yaxisUnit"
              hidden={!isStep1}
              rules={[{ required: true, message: t('请输入Y轴单位') }]}
            >
              <Input placeholder={t('请输入Y轴单位')} maxLength={20} showCount />
            </Form.Item>
            <Form.Item label={t('简要说明')} hidden={!isStep1} name="instruction">
              <Input placeholder={t('请输入简要说明')} maxLength={1000} showCount />
            </Form.Item>
          </div>
          <div className={styles.step2Box}>
            <Form.List
              name="correlationChartDatas"
              initialValue={[
                { xaxis: '', yaxis: '' },
                { xaxis: '', yaxis: '' },
              ]}
              rules={[{ validator: validatorData, message: t('请添加指标数据') }]}
            >
              {(fields, { add, remove }) => {
                if (!isStep2) return null;
                return (
                  <Table
                    columns={getColumns({ remove, fields })}
                    dataSource={fields}
                    pagination={false}
                    bordered
                    size="small"
                    footer={() => (
                      <div style={{ textAlign: 'center' }}>
                        <a onClick={() => add()}>
                          <PlusOutlined /> {t('新增')}
                        </a>
                      </div>
                    )}
                  />
                );
              }}
            </Form.List>
          </div>
          <Form.Item name="thumbnail" hidden={!isStep3} noStyle>
            <ChartLine config={lineData} />
          </Form.Item>
        </Form>
      </div>
      <div className={styles.footer}>
        <Space>
          <Button onClick={handleLast} loading={loading}>
            {isStep1 ? t('取消') : t('上一步')}
          </Button>
          <Button type="primary" onClick={handleNext} loading={loading}>
            {isStep3 ? t('提交') : t('下一步')}
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default FormModalContent;
