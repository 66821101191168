/*
 * @Description: 入库病例增长趋势
 * @Author: likaifeng
 * @Date: 2023-12-29 10:26:05
 * @LastEditTime: 2024-01-08 10:25:49
 * @LastEditors: likaifeng
 */
import usePermission from '@/hooks/usePermission';
import { Col, Row, Spin } from '@sinohealth/butterfly-ui-components';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useEnter, useEnterSummary } from '../../hook';
import { defaultLineOption } from '../../hook/config';
import { ImdtChartOption } from '../../hook/echartType';
import EmptyCanvas from '../EmptyCanvas';
import ImdtChart from '../ImdtChart';
import ToolCard from '../ToolCard';
import styles from './index.less';
import { useLocales } from '@/Locales';

/**
 * @description: 入库病例增长趋势
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-29 10:26:45
 */
const CasesIncreaseTrend: React.FC = () => {
  const prev = dayjs().subtract(364, 'day').format('YYYY-MM-DD');
  const next = dayjs().format('YYYY-MM-DD');

  const [defaultParams, setDefaultParams] = useState({
    agencyId: 0,
    startDate: prev,
    endDate: next,
  });

  const { enterSource, loadingEnter } = useEnter(defaultParams);
  const { enterSummarySource, loadingSummary } = useEnterSummary(defaultParams);
  const permission = usePermission('mdtStatistics');

  const { t } = useLocales();

  const options: ImdtChartOption = {
    ...defaultLineOption({
      // 整体布局
      grid: [
        {
          left: 40,
          top: 20,
          right: 20,
          bottom: 60,
        },
      ],
      tooltip: {
        formatter: `{b0}<br /> ${t('入库病例')}: {c0}`,
      },
    }),
    xAxis: {
      type: 'category',
      data: enterSource.map((el) => el.createTime),
      axisLine: {
        show: true,
      },
      axisLabel: {
        rotate: 0, // 坐标轴旋转
      },
      boundaryGap: true,
      axisTick: {
        alignWithLabel: false,
      },
    },
    // 数据
    series: [
      {
        data: enterSource.map((el) => el.total),
        type: 'line',
        lineStyle: {
          width: 2,
          color: '#53A8E2',
        },
        symbolSize: 5,
        itemStyle: {
          color: '#53A8E2',
          borderWidth: 1,
        },
        emphasis: {
          scale: 2.2,
        },
        smooth: true,
        areaStyle: {
          color: '#53a8e236',
        },
        selectedMode: 'series',
      },
    ],
  };

  return (
    <ToolCard
      agencyId={defaultParams.agencyId}
      pickerDate={[defaultParams?.startDate, defaultParams?.endDate]}
      agencyPermission={
        permission?.agencyDataListPage &&
        (permission?.statisticalSummary || permission?.statisticalEnter)
      }
      permission={permission?.statisticalSummary || permission?.statisticalEnter}
      onTime={(_, f) => {
        setDefaultParams({
          ...defaultParams,
          startDate: f[0],
          endDate: f[1],
        });
      }}
      onSelect={(v) => setDefaultParams({ ...defaultParams, agencyId: v })}
    >
      <Row>
        <Col span={7}>
          <Spin spinning={loadingEnter} tip={t('加载中...')}>
            <div className={styles.title}>{t('入库病例')}</div>
            {permission?.statisticalSummary ? (
              <ul className={styles['incoming-case']}>
                <li className={styles.case}>
                  <div className={styles.header}>
                    <div className={styles.label}>{t('累计入库病例')}</div>
                    <div className={styles.count}>{enterSummarySource?.patientNum || 0}</div>
                  </div>
                  <div className={styles.footer}>
                    <div className={styles.label}>{t('上月环比')}</div>
                    {!enterSummarySource?.chainRatio ? (
                      <div className={styles.count}>-</div>
                    ) : (
                      <div className={styles.count}>
                        {enterSummarySource?.chainRatio === '-'
                          ? enterSummarySource?.chainRatio
                          : `${enterSummarySource?.chainRatio}%`}
                      </div>
                    )}
                  </div>
                </li>
                <li className={styles.case}>
                  <div className={styles.header}>
                    <div className={styles.label}>{t('本月入库病例')}</div>
                    <div className={styles.count}>{enterSummarySource?.currentMonthNum || 0}</div>
                  </div>
                  <div className={styles.footer}>
                    <div className={styles.label}>{t('上月同比')}</div>
                    {!enterSummarySource?.yearRatio ? (
                      <div className={styles.count}>-</div>
                    ) : (
                      <div className={styles.count}>
                        {enterSummarySource?.yearRatio === '-'
                          ? enterSummarySource?.yearRatio
                          : `${enterSummarySource?.yearRatio}%`}
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            ) : null}
            {permission?.statisticalSummary ? null : (
              <div className={styles['incoming-case']}>
                <EmptyCanvas height={330} permission={permission?.statisticalSummary} />
              </div>
            )}
          </Spin>
        </Col>
        <Col span={17}>
          <ImdtChart
            loading={loadingSummary}
            title={t('入库病例增长趋势')}
            titleBorder={false}
            height={370}
            option={options}
            empty={!enterSummarySource || !permission?.statisticalEnter}
            permission={permission?.statisticalEnter}
          />
        </Col>
      </Row>
    </ToolCard>
  );
};

export default CasesIncreaseTrend;
