import { Form, Input, Row, Col } from '@sinohealth/butterfly-ui-components/lib';
import Upload from '@/components/Upload';
import { CloudUploadOutlined } from '@ant-design/icons';
import styles from './index.less';
import Image from '@/components/Image';
import { useLocales } from '@/Locales';
import Text from '@/components/Text';

const UploadImage = (props: any) => {
  const { value } = props;
  return (
    <Upload listType="text" accept={['.jpg', '.png']} maxSize={5} {...props}>
      <div className={styles.upload}>
        <div className={styles.uploadContent}>
          {
            value ? (
              <Image src={value} />
            ) : (
              <>
                <div><CloudUploadOutlined /></div>
                <div>
                  <Text>将图片拖到此处，或</Text>
                  &nbsp;
                  <a><Text>点击上传</Text></a>
                  &nbsp;
                </div>
                <div><Text>只能上传jpg/png文件，且不超过5M</Text></div>
              </>
            )
          }
        </div>
      </div>
    </Upload>
  );
};

const FormModalContent = (props: any) => {
  const { t } = useLocales();
  return (
    <Form layout="vertical" requiredMark={true} labelCol={{ span: 24 }} {...props}>
      <Form.Item name="id" hidden><Input /></Form.Item>
      <Form.Item label={t('对比图片名称')} name="name" rules={[{ required: true, message: t('请输入对比图片名称') }]}>
        <Input placeholder={t('请输入对比图片名称')} maxLength={50} showCount />
      </Form.Item>
      <Form.Item label={t('简要说明')} name="instruction">
        <Input placeholder={t('请输入简要说明')} maxLength={150} showCount />
      </Form.Item>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={t('对比图片')} labelCol={{ span: 12 }} name="previousImage" rules={[{ required: true, message: t('请上传对比图片') }]}>
            <UploadImage />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label=" " required={false} name="nextImage" rules={[{ required: true, message: t('请上传对比图片') }]}>
            <UploadImage />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FormModalContent;
