import { Form, Modal, ModalProps } from '@sinohealth/butterfly-ui-components/lib';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocales } from '@/Locales';

interface BaseFormModalProps extends ModalProps {
  formModal: any;
  onSubmit: (formData?: any) => Promise<any>;
  FormContent: any;
  confirmCancel?: boolean;
}

const BaseFormModal = (props: BaseFormModalProps) => {
  const { formModal, onSubmit, FormContent, confirmCancel = false, ...otherProps } = props;
  const [defaultFormData, setDefaultFormData] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const { t } = useLocales();

  useEffect(() => {
    if (!formModal) {
      return;
    }
    formModal.current = {
      form,
      openModal,
      closeModal,
    };
  }, []);
  const openModal = (defaultData: any = {}) => {
    setDefaultFormData(defaultData);
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    if (confirmCancel) {
      Modal.confirm({
        title: `${t('确认关闭弹窗')}？`,
        content: t('表单尚未提交，关闭弹窗会丢失未提交的内容'),
        onOk: closeModal,
      });
    } else {
      closeModal();
    }
  };

  const handleOk = (data?: any) => {
    form.submit();
  };

  const handleSubmit = (formValues: any) => {
    const formData = {
      ...defaultFormData,
      ...formValues,
    };
    setSubmitLoading(true);
    onSubmit(formData)
      .then(() => {
        closeModal();
        setSubmitLoading(false);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const renderFormContent = useMemo(() => {
    form.resetFields();
    form.setFieldsValue(defaultFormData);
    return (
      <FormContent
        defaultFormData={defaultFormData}
        form={form}
        colon={false}
        onFinish={handleSubmit}
        onCancel={closeModal}
        labelAlign="right"
        labelCol={{ span: 4 }}
      />
    );
  }, [defaultFormData]);

  if (!visible) return null;
  return (
    <Modal
      open={true}
      onCancel={handleCancel}
      onOk={handleOk}
      cancelButtonProps={{
        disabled: submitLoading,
      }}
      okButtonProps={{
        loading: submitLoading,
      }}
      {...otherProps}
    >
      {renderFormContent}
    </Modal>
  );
};

export const useFormModal = () => {
  const formModal: any = useRef();
  return formModal;
};

export default BaseFormModal;
