/*
 * @Description: 统计机构：/ 统计时间：
 * @Author: likaifeng
 * @Date: 2023-12-29 10:05:38
 * @LastEditTime: 2024-01-04 14:36:41
 * @LastEditors: likaifeng
 */
import { agencyListsAtom } from '@/store/statistics';
import { RangePickerProps } from '@sinohealth/butterfly-ui-antd/lib/date-picker';
import { DatePicker, Select, TimeRangePickerProps } from '@sinohealth/butterfly-ui-components';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styles from './index.less';
import { useLocales } from '@/Locales';

const { RangePicker } = DatePicker;

interface ToolCardProps {
  agencyId?: number;
  pickerDate?: string[];
  /** 统计机构全新啊 */
  agencyPermission?: boolean;
  /** 统计时间权限 */
  permission?: boolean;
  onSelect?: (value: any, option: any | any[]) => void;
  onTime?: (values: any, formatString: string[]) => void;
}

/**
 * @description: 统计机构：/ 统计时间：
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-29 10:06:26
 */
const ToolCard: React.FC<ToolCardProps> = (props) => {
  const { agencyId, pickerDate, agencyPermission, permission, onSelect, onTime } = props;

  const [defaultPickerDate, setDefaultPickerDate] = useState<string[]>([]);

  const agencyLists = useRecoilValue(agencyListsAtom);

  const { t } = useLocales();

  /**
   * @description: 时间选择
   * @author: likaifeng
   * @param {null} dates
   * @param {string} dateStrings
   * @return {*}
   * @Date: 2024-01-02 17:21:49
   */
  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    onTime && onTime(dates, dateStrings);
  };

  /**
   * @description: 禁用时间
   * @author: likaifeng
   * @param {*} current
   * @return {*}
   * @Date: 2023-12-28 14:56:50
   */
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day');
  };

  /**
   * @description: 时间默认配置
   * @author: likaifeng
   * @return {*}
   * @Date: 2024-01-02 17:22:00
   */
  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: '近7天', value: [dayjs().add(-6, 'd'), dayjs()] },
    { label: '近15天', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: '近30天', value: [dayjs().add(-29, 'd'), dayjs()] },
    { label: '近3月', value: [dayjs().add(-89, 'd'), dayjs()] },
    { label: '近半年', value: [dayjs().add(-179, 'd'), dayjs()] },
    { label: '近一年', value: [dayjs().add(-364, 'd'), dayjs()] },
  ];

  const dateFormat = 'YYYY-MM-DD';

  useEffect(() => {
    // console.log(pickerDate);
    if (pickerDate?.length) {
      setDefaultPickerDate(pickerDate);
    } else {
      const prev = dayjs().subtract(365, 'day').format('YYYY-MM-DD');
      const next = dayjs().format('YYYY-MM-DD');
      setDefaultPickerDate([prev, next]);
    }
  }, [pickerDate]);

  return (
    <div className={styles['tool-card']}>
      <ul className={styles['card-header']}>
        {agencyPermission ? (
          <li className={styles.card}>
            <div className={styles['card-label']}>{t('统计机构')}：</div>
            <Select
              value={agencyId}
              className={styles.selected}
              style={{ width: 200 }}
              fieldNames={{ label: 'name', value: 'id' }}
              options={agencyLists}
              onChange={onSelect}
            />
          </li>
        ) : null}
        {permission ? (
          <li className={styles.card}>
            <div className={styles['card-label']}>{t('统计时间')}：</div>
            <RangePicker
              className={styles.picker}
              value={[
                dayjs(dayjs(defaultPickerDate?.[0]).format(dateFormat), dateFormat),
                dayjs(dayjs(defaultPickerDate?.[1]).format(dateFormat), dateFormat),
              ]}
              presets={rangePresets}
              disabledDate={disabledDate}
              allowClear={false}
              onChange={onRangeChange}
            />
          </li>
        ) : null}
      </ul>
      <div className={styles['card-footer']}>{props.children}</div>
    </div>
  );
};

export default ToolCard;
