import React, { useEffect, useState } from 'react';
import { DatePicker, DatePickerProps } from '@sinohealth/butterfly-ui-components';
import dayjs from 'dayjs';

const DateInput = (props: DatePickerProps) => {
  const { value, onChange, ...otherProps } = props;
  const [dayValue, setDayValue] = useState<any>();
  useEffect(() => {
    if (value) {
      if (typeof value === 'string') {
        setDayValue(dayjs(value));
        return;
      }
      setDayValue(value);
    } else {
      setDayValue(null);
    }
  }, [value]);

  const handleChange = (date: any, dateString: string) => {
    // @ts-ignore
    onChange && onChange(dateString);
  };
  return <DatePicker value={dayValue} onChange={handleChange} {...otherProps} />;
};

DateInput.RangePicker = (props: any) => {
  const { value, onChange, ...otherProps } = props;
  const [dayValue, setDayValue] = useState<any>([]);
  useEffect(() => {
    if (Array.isArray(value)) {
      setDayValue(value.map((d) => {
        if (typeof d === 'string') {
          return dayjs(d);
        }
        return d;
      }));
    } else {
      setDayValue(null);
    }
  }, [value]);

  const handleChange = (date: any, dateString: string) => {
    // @ts-ignore
    onChange && onChange(dateString);
  };
  return <DatePicker.RangePicker value={dayValue} onChange={handleChange} {...otherProps} />;
};

export default DateInput;
