import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { httpGetIdentityById } from '@/services/user';
import { getSystemUserInfo } from '@/utils/cookies';
import { userInfoAtom } from '@/store/atom';
import { apiInstitutionAgencyListPage } from '@/services/masterData';
import { getAgencyList } from '@/services/common';

let lock = false;
const useCurrentLoginUserInfo = () => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (userInfo || lock) {
      return;
    }
    fetchData();
  }, []);

  const fetchData = async () => {
    lock = true;
    try {
      const info = getSystemUserInfo(); // 登录后本地存储的数据
      const id = info?.user?.id;
      if (id) {
        const userData: any = await fetchUserInfo(id);
        const agencys = await fetchUserTeam(userData?.user?.id); // 当前用户所属机构
        const userInfoData = {
          ...userData,
          agency: agencys[0] || {},
          agencys,
        };
        console.log('当前用户信息');
        console.log(userInfoData);
        setUserInfo(userInfoData);
      } else {
        navigate('/login');
      }
      setIsError(false);
    } catch (error) {
      console.log('初始化用户信息报错');
      console.log(error);
      setIsError(true);
      navigate('/login');
    }
    lock = false;
  };

  const fetchUserInfo = async (id: string) => {
    return httpGetIdentityById(id)
      .then((res) => {
        return res;
      });
  };

  const fetchUserTeam = (userId: any) => {
    return getAgencyList(userId).then((data:any) => {
      return data;
    });
  };
  return [userInfo, isError];
};

export default useCurrentLoginUserInfo;
