/*
 * @Description: 数据概览
 * @Author: likaifeng
 * @Date: 2023-12-28 11:58:31
 * @LastEditTime: 2024-08-20 09:45:15
 * @LastEditors: likaifeng
 */
import usePermission from '@/hooks/usePermission';
import { useLocales } from '@/Locales';
import { Col, Row } from '@sinohealth/butterfly-ui-components';
import React from 'react';
import { useSync } from '../../hook';
import styles from '../../index.less';
import FieldCanvas from '../FieldCanvas';
import MapCanvas from '../MapCanvas';
import MdtAgencyRanking from '../MdtAgencyRanking';
import MdtTrendRecentDecember from '../MdtTrendRecentDecember';

/**
 * @description: 数据概览
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-28 11:58:55
 */
const OverviewAnalysis: React.FC = () => {
  const { syncSource, syncLoading } = useSync();
  const { t } = useLocales();

  const permission = usePermission('mdtStatistics');

  return (
    <>
      <div className={styles['statistics-header']}>
        <Row gutter={16}>
          <Col span={6}>
            <div className={styles['header-left']}>
              <MdtTrendRecentDecember />
              <MdtAgencyRanking />
            </div>
          </Col>
          <Col span={18}>
            <MapCanvas />
          </Col>
        </Row>
      </div>
      <div className={styles['statistics-footer']}>
        <Row gutter={16}>
          <Col span={6}>
            <FieldCanvas
              loading={syncLoading}
              title={t('已开展MDT场次')}
              count={syncSource?.consulCount}
              ratio={syncSource?.mdtChainRatio}
              basis={syncSource?.yearMdtRatio}
              sessionLabel={t('上月场次')}
              session={syncSource?.lastMonthMdtCount}
              empty={permission?.mdtSessionsCarriedOut}
            />
          </Col>
          <Col span={6}>
            <FieldCanvas
              loading={syncLoading}
              title={t('团队数')}
              count={syncSource?.teamCount}
              ratio={syncSource?.teamChainRatio}
              basis={syncSource?.yearTeamRatio}
              sessionLabel={t('上月新增')}
              session={syncSource?.lastMonthTeamCount}
              empty={permission?.numberOfTeams}
            />
          </Col>
          <Col span={6}>
            <FieldCanvas
              loading={syncLoading}
              title={t('合作专家数')}
              count={syncSource?.doctorCount}
              ratio={syncSource?.doctorChainRatio}
              basis={syncSource?.yearDoctorRatio}
              sessionLabel={t('上月新增')}
              session={syncSource?.lastMonthDoctorCount}
              empty={permission?.numberOfExperts}
            />
          </Col>
          <Col span={6}>
            <FieldCanvas
              loading={syncLoading}
              title={t('病例总数')}
              count={syncSource?.patientCount}
              ratio={syncSource?.patientChainRatio}
              basis={syncSource?.yearPatientRatio}
              sessionLabel={t('上月新增')}
              session={syncSource?.lastMonthPatientCount}
              empty={permission?.totalNumberOfCases}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OverviewAnalysis;
