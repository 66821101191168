import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import dictAtom from '@/store/dict';
import locationDictData from '@/hooks/dict/locationDictData';
import { useLocales } from '@/Locales';

/*
* 获取字典选项数据
* @params: dictName 字典类型key
* @return: [{ label: '', value: '' }]
* */
const useDictOptions = (dictName: string = '') => {
  const [dictOptions, setDictOptions] = useRecoilState<any>(dictAtom);
  const { t, locales } = useLocales();

  useEffect(() => {
    handleSetDictOptions();
  }, []);

  useEffect(() => {
    handleSetDictOptions();
  }, [locales]);

  const handleSetDictOptions = () => {
    const d = {};
    Object.keys(locationDictData).forEach((key) => {
      // @ts-ignore
      d[key] = locationDictData[key].map((item) => {
        return {
          ...item,
          label: t(item.label),
        };
      });
    });
    setDictOptions(d);
  };

  return dictName ? dictOptions[dictName] : dictOptions;
};

export default useDictOptions;
