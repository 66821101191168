import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Space, Badge, DatePicker } from '@sinohealth/butterfly-ui-components';
import dayjs from 'dayjs';
import styles from './index.less';
import usePermission from '@/hooks/usePermission';
import { PatientInfoStatus } from '@/pages/patient/detail';
import Text from '@/components/Text';

type TabHeaderProps = {
  title: React.ReactNode | string,
  label?: React.ReactNode | string,
  disabled?: Boolean,
  action?: React.ReactNode
}
const TabHeader = (props: TabHeaderProps) => {
  const { title, action, disabled = true, label } = props;
  const permission = useRecoilValue(PatientInfoStatus);

  return (
    <div className={styles.tabHeader}>
      <div className={styles.left}>
        <Badge status="processing" />&nbsp;&nbsp;&nbsp;{label || <Text>检查时间</Text>}：{title}
      </div>
      <Space className={styles.right}>
        {
          (permission.edit || !disabled) ? action : ''
        }
      </Space>
    </div>
  );
};

export const TabHeaderInput = (props: any) => {
  const { value, label, onChange, disabled = false, action } = props;
  const dateValue = dayjs(value || Date.now());
  useEffect(() => {
    onChange(dateValue.format('YYYY-MM-DD'));
  }, []);
  const title = disabled ? value : (
    <DatePicker value={dateValue} onChange={onChange} allowClear={false} style={{ width: 240 }} />
  );
  return <TabHeader label={label} title={title} disabled={disabled} action={action} />;
};

export default TabHeader;
