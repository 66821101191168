import React, { useRef, useEffect } from 'react';
import { Line } from '@ant-design/plots';
import { base64ToFile } from '@/utils';
import { uploadFile } from '@/services/common';

const ChartLine = (props: any) => {
  const canvas = useRef<any>(null);
  const { config = {}, onChange } = props;
  const { name, xaxisUnit, yaxisUnit, correlationChartDatas } = config;

  useEffect(() => {
    if (canvas.current) {
      setTimeout(() => {
        toDataURL();
      }, 600);
    }
  }, [canvas]);
  const lineConfig: any = {
    data: correlationChartDatas?.map((item: any) => {
      return {
        ...item,
        yaxis: Number(item.yaxis),
      };
    }),
    padding: 60,
    height: 340,
    xField: 'xaxis',
    yField: 'yaxis',
    xAxis: {
      title: {
        text: xaxisUnit,
        position: 'end',
        autoRotate: true,
      },
    },
    yAxis: {
      title: {
        text: yaxisUnit,
        position: 'end',
        autoRotate: true,
      },
    },
    theme: {
      styleSheet: {
        backgroundColor: '#f5f5f5', // background: #8bafe359;
      },

    },
    onReady(chart: any) {
      canvas.current = chart;
    },
  };
  // 获取图表 base64 数据
  const toDataURL = () => {
    const imgUrl = canvas.current?.toDataURL();
    onChange && onChange(imgUrl);
    // canvas.current?.downloadImage();
  };
  return (
    <div style={{ background: '#fff', borderRadius: 4 }}>
      {
        config.name && <Line key={config.key} {...lineConfig} />
      }
    </div>
  );
};

export default ChartLine;
