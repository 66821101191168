/*
 * @Description: 团队MDT排名
 * @Author: likaifeng
 * @Date: 2023-12-28 15:29:34
 * @LastEditTime: 2024-01-04 14:32:37
 * @LastEditors: likaifeng
 */
import usePermission from '@/hooks/usePermission';
import * as echarts from 'echarts/core';
import React from 'react';
import { useRank } from '../../hook';
import { defaultPieOption } from '../../hook/config';
import { ImdtChartOption } from '../../hook/echartType';
import ImdtChart from '../ImdtChart';
import styles from './index.less';
import { useLocales } from '@/Locales';

/**
 * @description: 团队MDT排名
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-28 15:31:29
 */
const TeamMdtRank: React.FC = () => {
  const { rankSource, loadingRank } = useRank();
  const permission = usePermission('mdtStatistics');
  const { t } = useLocales();

  const options: ImdtChartOption = {
    ...defaultPieOption({
      legend: {
        bottom: 10,
        data: [
          {
            name: t('病例讨论'),
            itemStyle: {
              color: '#306CD5',
            },
          },
          {
            name: t('病例分享'),
            itemStyle: {
              color: '#3BCBFF',
            },
          },
        ],
      },
    }),
    grid: {
      left: 100,
      top: 20,
      right: 20,
      bottom: 70,
    },
    xAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: '#4288ff5e',
          type: 'dashed',
          width: 0.6,
        },
      },
    },
    yAxis: {
      type: 'category',
      data: rankSource.map((el: any) => el.name),
      axisLabel: {
        hideOverlap: true,
        formatter(value: string) {
          if (value.length > 6) {
            // 限制长度自设
            return `${value.substr(0, 6)}...`;
          }
          return value;
        },
      },
      axisTick: {
        alignWithLabel: true,
      },
    },
    tooltip: {
      axisPointer: {
        type: 'shadow',
      },
      trigger: 'axis',
      backgroundColor: '#00244c99',
      borderWidth: 0,
      textStyle: {
        color: '#FFFFFF',
      },
    },
    // 数据
    series: [
      {
        data: rankSource?.map((item) => item.invitationRecordNum),
        type: 'bar',
        name: t('病例讨论'),
        barMaxWidth: 15,
        itemStyle: {
          borderRadius: [0, 14, 14, 0],
          color: (params) => {
            const colorList = ['#306CD5', '#7cade159'];
            return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
              {
                // 代表渐变色从正上方开始
                offset: 0, // offset范围是0~1，用于表示位置，0是指0%处的颜色
                color: colorList?.[0],
              }, // 柱图渐变色
              {
                offset: 1, // 指100%处的颜色
                color: colorList?.[1],
              },
            ]);
          },
        },
      },
      {
        data: rankSource?.map((item) => item.consulNum),
        type: 'bar',
        name: t('病例分享'),
        barMaxWidth: 15,
        itemStyle: {
          borderRadius: [0, 14, 14, 0],
          color: (params) => {
            const colorList = ['#3BCBFF', '#7ccee159'];
            return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
              {
                // 代表渐变色从正上方开始
                offset: 0, // offset范围是0~1，用于表示位置，0是指0%处的颜色
                color: colorList?.[0],
              }, // 柱图渐变色
              {
                offset: 1, // 指100%处的颜色
                color: colorList?.[1],
              },
            ]);
          },
        },
      },
    ],
  };

  return (
    <div className={styles['team-mdt-rank']}>
      <ImdtChart
        loading={loadingRank}
        title={t('团队MDT排名')}
        height={447}
        option={options}
        empty={!rankSource.length || !permission?.statisticalRank}
        permission={permission?.statisticalRank}
      />
      {/* <div className={styles.title}>团队MDT排名</div> */}
      {/* <ul className={styles['mdt-rank']}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((el) => (
          <li key={el} className={styles.rank}>
            <div className={styles.name}>广东省肿瘤医院</div>
            <Tooltip
              title={
                <div>
                  <div className={styles['tip-name']}>广东省肿瘤医院MDT团队</div>
                  <div className={styles['tip-label']}>病例讨论：0.8</div>
                </div>
              }
              color="#00244c99"
            >
              <div className={classNames(styles.progress)}>
                <div
                  className={classNames(
                    styles['progress-discussion'],
                    el % 2 !== 0 && styles['progress-share'],
                  )}
                  style={{ width: `${el}0%` }}
                />
                <div
                  className={el % 2 === 0 ? styles.loop : styles['share-loop']}
                  style={{
                    right: el > 10 ? '-12px' : `calc(100% - ${el}0% - 12px)`,
                  }}
                />
              </div>
            </Tooltip>
          </li>
        ))}
      </ul>
      <ul className={styles['rank-color']}>
        <li className={styles.color}>病例讨论</li>
        <li className={styles.color}>病例分享</li>
      </ul> */}
    </div>
  );
};

export default TeamMdtRank;
