import { useState, useEffect } from 'react';
import { httpGetAgencyScoringStrategy } from '@/services/team';
import useCurrentLoginUserInfo from '@/hooks/useCurrentLoginUserInfo';
// 获取评分策略
const useAgencyScoringStrategy = (userId?: string) => {
  const [strategyData, setStrategyData] = useState<any>();
  const [userinfo] = useCurrentLoginUserInfo();
  const id = userId || userinfo?.user?.id;
  useEffect(() => {
    if (id) {
      httpGetAgencyScoringStrategy(id)
        .then((res) => {
          setStrategyData(res);
        });
    }
  }, [userinfo]);

  return strategyData;
};

export default useAgencyScoringStrategy;
