/*
 * @Description: MDT数据统计 状态管理
 * @Author: likaifeng
 * @Date: 2023-12-29 16:56:41
 * @LastEditTime: 2024-01-02 13:50:09
 * @LastEditors: likaifeng
 */
import { atom } from 'recoil';

/**
 * @description: MDT数据统计 - BI 配置列表 agencyIds
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const agencyIdsAtom = atom<any>({
  key: 'agencyIds',
  default: [],
});

/**
 * @description: MDT数据统计 - 统计机构
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-29 20:30:22
 */
export const agencyListsAtom = atom<any>({
  key: 'agencyLists',
  default: [],
});
