import localEnum from '@/hooks/dict/localEnum';
import { useLocales } from '@/Locales';
import { updatePatientIssue } from '@/services/patient';
import { speechFilterEditAtom, speechUpdateTimeAtom } from '@/store/speech';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { message } from '@sinohealth/butterfly-ui-antd';
import { Button, Form, Input, Radio } from '@sinohealth/butterfly-ui-components/lib';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

const IssueForm = (props: any) => {
  const [form] = Form.useForm();
  const { t } = useLocales();
  const { MDT_ISSUES_TYPE, LETTER_LIST } = localEnum;

  const speechFilterEditFlag = useRecoilValue(speechFilterEditAtom);
  const speechUpdateTime = useRecoilValue(speechUpdateTimeAtom);
  const [firstRender, setFirstRender] = useState(true);
  const [issuePlaceholder, setIssuePlaceholder] = useState('请输入需讨论的议题');
  const [options] = useState<string[]>(['', '']);

  const values = Form.useWatch([], form);

  const typeList = [
    { value: MDT_ISSUES_TYPE.VOTING, label: t('投票型议题') },
    { value: MDT_ISSUES_TYPE.DISCUSSION, label: t('讨论型议题') },
  ];

  useEffect(() => {
    if (props?.type === MDT_ISSUES_TYPE.VOTING) {
      setIssuePlaceholder(t('请输入需投票的议题'));
    } else {
      setIssuePlaceholder(t('请输入需讨论的议题'));
    }
  }, [props?.type]);

  useEffect(() => {
    try {
      form?.validateFields().then(
        () => {
          if (speechFilterEditFlag && !firstRender) {
            // console.log({ values, firstRender });
            throttledOnFieldsChange(values);
          }
        },
        (err) => {
          // console.log(err);
        },
      );
    } catch (err) {
      console.log(err);
    }
  }, [form, values]);

  // 使用 throttle 函数限制 onFieldsChange 的执行频率
  const throttledOnFieldsChange = useCallback(
    _.debounce(
      (val) => {
        // console.log('Fields changed', val);
        if (val?.id) {
          const empty = val?.options?.filter((el: string) => !el);
          if (empty?.length) {
            // message.error('问题选项不能为空');
            return;
          }
          updatePatientIssue({
            ...val,
          }).then(() => {
            console.log('更新成功');
            props?.onUpdate && props?.onUpdate();
          });
        }
      },
      speechUpdateTime,
      { leading: false },
    ),
    [],
  ); // 3000 milliseconds = 3

  useEffect(() => {
    if (speechFilterEditFlag) {
      const timer = setTimeout(() => {
        setFirstRender(false);
        clearTimeout(timer);
      }, 3000);
    } else {
      setFirstRender(true);
    }
  }, [speechFilterEditFlag]);

  return (
    <Form layout="horizontal" requiredMark={false} form={form} {...props}>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="type" hidden>
        <Radio.Group defaultValue={props?.type}>
          {typeList.map((item) => {
            return (
              <Radio key={item.value} value={item.value}>
                {' '}
                {item.label}{' '}
              </Radio>
            );
          })}
        </Radio.Group>
      </Form.Item>
      {props?.type === MDT_ISSUES_TYPE.DISCUSSION && (
        <Form.Item name="issue" rules={[{ required: true, message: issuePlaceholder }]}>
          <Input.TextArea
            placeholder={issuePlaceholder}
            maxLength={100}
            showCount
            autoSize={{ minRows: 6, maxRows: 6 }}
          />
        </Form.Item>
      )}
      {props?.type === MDT_ISSUES_TYPE.VOTING && (
        <>
          <Form.Item name="issue" rules={[{ required: true, message: issuePlaceholder }]}>
            <Input placeholder={issuePlaceholder} maxLength={100} />
          </Form.Item>

          <Form.Item>
            <Form.List name="options" initialValue={options}>
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field, inx: number) => (
                      <Form.Item key={field.name} label={`${LETTER_LIST[inx]}.`}>
                        <Form.Item {...field} noStyle>
                          <Input
                            placeholder={t('请输入选项内容')}
                            maxLength={15}
                            style={{ width: '90%' }}
                          />
                        </Form.Item>
                        <Form.Item noStyle>
                          {inx === fields.length - 1 && (
                            <Button
                              type="link"
                              disabled={fields.length <= 2}
                              icon={<MinusCircleOutlined />}
                              onClick={() => {
                                if (fields.length <= 2) {
                                  message.error(t('最少需要2个选项'));
                                  return;
                                }
                                remove(field.name);
                              }}
                            />
                          )}
                        </Form.Item>
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="link"
                        icon={<PlusCircleOutlined />}
                        disabled={fields.length >= 5}
                        onClick={() => add()}
                      >
                        {t('增加选项')}
                      </Button>
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default IssueForm;
