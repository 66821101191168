import { request } from '@/common/request';

// 全局请求前缀
export const apiPrefix = '/admin-imdt/api';

// 下载文件
export const download = (key: string) => {
  const url = '/cos/download';
  return request.post(
    url,
    { key },
    {
      // @ts-ignore
      hiddenErrorMsg: true,
    },
  );
};

export default {
  apiPrefix,
};
