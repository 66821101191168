/* eslint-disable no-promise-executor-return */
/*
 * @Description: 讲稿演示-主要内容
 * @Author: likaifeng
 * @Date: 2023-11-30 16:10:48
 * @LastEditTime: 2024-08-13 17:25:56
 * @LastEditors: likaifeng
 */
import { FloatButton } from '@sinohealth/butterfly-ui-components';
import { lazy, memo } from 'react';
import SpeechAdmitted from '../SpeechAdmitted';
// import SpeechContrast from '../SpeechContrast';
// import SpeechDischarge from '../SpeechDischarge';
// import SpeechFooter from '../SpeechFooter';
// import SpeechGene from '../SpeechGene';
// import SpeechInspect from '../SpeechInspect';
// import SpeechMedica from '../SpeechMedica';
// import SpeechOtherInfo from '../SpeechOtherInfo';
// import SpeechPathological from '../SpeechPathological';
import SpeechPatient from '../SpeechPatient';
import SpeechSummary from '../SpeechSummary';
// import SpeechSurgery from '../SpeechSurgery';
// import SpeechTendency from '../SpeechTendency';
// import SpeechVideoReport from '../SpeechVideoReport';
import styles from './index.less';

// const SpeechPatient = lazy(() => import('../SpeechPatient'));
// const SpeechSummary = lazy(() => import('../SpeechSummary'));
// const SpeechAdmitted = lazy(() => import('../SpeechAdmitted'));
const SpeechDischarge = lazy(() => import('../SpeechDischarge'));
const SpeechVideoReport = lazy(() => import('../SpeechVideoReport'));
const SpeechSurgery = lazy(() => import('../SpeechSurgery'));
const SpeechPathological = lazy(() => import('../SpeechPathological'));
const SpeechInspect = lazy(() => import('../SpeechInspect'));
const SpeechGene = lazy(() => import('../SpeechGene'));
const SpeechMedica = lazy(() => import('../SpeechMedica'));
const SpeechContrast = lazy(() => import('../SpeechContrast'));
const SpeechTendency = lazy(() => import('../SpeechTendency'));
const SpeechOtherInfo = lazy(() => import('../SpeechOtherInfo'));
const SpeechFooter = lazy(() => import('../SpeechFooter'));

/**
 * @description: 讲稿演示-主要内容
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:27:21
 */
const SpeechMain = memo(() => {
  return (
    <div id="speechMain" className={styles['speech-main']}>
      <SpeechPatient />
      <SpeechSummary />
      <SpeechAdmitted />
      <SpeechDischarge />
      <SpeechVideoReport />
      <SpeechSurgery />
      <SpeechPathological />
      <SpeechInspect />
      <SpeechGene />
      <SpeechMedica />
      <SpeechContrast />
      <SpeechTendency />
      <SpeechOtherInfo />
      <SpeechFooter />
      <FloatButton.BackTop target={() => document.getElementById('speechMain') as HTMLElement} />
    </div>
  );
});

export default SpeechMain;
