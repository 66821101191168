/* eslint-disable indent */
/*
 * @Description: 讲稿演示-MDT议题
 * @Author: likaifeng
 * @Date: 2023-11-30 19:23:34
 * @LastEditTime: 2024-08-12 11:30:01
 * @LastEditors: likaifeng
 */
import BaseFormModal from '@/common/components/BaseFormModal';
import localEnum from '@/hooks/dict/localEnum';
import { useLocales } from '@/Locales';
import IssueBarEchart from '@/pages/consultationCenter/components/IssueBarEchart';
import AddForm from '@/pages/patient/detail/components/MdtIssuesTab/components/AddForm';
import { useIssueVoteStatistics } from '@/pages/speechLecture/hook/make';
import { useIssueList } from '@/pages/speechLecture/hook/makeSpeech';
import { addPatientIssue, deletePatientIssue } from '@/services/patient';
import {
  speechFilterEditAtom,
  speechFilterMakeDatasAtom,
  speechIssueFlagsAtom,
} from '@/store/speech';
import { Button, Empty, message } from '@sinohealth/butterfly-ui-components';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styles from './index.less';
import IssueForm from './IssueForm';

/**
 * @description: 讲稿演示-MDT议题
 * @author: likaifeng
 * @param {*} props
 * @return {*}
 * @Date: 2023-12-04 14:27:11
 */
const SpeechIssue: React.FC<any> = (props: any) => {
  const { consultationId } = props;

  const formModal = useRef<any>();
  const [beforeSeriesDataMap, setBeforeSeriesDataMap] = useState<any>({});
  const [afterSeriesDataMap, setAfterSeriesDataMap] = useState<any>({});
  const [speechIssueFlag, setSpeechIssueFlag] = useRecoilState(speechIssueFlagsAtom);
  const speechFilterMakeData = useRecoilValue(speechFilterMakeDatasAtom);
  const speechFilterEditFlag = useRecoilValue(speechFilterEditAtom);
  const { t } = useLocales();

  const { setReload, issueList, patientId } = useIssueList();
  const { issueVoteStatistics } = useIssueVoteStatistics(consultationId) as any;

  const { MDT_ISSUES_TYPE, LETTER_LIST } = localEnum;

  const handleCreate = () => {
    formModal.current?.openModal();
  };

  const onDelete = (item: any) => {
    deletePatientIssue({
      id: item.id,
    }).then(() => {
      message.success(t('删除成功'));
      setReload(true);
    });
  };

  const handleSubmit = (formValue: any) => {
    const newFormValue = JSON.parse(JSON.stringify(formValue));

    newFormValue.type = !newFormValue.type ? MDT_ISSUES_TYPE.VOTING : newFormValue.type;
    if (newFormValue.type === MDT_ISSUES_TYPE.VOTING) {
      if (!newFormValue?.options || newFormValue?.options.length < 2) {
        message.error('最少需要2个选项');
        return Promise.reject();
      }
      const empty = newFormValue?.options?.filter((el: string) => !el);
      if (empty.length) {
        message.error('问题选项不能为空');
        return Promise.reject();
      }
    }
    return addPatientIssue({
      ...newFormValue,
      patientId,
    }).then(() => {
      setReload(true);
    });
  };

  useEffect(() => {
    if (issueVoteStatistics && issueVoteStatistics?.length > 0) {
      const tempBefore = {} as any;
      const tempAfter = {} as any;
      issueVoteStatistics.forEach((item: any) => {
        if (item.timing === 'before') {
          tempBefore[item.issueId] = {
            percentageResult: item.percentageResult || [],
            peopleResult: item.peopleResult || [],
          };
        } else {
          tempAfter[item.issueId] = {
            percentageResult: item.percentageResult || [],
            peopleResult: item.peopleResult || [],
          };
        }
      });
      if (Object.keys(tempBefore).length) {
        setBeforeSeriesDataMap(tempBefore);
      }
      if (Object.keys(tempAfter).length) {
        setAfterSeriesDataMap(tempAfter);
      }
    }
  }, [issueVoteStatistics]);

  return speechIssueFlag ? (
    <div className={styles['speech-issue']}>
      <div className={styles['issue-header']}>
        <div className={styles.issue}>
          <i className="iconfont icon-fuzhiwenjian" style={{ marginRight: 5 }} />
          {t('MDT议题')}
        </div>
        {speechFilterEditFlag && (
          <Button type="link" onClick={handleCreate}>
            {t('新增')}
          </Button>
        )}
        <i
          className={classNames('iconfont icon-choutizhankai', styles['issue-close'])}
          onClick={() => setSpeechIssueFlag(false)}
        />
      </div>
      <div className={styles['issue-container']}>
        {issueList?.map((el: any, index: number) => (
          <div key={el.id} className={styles['issue-item']}>
            <div className={styles['item-header']}>
              <div className={styles['header-icon']}>
                {t('议题')}
                {index + 1}
              </div>
              {speechFilterEditFlag && (
                <>
                  {/* <Button type="link">编辑</Button> */}
                  <Button type="link" danger onClick={() => onDelete(el)}>
                    {t('删除')}
                  </Button>
                </>
              )}
            </div>
            {speechFilterEditFlag ? (
              <IssueForm
                initialValues={el}
                type={el.type}
                name={`issue_${el.id}`}
                onUpdate={() => setReload(true)}
              />
            ) : (
              <>
                <div className={styles.container}>{el.issue}</div>

                {el.type === MDT_ISSUES_TYPE.VOTING && el.options && (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {el.options.map((opt: string, inx: number) => {
                      const key = `${opt}-${inx.toString()}`;
                      return (
                        <span key={key} style={{ marginRight: '16px' }}>
                          {LETTER_LIST[inx]}.{opt}
                        </span>
                      );
                    })}
                  </div>
                )}
              </>
            )}

            {speechFilterMakeData?.pushedIssueVote === true &&
              el.type === MDT_ISSUES_TYPE.VOTING && (
                <div>
                  {beforeSeriesDataMap[el.id] && (
                    <div style={{ marginTop: '8px' }}>
                      <IssueBarEchart title="MDT前" seriesData={beforeSeriesDataMap[el.id]} />
                    </div>
                  )}
                  {afterSeriesDataMap[el.id] && (
                    <div style={{ marginTop: '8px' }}>
                      <IssueBarEchart title="MDT后" seriesData={afterSeriesDataMap[el.id]} />
                    </div>
                  )}
                </div>
              )}
          </div>
        ))}
        {!issueList.length && <Empty description={t('暂无数据')} />}
      </div>
      <BaseFormModal
        title="新增MDT议题"
        formModal={formModal}
        onSubmit={handleSubmit}
        FormContent={AddForm}
        width={600}
      />
    </div>
  ) : (
    <div className={styles['issue-open']} onClick={() => setSpeechIssueFlag(!speechIssueFlag)}>
      <div>
        MDT
        <br />
        {t('议题')}
      </div>
      <i className="iconfont icon-choutishouqi" />
    </div>
  );
};

export default SpeechIssue;
