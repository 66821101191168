import React, { useState, useEffect, useRef, createContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import TimeLine from '@/pages/patient/detail/components/TimeLine';
import { PatientInfoAtom } from '@/pages/patient/detail';
import Empty from '@/pages/patient/detail/components/Empty';
import styles from './index.less';
import OcrInfo from '@/pages/patient/detail/components/OcrInfo';
import dayjs from 'dayjs';
import { useLocales } from '@/Locales';

export const OcrContext = createContext<any>({
  open: false,
  data: null,
});
const TabItem = (props: any) => {
  const { fieldKey, label, renderChildren, onDelete } = props;
  const timeLineRef = useRef<any>(null);
  const [timeLine, setTimeLine] = useState<any[]>([]);
  const [selectedDate, setSelectedData] = useState<any>({});
  const [ocrConfig, setOcrConfig] = useState<any>({});
  const patientInfo = useRecoilValue(PatientInfoAtom);
  const { t } = useLocales();
  const recordsData = patientInfo[fieldKey] || [];
  useEffect(() => {
    setTimeLine(recordsData.map((item: any) => {
      return {
        label: item.recordTime || item.dischargeTime || item.informationDate,
        value: item.id,
        data: item,
      };
    }).sort((item: any, nextItem: any) => {
      return dayjs(item.label).diff(dayjs(nextItem.label)) * -1;
    }));
  }, [patientInfo]);

  const handleCreate = () => {
    // 通过时间轴插入新数据，再反显到已选择的日期里，判断无id为新增
    timeLineRef.current?.handleCreate();
  };

  const isEmpty = !selectedDate.key;

  const ocrContextValue = useMemo(() => {
    return {
      ocrConfig,
      setOcrConfig,
    };
  }, [ocrConfig, setOcrConfig]);

  const content = useMemo(() => {
    if (!selectedDate.key) {
      return null;
    }
    // renderChildren在编辑时不会修改key，所以需要加个时间戳
    return renderChildren(selectedDate.key + Date.now(), selectedDate);
  }, [selectedDate]);
  return (
    <OcrContext.Provider value={ocrContextValue}>
      { isEmpty && <Empty btnText={`${t('新增')}${label}`} onCreate={handleCreate} />}
      <div className={styles.tabBody} style={{ width: isEmpty ? 0 : '100%' }}>
        <div className={styles.left}>
          <TimeLine ref={timeLineRef} label={label} data={timeLine} value={selectedDate.key} onDelete={onDelete} onChange={(v: string, data: any) => setSelectedData({ ...data, key: v })} />
        </div>
        <div className={styles.right}>
          { content }
        </div>
      </div>
      <OcrInfo ocrConfig={ocrConfig} />
    </OcrContext.Provider>
  );
};

export default TabItem;
