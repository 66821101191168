import { Form, Input } from '@sinohealth/butterfly-ui-components/lib';
import { useLocales } from '@/Locales';

const AddFollowUpModal = (props: any) => {
  const { t } = useLocales();
  const changeValue = (e: any) => {
    const val: any = e.target.value;
    const weight = /^[0-9]*$/.test(val) ? String(val).replace('.', '') : '';
    props.form.setFieldsValue({ weight });
  };

  return (
    <Form {...props} layout="vertical">
      <Form.Item
        label={t('标签名称')}
        name="name"
        rules={[{ required: true, message: t('请输入标签名称') }]}
      >
        <Input style={{ width: '100%' }} placeholder={t('请输入标签名称')} showCount maxLength={15} />
      </Form.Item>
      <Form.Item
        label={t('标签排序')}
        name="weight"
        rules={[{ required: true, message: t('请输入标签排序') }]}
      >
        <Input
          style={{ width: '100%' }}
          placeholder={t('最多输入5位数字，数字越大，排序越靠前')}
          onChange={changeValue}
          maxLength={5}
        />
      </Form.Item>
    </Form>
  );
};

export default AddFollowUpModal;
