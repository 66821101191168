/*
 * @Description: 协议
 * @Author: likaifeng
 * @Date: 2023-12-03 20:46:36
 * @LastEditTime: 2023-12-03 20:54:27
 * @LastEditors: likaifeng
 */
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { agreement, agreementEn, privacyPolicy, privacyPolicyEn, userServiceProtocol, userServiceProtocolEn } from './config';
import styles from './index.less';
import { useLocales } from '@/Locales';

/**
 * @description: 协议
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-03 20:54:52
 */
const userProtocol: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const id = params.getAll('id')[0];
  const { locales } = useLocales();
  return (
    <div className={styles['user-protocol']}>
      {id === '1' && (
        <div
          dangerouslySetInnerHTML={{
            __html: locales === 'en' ? userServiceProtocolEn : userServiceProtocol,
          }}
        />
      )}
      {id === '2' && (
        <div
          dangerouslySetInnerHTML={{
            __html: locales === 'en' ? privacyPolicyEn : privacyPolicy,
          }}
        />
      )}

      {id === '3' && (
        <div
          dangerouslySetInnerHTML={{
            __html: locales === 'en' ? agreementEn : agreement,
          }}
        />
      )}
    </div>
  );
};

export default userProtocol;
