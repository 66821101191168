import { request } from '@/common/request';

// 医生列表
export const getDoctorList = (params: any) => {
  return request.post<any, any>('/doctor/listPage', params);
};
// 医生详情
export const getDoctorInfo = (id: string) => {
  return request.post<any, any>('/doctor/info', { id });
};
// 医生详情
export const checkUserName = (params: any) => {
  return request.post<any, any>('/doctor/getUserName', params);
};
// 创建医生
export const addDoctor = (params: any) => {
  return request.post<any, any>('/doctor/add', params);
};
// 编辑医生
export const updateDoctor = (params: any) => {
  return request.post<any, any>('/doctor/update', params);
};
// 禁用/启用医生
export const updateDoctorStatus = (params: any) => {
  return request.post<any, any>('/doctor/updateUserStatus', params);
};
// 解绑医生微信
export const unbind = (openId: string) => {
  return request.post<any, any>('/doctor/unbind', { openId });
};
// 获取医生账号登录信息
export const getDoctorAccount = (params: any) => {
  const url = '/agency/getUser';
  return request.post<any, any>(url, params);
};
// 更新医生账号登录信息
export const updateDoctorAccount = (params: any) => {
  const url = '/agency/updateUser';
  return request.post<any, any>(url, params);
};
// 医生概览
export const getSubscribeNumber = (params: any) => {
  const url = '/doctor/subscribeNumber';
  return request.post<any, any>(url, params);
};

export default {};
