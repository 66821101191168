import React from 'react';
import { Table } from '@sinohealth/butterfly-ui-components';
import Label from '@/components/Label';
import useDict from '@/hooks/dict/useDict';
import { getUuid } from '@/utils';
import { useLocales } from '@/Locales';

const InspectionItemsTable = (props: any) => {
  const { data = [] } = props;
  const tumorMarker = useDict('tumorMarker');
  const { t } = useLocales();
  const columns: any = [
    {
      title: '检验项目',
      dataIndex: 'type',
      key: 'type',
      render(text: string) {
        return text === '0' ? t('肿瘤标志物') : t('其他');
      },
    },
    {
      title: '指标名称',
      dataIndex: 'tumorMarkers',
      key: 'tumorMarkers',
      render(text: string, record: any): string {
        return record.type === '0' ? tumorMarker?.[Number(text)] : text;
      },
    },
    {
      title: '单位',
      dataIndex: 'unit',
      key: 'unit',
    },
    {
      title: '结果',
      dataIndex: 'resultValueItem',
      key: 'resultValueItem',
    },
    {
      title: '参考区间',
      dataIndex: 'referenceItem',
      key: 'referenceItem',
    },
  ].map((item) => {
    return {
      ...item,
      title: t(item.title),
    };
  });
  return (
    <div>
      <div style={{ marginBottom: 12 }}>
        <Label>检验项目</Label>
        {
          data.length === 0 && <span style={{ marginLeft: 12 }}>--</span>
        }
      </div>
      {
        data.length > 0 && (
          <Table columns={columns} rowKey={() => getUuid()} dataSource={data} pagination={false} />
        )
      }
    </div>
  );
};

export default InspectionItemsTable;
