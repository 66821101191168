/*
 * @Description: 统一的空状态
 * @Author: likaifeng
 * @Date: 2024-01-03 13:51:57
 * @LastEditTime: 2024-01-03 16:48:43
 * @LastEditors: likaifeng
 */
import { Empty } from '@sinohealth/butterfly-ui-components';
import React from 'react';
import { useLocales } from '@/Locales';

interface EmptyCanvasProps {
  height?: string | number;
  width?: string | number;
  permission?: boolean;
}

/**
 * @description: 统一的空状态
 * @author: likaifeng
 * @param {*} props
 * @return {*}
 * @Date: 2024-01-03 13:54:13
 */
const EmptyCanvas: React.FC<EmptyCanvasProps> = (props) => {
  const { t } = useLocales();
  return (
    <Empty
      description={!props?.permission ? t('暂无权限查看') : t('暂无数据')}
      style={{
        height: props?.height,
        width: props?.width,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#0b214560',
      }}
    />
  );
};

export default EmptyCanvas;
