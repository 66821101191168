/*
 * @Description: 讲稿演示-头部
 * @Author: likaifeng
 * @Date: 2023-11-30 15:47:43
 * @LastEditTime: 2024-08-16 16:01:51
 * @LastEditors: likaifeng
 */
import { useLocales } from '@/Locales';
import { useDoctorDetail } from '@/pages/speechLecture/hook/make';
import { usePatientSpeechSubmit } from '@/pages/speechLecture/hook/makeSpeech';
import {
  speechFilterEditAtom,
  speechFontSizesAtom,
  speechOriginDataAtom,
  speechSaveLoadingAtom,
  speechUpdateTimeAtom,
} from '@/store/speech';
import { getLocalStorage, removeLocalStorage } from '@/utils/cookies';
import { Button, message, Select, Space } from '@sinohealth/butterfly-ui-components';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Screenfull from '../Screenfull';
import styles from './index.less';

/**
 * @description: 讲稿演示-头部
 * @author: likaifeng
 * @param {*} props
 * @return {*}
 * @Date: 2023-12-04 14:26:45
 */
const SpeechHeader: React.FC<any> = (props) => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];

  const { title } = props;

  const [loading, setLoading] = useState(false);
  const speechOriginData = useRecoilValue(speechOriginDataAtom);
  const speechUpdateTime = useRecoilValue(speechUpdateTimeAtom);
  const [speechFontSize, setSpeechFontSize] = useRecoilState(speechFontSizesAtom);
  const setSpeechSaveLoading = useSetRecoilState(speechSaveLoadingAtom);
  const [speechFilterEditFlag, setSpeechFilterEditFlag] = useRecoilState(speechFilterEditAtom);

  const { doctorDetail } = useDoctorDetail(speechOriginData?.baseInfo?.doctorId);
  const { setCanSubmit } = usePatientSpeechSubmit();

  const { t } = useLocales();

  useEffect(() => {
    window.document.documentElement.setAttribute('data-size', speechFontSize);
  }, [speechFontSize]);

  /**
   * @description: 保存讲稿
   * @author: KaifengLi
   * @version: 2.20.0
   * @Date: 2024-08-06 10:45:02
   */
  const handleSaveSpeechFilter = _.throttle(() => {
    const key = `SPEECH_MAKING_EDIT_ERROR_${patientId}`;
    const localError = getLocalStorage(key);
    // console.log(localError);
    if (localError) {
      const err = Object.keys(localError).filter((el) => localError[el]);
      if (err.length) {
        message.error(t('请先完善信息'));
        return;
      }
    }
    setLoading(true);
    setSpeechSaveLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
      setSpeechFilterEditFlag(false);
      setSpeechSaveLoading(false);
      removeLocalStorage(key);
      setCanSubmit(true);
      message.success(t('保存成功'));
      clearTimeout(timer);
    }, 1000);
  }, 500);

  /**
   * @description: 切换字体大小
   * @author: likaifeng
   * @param {any} e
   * @return {*}
   * @Date: 2023-11-30 20:20:36
   */
  const handleSelectFontSize = (e: any) => {
    // window.document.documentElement.setAttribute('data-size', e);
    setSpeechFontSize(e);
  };

  return (
    <header className={styles['speech-header']}>
      {/* <h1 className={styles['speech-logo']}>中惠医疗科技有限公司</h1> */}
      <div className={styles['header-container']}>
        <div className={styles['header-title']}>
          <h2 className={styles['speech-title']}>{title}</h2>
          <div className={styles['speech-doctor']}>
            {t('医生')}：{doctorDetail?.name}（{doctorDetail?.hospitalName}{' '}
            {doctorDetail?.departmentName} {doctorDetail?.jobTitle}）
          </div>
        </div>
        <div className={`${styles['header-tools']} ${styles['tools-width']}`}>
          {/* <speech-stop-watch
        :is-show-stopwatch="isShowStopwatch"
        @getDataToParentStopWatch="handleSetDataToParentStopWatch"
      /> */}
          <Space size={12}>
            {speechFilterEditFlag ? (
              <Button type="primary" loading={loading} onClick={handleSaveSpeechFilter}>
                {t('保存')}
              </Button>
            ) : (
              <Button type="primary" onClick={() => setSpeechFilterEditFlag(true)}>
                {t('编辑')}
              </Button>
            )}
            {/* <SpeechStopWatch /> */}
            <div className={styles['font-size']}>
              <span className={styles['font-label']}>{t('字体大小')}</span>
              <Select
                className={styles['font-select']}
                value={speechFontSize}
                onChange={handleSelectFontSize}
                options={[
                  { value: 1, label: t('小号') },
                  { value: 2, label: t('中号') },
                  { value: 3, label: t('大号') },
                ]}
              />
            </div>
            <Screenfull />
          </Space>
        </div>
      </div>
    </header>
  );
};

export default SpeechHeader;
