/* eslint-disable indent */
import { Form, FormItem } from '@/components/BaseForm';
import Label from '@/components/Label';
import Text from '@/components/Text';
import { PatientInfoAtom, PatientInfoStatus } from '@/pages/patient/detail';
import InspectionItems from '@/pages/patient/detail/components/InspectionReportsTab/components/InspectionItems';
import InspectionItemsTable from '@/pages/patient/detail/components/InspectionReportsTab/components/InspectionItemsTable';
import RecordImgUpload from '@/pages/patient/detail/components/RecordImgUpload';
import TabHeader, { TabHeaderInput } from '@/pages/patient/detail/components/TabHeader';
import { addPatientInspection, updatePatientInspection } from '@/services/patient';
import { Form as AntdForm, Input } from '@sinohealth/butterfly-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

const InspectionReportsTab = (props: any) => {
  const { data } = props;
  const [form] = AntdForm.useForm();
  const [readOnly, setReadOnly] = useState(false);
  const patientInfo = useRecoilValue(PatientInfoAtom);
  const [patientInfoStatus, setPatientInfoStatus] = useRecoilState(PatientInfoStatus);

  useEffect(() => {
    setReadOnly(!!data?.id);
  }, []);

  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    return () => {
      // 组件销毁时，如果是编辑状态，把全局的可编辑状态还原
      if (isEditing) {
        setPatientInfoStatus({
          edit: true,
        });
      }
    };
  }, [isEditing]);

  const handleChangeReadOnly = () => {
    const newReadOnly = !readOnly;
    setReadOnly(newReadOnly);
    setPatientInfoStatus({
      edit: newReadOnly,
    });
    setIsEditing(!newReadOnly);
    if (newReadOnly) {
      patientInfo.update();
    }
    form.resetFields();
  };

  const handleSubmit = () => {
    form.validateFields().then((formValue) => {
      // 获取新数据的 ID 列表
      const newIds = formValue?.zhInspectionItems
        ? formValue?.zhInspectionItems?.filter((el: any) => el.id).map((el: any) => el.id)
        : [];

      // 标记已删除的数据
      const updatedItems = data?.zhInspectionItems
        ? data?.zhInspectionItems?.map((el: any) => {
            if (!newIds.includes(el.id)) {
              // 如果 ID 不在新数据中，则标记为已删除
              return { ...el, delete: true };
            }
            return el;
          })
        : [];

      const updateItem = updatedItems?.filter((el: any) => el.delete);

      const params = {
        ...formValue,
        zhInspectionItems: formValue?.zhInspectionItems
          ? [...updateItem, ...formValue.zhInspectionItems]
          : [...updateItem],
        patientId: patientInfo.patient.id,
      };
      const api = formValue?.id ? updatePatientInspection : addPatientInspection;
      api(params).then(() => {
        patientInfo.update();
        handleChangeReadOnly();
      });
    });
  };
  const action = readOnly ? (
    <a onClick={handleChangeReadOnly}>
      <Text>编辑</Text>
    </a>
  ) : (
    <>
      <a onClick={handleChangeReadOnly}>
        <Text>取消</Text>
      </a>
      <a onClick={handleSubmit}>
        <Text>保存</Text>
      </a>
    </>
  );
  const mapImage = (urls: string) => {
    return <RecordImgUpload value={urls} disabled />;
  };
  return (
    <Form
      requiredMark={false}
      className="record-form"
      form={form}
      readOnly={readOnly}
      initialValues={data}
      layout={readOnly ? 'horizontal' : 'vertical'}
    >
      <FormItem name="id" hidden>
        <Input />
      </FormItem>
      <FormItem
        noStyle
        transformText={(text) => (
          <TabHeader title={moment(text).format('YYYY-MM-DD')} action={action} />
        )}
        name="recordTime"
        span={24}
      >
        <TabHeaderInput action={action} />
      </FormItem>
      <FormItem name="imagePath" transformText={mapImage}>
        <RecordImgUpload />
      </FormItem>
      <FormItem name="zhInspectionItems" transformText={(v) => <InspectionItemsTable data={v} />}>
        <InspectionItems />
      </FormItem>
      <FormItem label={<Label>描述</Label>} name="description">
        <Input.TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
      </FormItem>
    </Form>
  );
};

export default InspectionReportsTab;
