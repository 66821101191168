import Label from '@/components/Label';
import { useLocales } from '@/Locales';
import InspectionItem from '@/pages/patient/detail/components/InspectionReportsTab/components/InspectionItem';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Space } from '@sinohealth/butterfly-ui-components';
import styles from './index.less';

const InspectionItems = (props: any) => {
  const { value, onChange, onAdd, showLabel = true } = props;
  const { t } = useLocales();
  return (
    <>
      {showLabel && (
        <div style={{ marginBottom: 12 }}>
          <Label>{t('检验项目')}</Label>
        </div>
      )}
      <Form.List name="zhInspectionItems">
        {(fields, { add, remove }) => (
          <Space size={12} wrap>
            {fields.map((params) => (
              <InspectionItem
                {...params}
                remove={(v: any) => {
                  remove(v);
                  onAdd && onAdd();
                }}
              />
            ))}
            <Button
              className={styles.addBtn}
              type="dashed"
              onClick={() => {
                add();
                onAdd && onAdd();
              }}
              icon={<PlusOutlined />}
            >
              {t('新增记录')}
            </Button>
          </Space>
        )}
      </Form.List>
    </>
  );
};

export default InspectionItems;
