/*
 * @Description: 讲稿演示-左侧大纲
 * @Author: likaifeng
 * @Date: 2023-11-30 16:02:22
 * @LastEditTime: 2024-01-08 13:51:48
 * @LastEditors: likaifeng
 */
import {
  speechMainDetailAtom,
  speechMainDetailTabAtom,
  speechSideMenuAtom,
  speechSideTabAtom,
} from '@/store/lecture';
import { Popover } from '@sinohealth/butterfly-ui-components';
import classNames from 'classnames';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styles from './index.less';
import { useLocales } from '@/Locales';

const SpeechSide: React.FC = () => {
  const speechSideMenu = useRecoilValue(speechSideMenuAtom);
  const [speechSideTab, setSpeechSideTab] = useRecoilState(speechSideTabAtom);
  const setSpeechMainDetailTab = useSetRecoilState(speechMainDetailTabAtom);
  const setSpeechMainDetail = useSetRecoilState(speechMainDetailAtom);
  const { t } = useLocales();

  /**
   * @description: 切换左侧讲稿大纲
   * @author: likaifeng
   * @param {string} key
   * @return {*}
   * @Date: 2023-11-30 20:24:45
   */
  const handleChangeSideMenu = (key: string) => {
    setSpeechSideTab(key);
    setSpeechMainDetailTab(null);
    setSpeechMainDetail(null);
    document.querySelector(`#${key}`)?.scrollIntoView();
  };

  return (
    <div className={styles['speech-side']}>
      <div className={styles['side-header']}>
        <div className={styles.side}>
          <i className="iconfont icon-fuzhiwenjian" style={{ marginRight: 5 }} />
          {t('讲稿大纲')}
        </div>
      </div>
      <ul className={styles['side-container']}>
        {speechSideMenu?.map((menu: any, index: number) => (
          <li key={menu.label} className={styles['side-step']}>
            <div
              id={`menu_${menu.label}`}
              className={classNames(
                styles['step-title'],
                speechSideTab.indexOf(menu.label) !== -1 && styles['step-title-active'],
              )}
              onClick={() => handleChangeSideMenu(menu.label)}
            >
              {index + 1}. {t(menu.name)}
              {menu.children ? (
                <span className={styles['title-count']}>{menu.children.length}</span>
              ) : null}
            </div>
            {menu.children ? (
              <div className={styles.container}>
                {menu.children.map((child: any) => (
                  <React.Fragment key={child.label}>
                    <div
                      key={child.label}
                      className={classNames(
                        styles['step-item'],
                        speechSideTab === child.label && styles['step-item-active'],
                      )}
                      onClick={() => handleChangeSideMenu(child.label)}
                    >
                      {child.remarks ? (
                        <Popover
                          content={
                            <div style={{ maxWidth: 200, color: '#FFF' }}>
                              {menu.name === '影像报告' ? t('备注') : t('资料类型')}：
                              {child.remarks}
                            </div>
                          }
                          placement="rightTop"
                          color="#00244ce6"
                          title={
                            <div style={{ color: '#FFF', fontWeight: 'normal' }}>
                              {`${t(menu.name)}: ${child.name}`}
                            </div>
                          }
                        >
                          <div className={classNames(styles.label)}>
                            <span
                              className={classNames(
                                styles.step,
                                speechSideTab === child.label && styles['step-active'],
                              )}
                            >
                              {child.name}
                            </span>
                            {child.type ? (
                              <span className={styles['step-ct']}>{child.type}</span>
                            ) : null}
                          </div>
                          {child.remarks ? (
                            <div
                              className={classNames(
                                styles['label-remarks'],
                                speechSideTab === child.label && styles['label-remarks-active'],
                              )}
                            >
                              {child.remarks}
                            </div>
                          ) : null}
                        </Popover>
                      ) : (
                        <div className={classNames(styles.label)}>
                          <span
                            className={classNames(
                              styles.step,
                              speechSideTab === child.label && styles['step-active'],
                            )}
                          >
                            {child.name}
                          </span>
                          {child.type ? (
                            <span className={styles['step-ct']}>{child.type}</span>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SpeechSide;
