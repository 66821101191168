import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Spin } from '@sinohealth/butterfly-ui-components/lib';
// import { usePermission } from '@/common/hooks';
import routerConfig from '@/config/router';
import useCurrentLoginUserInfo from '@/hooks/useCurrentLoginUserInfo';

type routerConfigProps = {
  path: string;
  element: any;
  children?: routerConfigProps[];
};

const renderRoute = (routerConfigData: any[]) =>
  routerConfigData.map(({ children, ...routeProps }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Route {...routeProps} key={routeProps.path}>
      {children && renderRoute(children)}
    </Route>
  ));

export const RenderRoutes = () => {
  return <Routes>{renderRoute(routerConfig)}</Routes>;
};

export default RenderRoutes;
