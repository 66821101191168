/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/*
 * @Description: 讲稿演示-主要内容-内容展示组件
 * @Author: likaifeng
 * @Date: 2023-11-30 16:36:37
 * @LastEditTime: 2024-02-02 11:05:53
 * @LastEditors: likaifeng
 */
import ReactViewer from '@/components/ReactViewer';
import { download } from '@/services';
import className from 'classnames';
import React, { useEffect, useState } from 'react';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';
import SpeechCompareModel from '../SpeechCompareModel';
import SpeechFile from '../SpeechFile';
import styles from './index.less';
import { useLocales } from '@/Locales';

/**
 * @description: 讲稿演示-主要内容-内容展示组件
 * @author: likaifeng
 * @param {*} props
 * @return {*}
 * @Date: 2023-12-04 14:26:03
 */
const SpeechField: React.FC<any> = (props) => {
  const {
    style,
    border,
    color, // 是否深色
    title,
    compareTitle,
    description,
    imagesSource, // 图片数据 对象数组 [{ type: 'image', url: '' },{ type: 'video', url: '' }]
    imageStyle,
    zhWedcmTask,
    dicomId,
    type = 'TEXT', // TEXT IMAGE BUTTON OTHER COMPARE
  } = props;

  const [compare, setCompare] = useState<any[]>([]);
  const [downloadImage, setDownloadImage] = useState<any[]>([]);
  const [viewImages, setViewImages] = useState<ImageDecorator[]>([]);
  const [viewImagesIndex, setViewImagesIndex] = useState(0);
  const { t } = useLocales();

  /**
   * @description: 查看 dicom 影像
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-10-31 14:19:37
   */
  const handleViewDicom = () => {
    if (dicomId !== -1) {
      // const query = `id=${dicomId}`;
      // window.open(`/#//patientManagement/dicom?${query}`);
      window.open(zhWedcmTask.openPath);
    }
  };

  const handleDownloadImage = (img: any[]) => {
    Promise.all(
      img.map((el) => {
        return download(el.key).then((url) => {
          return {
            ...el,
            url,
          };
        });
      }),
    ).then((res) => {
      // console.log(res);
      setDownloadImage(res);
    });
  };

  /**
   * @description: 父容器的类
   * @author: likaifeng
   * @return {*}
   * @Date: 2024-01-09 14:32:04
   */
  const faClass = () => {
    return className(
      styles['field-item'],
      border && styles['field-border'],
      type === 'TEXT' && styles['field-text'],
      type === 'BUTTON' && styles['field-button'],
      type === 'IMAGE' && styles['field-image'],
      type === 'COMPARE' && styles['field-image'],
      type === 'OTHER' && styles['field-image'],
    );
  };

  useEffect(() => {
    if (imagesSource && imagesSource?.length) {
      handleDownloadImage(imagesSource);
    } else {
      setDownloadImage([]);
    }
  }, [imagesSource]);

  return description || (downloadImage && downloadImage.length) || props.children ? (
    <div className={faClass()} style={style}>
      {/* 标题 */}
      {title ? (
        <div className={styles['label-wrapper']}>
          <div className={className(styles['field-label'], color && styles['deep-label'])}>
            {t(title)}
          </div>
        </div>
      ) : null}

      {/* 文本类型内容 */}
      {type === 'TEXT' ? <div className={styles['field-desc']}>{description}</div> : null}
      {/* 按钮类型内容 description 按钮描述 */}
      {type === 'BUTTON' ? (
        <div className={styles['desc-button']} onClick={handleViewDicom}>
          <i className="el-icon-search" />
          {description}
        </div>
      ) : null}

      {/* 图片类型内容 */}
      {type === 'IMAGE' ? (
        <div className={styles['images-container']}>
          {downloadImage &&
            downloadImage?.map((el: any, index: number) => (
              <SpeechFile
                key={`${el.key}_${index}`}
                className={className(styles.images, el.type === 'video' && styles['video-label'])}
                type={el.type}
                url={el.url}
                request={false}
                preview={false}
                onClick={() => {
                  const fil = downloadImage.filter((lis) => lis.type !== 'video');
                  setViewImages(
                    fil.map((item: { url: string }) => {
                      return {
                        src: item.url,
                      };
                    }),
                  );
                  const cur = fil.findIndex((item) => el.url === item.url);
                  // console.log({ cur });
                  setViewImagesIndex(cur);
                }}
              />
            ))}
          {downloadImage && downloadImage?.length ? null : (
            <div className={styles['no-images']}>{t('暂无图片数据')}</div>
          )}
        </div>
      ) : null}

      {/* 对比图 - 图片类型内容 */}
      {type === 'COMPARE' ? (
        <div className={styles['images-container']}>
          {downloadImage &&
            downloadImage?.map((el: any, index: number) => (
              <SpeechFile
                key={`${el.key}_${index}`}
                preview={false}
                request={false}
                className={className(styles.images, el.type === 'video' && styles['video-label'])}
                type={el.type}
                url={el.url}
                onClick={() => setCompare(downloadImage)}
              />
            ))}

          {downloadImage && downloadImage?.length ? null : (
            <div className={styles['no-images']}>{t('暂无图片数据')}</div>
          )}
        </div>
      ) : null}
      {/* 其他类型 */}
      {type === 'OTHER' ? props.children : null}

      {/* 对比图查看 */}
      <SpeechCompareModel images={compare} name={compareTitle} onClose={() => setCompare([])} />
      {/* 自定义图片-大屏预览 */}
      <ReactViewer
        images={viewImages}
        activeIndex={viewImagesIndex}
        onClose={() => {
          setViewImages([]);
          setViewImagesIndex(0);
        }}
      />
    </div>
  ) : null;
};

export default SpeechField;
