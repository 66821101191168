export const codeConfig: any = {
  200: { en: 'success', 'zh-cn': '操作成功' },
  400: { en: 'Request failed, please try again later', 'zh-cn': '请求失败，请稍候重试' },
  401: { en: 'Access is denied due to invalid credentials.', 'zh-cn': '凭据无效，访问被拒绝' },
  403: { en: 'The requested operation is forbidden and cannot be completed.', 'zh-cn': '请求被禁止，无法完成' },
  404: {
    en: 'The requested operation failed because a resource associated with the request could not be found.',
    'zh-cn': '请求失败，因为找不到与请求关联的资源',
  },
  500: { en: 'The request failed due to an internal error.', 'zh-cn': '服务器内部错误' },
  20001: { en: 'username or password input error', 'zh-cn': '用户名或密码不正确' },
  20004: { en: 'Error verification code', 'zh-cn': '验证码校验错误，请您重新输入' },
  20005: { en: 'Sending verification code failed, please try again later', 'zh-cn': '发送验证码失败，请您稍后再试' },
  100005: { en: 'Account lock', 'zh-cn': '账户被锁定，请联系管理员!' },
  100006: { en: 'Credentials expired', 'zh-cn': '证书过期，请联系管理员!' },
  100007: { en: 'Account expired', 'zh-cn': '账户过期，请联系管理员!' },
  100008: { en: 'Account disabled', 'zh-cn': '账户被禁用，请联系管理员!' },
  400001: { en: 'Resource already exists.', 'zh-cn': '资源已存在' },
  400002: { en: 'Resource had used', 'zh-cn': '资源已被使用中，不能随意操作' },
  400003: { en: 'Resource had disabled', 'zh-cn': '资源已被禁用' },
  400004: { en: 'Validation of input failed.', 'zh-cn': '验证错误，请检查参数' },
  400005: {
    en: 'The request failed because it contained an invalid parameter or parameter value for the document. Review the API documentation to determine which parameters are valid for your request.',
    'zh-cn': '无效的文档值',
  },
  400007: { en: 'Invalid config', 'zh-cn': '无效的配置' },
  400008: {
    en: "The current operation is not allowed by the system's procedures. If there is an issue, please contact the administrator.",
    'zh-cn': '非法的操作',
  },
  400009: { en: 'Data error', 'zh-cn': '数据异常' },
};
export default {
  codeConfig,
};
