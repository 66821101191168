import { useState, useEffect } from 'react';
import useDictOptions from '@/hooks/dict/useDictOptions';

/*
* 获取字典数据
* @params: dictName 字典类型key
* @return: { key: label, key2: label2 }
* */
const useDict = (dictName: string = '') => {
  const dictOptions = useDictOptions();
  const [dictData, setDictData] = useState<any>({});

  useEffect(() => {
    if (!dictOptions) return;
    const dictKeys = Object.keys(dictOptions);
    const dictKeyValueData: any = {};
    dictKeys.forEach((key) => {
      if (Array.isArray(dictOptions[key])) {
        const itemData: any = {};
        dictOptions[key].forEach((dictItem: any) => {
          itemData[dictItem.value] = dictItem.label;
        });
        dictKeyValueData[key] = itemData;
      }
    });
    setDictData(dictKeyValueData);
  }, [dictOptions]);

  return dictData[dictName] || dictData;
};

export default useDict;
