/*
 * @Description: 讲稿预览
 * @Author: likaifeng
 * @Date: 2023-11-30 14:58:30
 * @LastEditTime: 2024-05-28 16:28:34
 * @LastEditors: likaifeng
 */
import { speechFilterMakeDataAtom, speechSideMenuAtom, speechSideTabAtom } from '@/store/lecture';
import { Spin } from '@sinohealth/butterfly-ui-components';
import React, { useLayoutEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { usePatientInfo } from '../hook/make';
import SpeechHeader from './components/SpeechHeader';
import SpeechIssue from './components/SpeechIssue';
import SpeechMain from './components/SpeechMain';
import SpeechSide from './components/SpeechSide';
import SpeechSubfield from './components/SpeechSubfield';
import styles from './index.less';

const Preview: React.FC = () => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];
  const name = params.getAll('patientName')[0];
  // 会诊ID, 旧跳转入口有使用id, 有使用consultationId,所在这里做个兼容
  const consultationId = params.getAll('id')[0] || params.getAll('consultationId')[0];
  const setSpeechSideTab = useSetRecoilState(speechSideTabAtom);
  const speechSideMenu = useRecoilValue(speechSideMenuAtom);
  const speechFilterMakeData = useRecoilValue(speechFilterMakeDataAtom);

  const { loading, speechTitle } = usePatientInfo(patientId, consultationId, name);

  useLayoutEffect(() => {
    speechFilterMakeData && handleCalcDomHeight();
  }, [speechFilterMakeData]);

  /**
   * @description: 计算内容区的高度集合
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-12-05 16:48:41
   */
  const handleCalcDomHeight = () => {
    const timer = setTimeout(() => {
      const heightSum: any = {};
      const labelItem: any[] = [];
      const heightArr: any[] = [];
      let sum = 0;
      speechSideMenu.forEach((el: any) => {
        const labelDom = document.getElementById(el.label);
        const fatherHei = labelDom ? labelDom.offsetHeight : 0;
        // console.log(fatherHei)
        sum += fatherHei;
        heightSum[el.label] = sum;
        heightArr.push(sum);
        labelItem.push(el.label);
        if (el.children) {
          el.children.forEach((item: any) => {
            const sonDom = document.getElementById(item.label);
            // console.log(sonDom)
            const sonHei = sonDom ? sonDom.offsetHeight : 0;
            // console.log(sonHei)
            sum += sonHei;
            heightSum[item.label] = sum;
            heightArr.push(sum);
            labelItem.push(item.label);
          });
        }
      });
      // console.log(heightSum)
      // console.log(heightArr)
      handleBindScrollEvent(heightArr, labelItem);
      clearTimeout(timer);
    }, 1000);
  };

  /**
   * @description: 内容区滚动事件
   * @author: likaifeng
   * @param {*} arr 高度集合
   * @param {*} label 标签集合
   * @return {*}
   * @Date: 2023-12-05 16:43:19
   */
  const handleBindScrollEvent = (arr: any[], label: any) => {
    const Dom = document.getElementById('speechMain');

    if (!Dom) return;
    Dom.onscroll = () => {
      const scrollTop = Dom.scrollTop;

      const scrollWhichIndex = arr.findIndex((item, index) => {
        return scrollTop >= arr[index] && scrollTop < arr[index + 1];
      });
      // console.log(scrollTop)
      // console.log('所在区间', scrollWhichIndex)
      let index = 0;
      if (scrollWhichIndex > -1) {
        index = scrollWhichIndex + 1;
      } else {
        index = 0;
      }
      // console.log(label[index])
      setSpeechSideTab(label[index]);

      const menuDom = document.querySelector(`#menu_${label[index]}`);

      if (!menuDom) return;

      menuDom.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      });
    };
  };

  return (
    <Spin spinning={loading}>
      <section className={styles['speech-draft']}>
        <SpeechHeader title={speechTitle} />
        <main className={styles['speech-container']}>
          <SpeechSide />
          <SpeechMain />
          <SpeechSubfield />
          <SpeechIssue consultationId={consultationId} />
        </main>
      </section>
    </Spin>
  );
};

export default Preview;
