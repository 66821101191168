/*
 * @Description: 近12月MDT开展场次趋势
 * @Author: likaifeng
 * @Date: 2023-12-26 16:19:34
 * @LastEditTime: 2024-01-04 14:38:59
 * @LastEditors: likaifeng
 */
import usePermission from '@/hooks/usePermission';
import React from 'react';
import { useMdtTrend } from '../../hook';
import { defaultLineOption } from '../../hook/config';
import { ImdtChartOption } from '../../hook/echartType';
import ImdtChart from '../ImdtChart';
import styles from './index.less';
import { useLocales } from '@/Locales';

/**
 * @description: 近12月MDT开展场次趋势
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-26 16:20:09
 */
const MdtTrendRecentDecember: React.FC = () => {
  const { mdtTrendSource, loadingMdtTrend } = useMdtTrend();
  const permission = usePermission('mdtStatistics');
  const { t } = useLocales();

  const options: ImdtChartOption = {
    ...defaultLineOption({
      tooltip: {
        backgroundColor: '#00244c99',
        borderWidth: 0,
        textStyle: {
          color: '#FFFFFF',
        },
      },
      grid: [
        {
          left: 40,
          bottom: 40,
          right: 20,
        },
      ],
    }),
    xAxis: {
      type: 'category',
      data: mdtTrendSource?.map((el: any) => el?.time),
      axisLine: {
        show: true,
      },
      axisTick: {
        alignWithLabel: true,
      },
    },
    // 数据
    series: [
      {
        data: mdtTrendSource?.map((el: any) => el?.num),
        type: 'line',
        lineStyle: {
          width: 2,
          color: '#76DDFB',
        },
        symbolSize: 5,
        itemStyle: {
          color: '#53A8E2',
          borderWidth: 1,
        },
        emphasis: {
          scale: 2.2,
        },
        selectedMode: 'series',
      },
    ],
  };

  return (
    <ImdtChart
      loading={loadingMdtTrend}
      className={styles['line-chart']}
      title={t('近12月MDT开展场次趋势')}
      height={261}
      option={options}
      empty={!mdtTrendSource.length || !permission?.statisticalDdtTrend}
      permission={permission?.statisticalDdtTrend}
    />
  );
};

export default MdtTrendRecentDecember;
