import React, { useState, useEffect } from 'react';
import { Space, Upload, message } from '@sinohealth/butterfly-ui-components';
import { CloseCircleFilled, CheckCircleFilled, SyncOutlined, EyeOutlined } from '@ant-design/icons';
// @ts-ignore
import OSS from 'ali-oss';
import styles from './index.less';
import { getImageInfo, getStoreId, getStsToken, sendUploaded } from '@/services/patient';
import classnames from 'classnames';
import { useLocales } from '@/Locales';

const DicomUpload = (props: any) => {
  const { value, onChange, disabled, id } = props;
  const [status, setStatus] = useState(0); // 上传状态 0: 未操作，1: 上传中， 2: 上传成功，3：上传失败
  const [progress, setProgress] = useState(0); // 上传进度
  const [cacheCheckpoint, setCacheCheckpoint] = useState<any>(); // 上传过程中的文件
  const [errorFile, setErrorFile] = useState<any>(); // 上传失败的文件配置
  const { t } = useLocales();

  useEffect(() => {
    // getStsToken()
    //   .then((res) => {
    //     console.log('res');
    //     console.log(res);
    //   });
    // const client = new OSS({
    //   region: stsConfig.region,
    //   accessKeyId: stsConfig.accessKey,
    //   accessKeySecret: stsConfig.accessSecret,
    //   bucket: stsConfig.bucket,
    //   stsToken: stsConfig.token,
    // });
  }, []);

  const handleUpload = async (options: any) => {
    const { file, checkpoint } = options;
    const stsConfig: any = await getStsToken();
    if (!stsConfig.token) {
      message.error(t('上传失败，影像账号异常'));
      return;
    }
    const uid: any = options.uid || await getStoreId();
    const client = new OSS({
      region: stsConfig.region,
      accessKeyId: stsConfig.accessKey,
      accessKeySecret: stsConfig.accessSecret,
      bucket: stsConfig.bucket,
      stsToken: stsConfig.token,
    });
    // 文件key值
    const fileKey = file.lastModified + file.name;
    // 对象key值
    const uploadPath = `/${stsConfig.dir}${uid}/${fileKey}`;
    setStatus(1);
    onChange('');
    try {
      // 上传文件，断点续传上传
      const result = await client.multipartUpload(uploadPath, file, {
        // 上传进度
        progress(progressNumber: any, _checkpoint: any) {
          setProgress(progressNumber);
          if (progressNumber === 1) {
            setCacheCheckpoint(null);
          } else {
            setCacheCheckpoint(_checkpoint);
          }
        },
        // parallel:1,
        // 断点记录点。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
        checkpoint,
      });
      if (result?.res?.status === 200) {
        onUploadSuccess(result, uid);
      } else {
        onUploadError(fileKey, uid);
      }
    } catch (e) {
      console.log(e);
      onUploadError(fileKey, uid);
    }
  };

  const onUploadSuccess = (result: any, uid: string) => {
    sendUploaded({
      storeUid: uid,
    }).then((data) => {
      onChange(data);
      setStatus(2);
    });
  };

  const onUploadError = (fileKey: any, uid: string) => {
    message.error(t('上传失败'));
    setErrorFile({
      fileKey,
      uid,
    });
    setStatus(3);
  };

  const handleDelete = () => {
    onChange('');
    setStatus(0);
  };

  const handleReload = () => {
    if (cacheCheckpoint) {
      const options = {
        file: cacheCheckpoint.file,
        checkpoint: cacheCheckpoint,
        uid: errorFile.uid,
      };
      handleUpload(options);
    }
  };

  const handlePreview = () => {
    getImageInfo({
      id,
    }).then((res: any) => {
      if (res) {
        window.open(res.zhWedcmTask.openPath);
      } else {
        message.error(t('暂无影像资料'));
      }
    });
  };

  if (disabled && !value) {
    return <span>{t('暂无影像资料')}</span>;
  }
  const classNames = classnames({
    [styles.dicomUpload]: true,
    [styles.readOnly]: disabled,
  });
  return (
    <>
      <div className={classNames}>
        {
          status !== 1 ? (
            <Space split="|" size={16}>
              { disabled && value && (<div className={styles.actionItem} onClick={handlePreview}><EyeOutlined /> {t('查看影像')}</div>)}
              { !disabled && (
                <div className={styles.actionItem}>
                  <Upload accept=".dcm,.zip,.mp4" customRequest={handleUpload} showUploadList={false}>
                    <a>{!value ? t('上传影像资料') : t('重新上传影像资料')}</a>
                  </Upload>
                </div>
              )}
              { !disabled && !!value && (<div className={styles.actionItem} onClick={handleDelete}>{t('删除')}</div>)}
              {
                status === 3 && cacheCheckpoint && (<div className={styles.actionItem} onClick={handleReload}><SyncOutlined /> {t('重试')}</div>)
              }
            </Space>
          ) : (
            <div className={styles.actionItem}>{t('影像上传中')}... {(progress * 100).toFixed(2)}%</div>
          )
        }
      </div>
      {
        status === 2 && (<span className={styles.tag}><CheckCircleFilled /> {t('上传成功')}</span>)
      }
      {
        status === 3 && (<span className={styles.tag}><CloseCircleFilled /> {t('上传失败')}</span>)
      }
    </>

  );
};

export default DicomUpload;
