/*
 * @Description: 讲稿演示-主要内容-手术记录
 * @Author: likaifeng
 * @Date: 2023-11-30 17:20:33
 * @LastEditTime: 2024-01-02 11:40:21
 * @LastEditors: likaifeng
 */
import { speechFilterMakeDataAtom } from '@/store/lecture';
import React from 'react';
import { useRecoilValue } from 'recoil';
import SpeechCard from '../SpeechCard';
import SpeechField from '../SpeechField';
import SpeechStep from '../SpeechStep';
import SpeechStepCard from '../SpeechStepCard';
import styles from './index.less';
import { useLocales } from '@/Locales';

/**
 * @description: 讲稿演示-主要内容-手术记录
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:28:55
 */
const SpeechSurgery: React.FC = () => {
  const speechFilterMakeData = useRecoilValue(speechFilterMakeDataAtom);
  const { t } = useLocales();

  return speechFilterMakeData?.surgicalRecord?.length ? (
    <SpeechCard titleId="surgicalRecord" title="手术记录">
      <div className={styles['speech-discharge']}>
        {speechFilterMakeData?.surgicalRecord?.map((item: any) => (
          <SpeechStep
            key={item.id}
            id={`surgicalRecord_${item.id}`}
            time={`${t('手术时间')}：${item.recordTime || '-'}`}
          >
            <SpeechStepCard
              padding="16px 16px 4px 16px"
              disabled={!!item.reportImage && !!item.reportImage.length}
            >
              <SpeechField
                title="报告图片"
                border={false}
                type="IMAGE"
                imagesSource={item.reportImage}
              />
            </SpeechStepCard>
            <SpeechStepCard
              disabled={!!item.surgeryName || !!item.procedures || !!item.seenDuringSurgery}
            >
              <SpeechField border title="手术名称" description={item.surgeryName} />
              <SpeechField border title="手术经过" description={item.procedures} />
              <SpeechField title="手术所见" description={item.seenDuringSurgery} />
            </SpeechStepCard>
          </SpeechStep>
        ))}
      </div>
    </SpeechCard>
  ) : null;
};

export default SpeechSurgery;
