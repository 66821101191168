import React from 'react';
import { useLocales } from '@/Locales';

const Text = (props: any) => {
  const { children } = props;
  const { t } = useLocales();
  return typeof children === 'string' ? t(children) : children;
};

export default Text;
