import React, { useState } from 'react';
import styles from './index.less';
import Image from '@/components/Image';
import { Space, Modal } from '@sinohealth/butterfly-ui-components';
import ImageZoom from '@/components/ImageZoom';

type CompareImageItemProps = {
  images: [string, string];
  title: string;
}
const CompareImageItem = (props: CompareImageItemProps) => {
  const { images = [], title } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Space className={styles.images}>
        <div className={styles.item}>
          <Image
            onClick={handleOpen}
            src={images[0]}
          />
        </div>
        <div className={styles.item}>
          <Image
            onClick={handleOpen}
            src={images[1]}
          />
        </div>
      </Space>
      <Modal title={`查看对比图 - ${title}`} open={open} width={1048} onCancel={handleClose} footer={false}>
        <div className={styles.imageModal}>
          <div className={styles.item}>
            <ImageZoom src={images[0]} />
          </div>
          <div className={styles.item}>
            <ImageZoom src={images[1]} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CompareImageItem;
