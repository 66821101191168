/* eslint-disable no-param-reassign */
/*
 * @Description: 讲稿演示-主要内容-病史摘要
 * @Author: likaifeng
 * @Date: 2023-11-30 16:27:15
 * @LastEditTime: 2024-05-28 19:25:11
 * @LastEditors: likaifeng
 */
import {
  speechFilterMakeDataAtom,
  speechMainDetailAtom,
  speechMainDetailTabAtom,
} from '@/store/lecture';
import React, { useLayoutEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import SpeechCard from '../SpeechCard';
import styles from './index.less';
import { useLocales } from '@/Locales';

/**
 * @description: 讲稿演示-主要内容-病史摘要
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:24:17
 */
const SpeechSummary: React.FC = () => {
  const speechFilterMakeData = useRecoilValue(speechFilterMakeDataAtom);
  const setSpeechMainDetail = useSetRecoilState(speechMainDetailAtom);
  const setSpeechMainDetailTab = useSetRecoilState(speechMainDetailTabAtom);
  const { t } = useLocales();

  /**
   * @description: 给a标签绑定事件
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-12-13 09:59:37
   */
  const handleClickPatientHistory = () => {
    const aDom: any = document.getElementsByTagName('a');
    if (aDom.length) {
      const len = aDom.length;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < len; i++) {
        aDom[i].onclick = function (e: any) {
          if (e.preventDefault) {
            e.preventDefault();
          } else {
            e.returnValue = false;
          }
          const { id, type } = e.target.dataset;
          const { hash, href } = e.target;
          e.target.className = 'hover-class';
          console.log(e.target.href);
          // console.log(type);
          // console.log(hash);
          if (id && type) {
            handleCalcMainDetail(id, type);
          } else if (hash) {
            const tabName = handleGetQueryVariable('type', hash);
            const ids = handleGetQueryVariable('id', hash);
            handleCalcMainDetail(ids, tabName);
          } else if (href) {
            window.open(href);
          }
        };
      }
    }
  };

  /**
   * @description: 根据hash值获取对应字段的值
   * @author: likaifeng
   * @param {*} name 字段名
   * @param {*} url 地址
   * @return {*}
   * @Date: 2023-12-13 10:06:23
   */
  const handleGetQueryVariable = (name: string, url: string) => {
    const reg = new RegExp(`(^|#)${name}=([^#]*)(#|$)`);
    const r = url.match(reg);

    if (r !== null) {
      return decodeURIComponent(r[2]);
    }
    return '';
  };

  /**
   * @description: 病史摘要获取关联讲稿的数据
   * @author: likaifeng
   * @param {string} ids 讲稿id
   * @param {string} tab 讲稿类型
   * @return {*}
   * @Date: 2023-12-04 14:42:45
   */
  const handleCalcMainDetail = (ids: string, tab: string) => {
    if (!ids || !tab) return;
    // console.log(ids, tab);
    const arr: any[] = Object.assign([], speechFilterMakeData[tab]).map((el: any) => ({ ...el }));

    const d = arr.filter((el: any) => Number(el.id) === Number(ids));

    if (d && d.length) {
      setSpeechMainDetail(d[0]);
    } else {
      // 先清空数据
      setSpeechMainDetail(null);
    }
    setSpeechMainDetailTab(tab);
  };

  useLayoutEffect(() => {
    handleClickPatientHistory();
  }, [speechFilterMakeData?.baseInfo?.history]);

  return (
    <SpeechCard id="patientHistory" title="病史摘要">
      {speechFilterMakeData?.baseInfo?.history ? (
        <div
          className={styles.simmary}
          dangerouslySetInnerHTML={{ __html: speechFilterMakeData?.baseInfo?.history }}
        />
      ) : (
        <div className={styles.simmary}>{t('未填写')}</div>
      )}
      <div style={{ height: 16 }} />
    </SpeechCard>
  );
};

export default SpeechSummary;
