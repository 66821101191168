/*
 * @Description: 讲稿演示-主要内容-参考文献
 * @Author: likaifeng
 * @Date: 2023-11-30 17:30:28
 * @LastEditTime: 2024-01-02 11:34:18
 * @LastEditors: likaifeng
 */
import { useLocales } from '@/Locales';
import { speechFilterMakeDataAtom } from '@/store/lecture';
import React from 'react';
import { useRecoilValue } from 'recoil';
import SpeechCard from '../SpeechCard';
import SpeechField from '../SpeechField';
import SpeechStep from '../SpeechStep';
import SpeechStepCard from '../SpeechStepCard';
import styles from './index.less';

/**
 * @description: 讲稿演示-主要内容-参考文献
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:26:32
 */
const SpeechOtherInfo: React.FC = () => {
  const speechFilterMakeData = useRecoilValue(speechFilterMakeDataAtom);
  const { t } = useLocales();

  return speechFilterMakeData?.otherInformation?.length ? (
    <SpeechCard titleId="otherInformation" title="参考文献">
      <div className={styles['speech-discharge']}>
        {speechFilterMakeData?.otherInformation?.map((item: any) => (
          <SpeechStep
            key={item.id}
            id={`otherInformation_${item.id}`}
            time={`${t('资料时间')}：${item.informationDate || '-'}`}
          >
            <SpeechStepCard
              padding="16px 16px 4px 16px"
              disabled={!!item.reportImage && !!item.reportImage.length}
            >
              <SpeechField
                title="报告图片"
                border={false}
                type="IMAGE"
                imagesSource={item.reportImage}
              />
            </SpeechStepCard>
            <SpeechStepCard disabled={!!item?.informationType || !!item?.description}>
              <SpeechField title="资料类型" border description={item?.informationType} />
              <SpeechField title="描述" border="false" description={item?.description} />
            </SpeechStepCard>
          </SpeechStep>
        ))}
      </div>
    </SpeechCard>
  ) : null;
};

export default SpeechOtherInfo;
