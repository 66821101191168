import Upload from '@/components/Upload';
import { baseURL } from '@/config/base';
import { useLocales } from '@/Locales';
import { apiPrefix } from '@/services';
import { ScanOutlined } from '@ant-design/icons';
import { Input, message } from '@sinohealth/butterfly-ui-components';
import styles from './index.less';

const options = [
  {
    label: '上传DICOM文件链接',
    value: 'link',
  },
  {
    label: '上传DICOM文件二维码',
    value: 'img',
  },
];
const DicomLink = (props: any) => {
  const { value, onChange, disabled } = props;
  const { t } = useLocales();

  const handleChange: any = (val: string) => {
    onChange && onChange(val);
  };

  const handleUploadSuccess = (info: any) => {
    const res = info.file.response.data;
    if (info.file.response.data) {
      message.success(t('二维码识别成功'));
      handleChange(res);
    } else {
      message.error(t('该二维码无法识别出链接，请重新上传'));
    }
  };

  const action = `${baseURL + apiPrefix}/cos/qrcode`;
  const uploadDom = (
    <Upload
      action={action}
      listType="text"
      accept="image/*"
      noStyle
      onUploadSuccess={handleUploadSuccess}
    >
      <a title="上传dicom二维码识别">
        {t('上传二维码识别')} <ScanOutlined />
      </a>
    </Upload>
  );
  return (
    <div className={styles.dicomLink}>
      <Input
        value={value}
        placeholder={t('请输入DICOM文件链接')}
        disabled={disabled}
        onChange={(e: any) => handleChange(e.target.value)}
        suffix={uploadDom}
        maxLength={2100}
      />
    </div>
  );
};

export default DicomLink;
