/* eslint-disable jsx-a11y/media-has-caption */
/*
 * @Description: 演讲稿页面-预览视频
 * @Author: likaifeng
 * @Date: 2023-11-30 19:34:39
 * @LastEditTime: 2023-12-05 11:37:06
 * @LastEditors: likaifeng
 */
import { Modal } from '@sinohealth/butterfly-ui-components';
import React, { useEffect, useState } from 'react';
import styles from './index.less';

/**
 * @description: 演讲稿页面-预览视频
 * @author: likaifeng
 * @param {*} props
 * @return {*}
 * @Date: 2023-12-04 14:29:17
 */
const SpeechVideoModel: React.FC<any> = (props) => {
  const { title, url, urlType, onClose } = props;

  const [dialogVisible, setDialogVisible] = useState(false);

  const handleClose = () => {
    setDialogVisible(false);
    onClose && onClose();
  };

  useEffect(() => {
    !dialogVisible && setDialogVisible(!!url);
  }, [url]);

  return (
    <Modal
      className={styles['video-model']}
      title={title}
      width="70%"
      open={dialogVisible}
      onCancel={handleClose}
      footer={null}
    >
      <div className={styles['speech-display']}>
        {urlType === 'IMAGE' ? <img src={url} alt="" /> : null}
        {urlType === 'VIDEO' ? <video className={styles.video} src={url} controls /> : null}
      </div>
    </Modal>
  );
};

export default SpeechVideoModel;
