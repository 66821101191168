const theme: any = {
  token: {
    colorPrimary: '#4288FF', // 主题色
  },
  components: {
    Menu: {
      itemActiveBg: 'red',
    },
  },
};

export default theme;
