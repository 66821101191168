/*
 * @Description: 活动分析
 * @Author: likaifeng
 * @Date: 2023-12-28 11:57:29
 * @LastEditTime: 2024-01-03 16:22:22
 * @LastEditors: likaifeng
 */
import usePermission from '@/hooks/usePermission';
import { Col, Row } from '@sinohealth/butterfly-ui-components';
import React from 'react';
import { useDepart, useJobTitle } from '../../hook';
import { defaultPieOption, defaultPieSeries } from '../../hook/config';
import { ImdtChartOption } from '../../hook/echartType';
import styles from '../../index.less';
import ImdtChart from '../ImdtChart';
import MdtTrends from '../MdtTrends';
import TeamMdtRank from '../TeamMdtRank';
import { useLocales } from '@/Locales';

/**
 * @description: 活动分析
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-28 11:57:56
 */
const ActivityAnalysis: React.FC = () => {
  const { departSource, loadingDepart } = useDepart();
  const { jobTitleSource, loadingJobTitle } = useJobTitle();
  const permission = usePermission('mdtStatistics');
  const { t } = useLocales();

  /** 科室人数占比 配置 */
  const options: ImdtChartOption = {
    ...defaultPieOption(),
    // 数据
    series: [
      {
        ...defaultPieSeries,
        data: departSource.map((el) => {
          return {
            name: el?.department_name,
            value: el?.mycount,
          };
        }),
      },
    ],
  };

  /** 医生职称占比 配置 */
  const titlesOptions: ImdtChartOption = {
    ...defaultPieOption(),
    // 数据
    series: [
      {
        ...defaultPieSeries,
        data: jobTitleSource?.map((el) => {
          return {
            value: el.mycount,
            name: el.job_title,
          };
        }),
      },
    ],
  };

  return (
    <>
      <div className={styles['statistics-header']}>
        <Row gutter={16}>
          <Col span={18}>
            <MdtTrends />
          </Col>
          <Col span={6}>
            <TeamMdtRank />
          </Col>
        </Row>
      </div>
      <div className={styles['statistics-footer']}>
        <Row gutter={16}>
          <Col span={12}>
            <ImdtChart
              loading={loadingDepart}
              height={400}
              backgroundImage
              title={t('科室人数占比')}
              option={options}
              empty={!departSource.length || !permission?.statisticalDepartment}
              permission={permission?.statisticalDepartment}
            />
          </Col>
          <Col span={12}>
            <ImdtChart
              loading={loadingJobTitle}
              height={400}
              backgroundImage
              title={t('医生职称占比')}
              option={titlesOptions}
              empty={!jobTitleSource.length || !permission?.statisticalJobTitle}
              permission={permission?.statisticalJobTitle}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ActivityAnalysis;
