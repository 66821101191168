import { useLocales } from '@/Locales';
import { uploadFile } from '@/services/common';
import { base64ToFile, getDownloadFileUrl } from '@/utils';
import { message, Modal, Spin } from '@sinohealth/butterfly-ui-components';
import { Editor as ImageEditor } from '@sinohealth/tiny-image-editor';
import React, { useImperativeHandle, useRef, useState } from 'react';
import styles from './index.less';

const menus = [
  'crop',
  'history',
  'download',
  'draw',
  'flip',
  'reset',
  'rotate',
  'rect',
  'circle',
  'text',
  // 'upload',
  'drag',
  'scale',
  'arrow',
  'mosaic',
  'undo',
  'redo',
];
const ImageEditorModal = (props: any, ref: any) => {
  const { title = '编辑图片' } = props;
  const [loading, setLoading] = useState(false); // 处理上传图片中
  const [open, setOpen] = useState(false);
  const [rootUrl, setRootUrl] = useState('');
  const editorRef = useRef<any>(null);
  const { locales } = useLocales();

  useImperativeHandle(ref, () => {
    return {
      openModal: handleOpen,
      closeModal: handleClose,
    };
  });

  const handleOpen = ({ src }: any) => {
    setRootUrl(src);
    setOpen(true);
    setLoading(false);
  };
  const handleClose = () => {
    setOpen(false);
    props?.onCancel && props?.onCancel(null);
  };
  const handleOK = async () => {
    setLoading(true);
    // 重置缩放
    await editorRef.current.resetRef.current.onResetZoom();
    // 重置拖拽
    await editorRef.current.resetDragRef.current.onResetDrag();
    editorRef.current.downloadRef.current
      .download()
      .then(({ downLoadUrl }: any) => {
        try {
          const file = base64ToFile(downLoadUrl, 'img.png');
          // console.log(downLoadUrl);
          const formData = new FormData();
          formData.append('file', file);
          uploadFile(formData)
            .then((res) => {
              setOpen(false);
              props.onOk && props.onOk(res, rootUrl);
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (e) {
          message.error('保存失败');
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      title={title}
      style={{ top: 30 }}
      destroyOnClose
      closable={false}
      maskClosable={false}
      keyboard={false}
      open={open}
      width={1100}
      onCancel={handleClose}
      onOk={handleOK}
      confirmLoading={loading}
    >
      <Spin spinning={loading}>
        <div className={styles.imageEditor}>
          {/* @ts-ignore */}
          <ImageEditor
            ref={editorRef}
            // @ts-ignore
            menus={menus}
            url={getDownloadFileUrl(rootUrl)}
            // @ts-ignore
            lang={locales === 'en' ? 'en' : 'zh'}
          />
        </div>
      </Spin>
    </Modal>
  );
};

export default React.forwardRef(ImageEditorModal);
