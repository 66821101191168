import React from 'react';
import { Empty as AndtEmpty } from '@sinohealth/butterfly-ui-components';
import styles from './index.less';

function Empty(props: any) {
  const { isSearch, className, style } = props;
  const iconClass = [styles.emptyIcon];
  if (isSearch) {
    iconClass.push(styles.search);
  }
  return <AndtEmpty className={className} style={style} />;
}
export default Empty;
