/*
 * @Description: MDT开展场次趋势
 * @Author: likaifeng
 * @Date: 2023-12-28 14:31:48
 * @LastEditTime: 2024-01-04 14:28:26
 * @LastEditors: likaifeng
 */
import usePermission from '@/hooks/usePermission';
import { Col, Row } from '@sinohealth/butterfly-ui-components';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTrend } from '../../hook';
import { defaultLineOption } from '../../hook/config';
import { ImdtChartOption } from '../../hook/echartType';
import FrequencyTop from '../FrequencyTop';
import ImdtChart from '../ImdtChart';
import ToolCard from '../ToolCard';
import { useLocales } from '@/Locales';

/**
 * @description: MDT开展场次趋势
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-28 14:32:19
 */
const MdtTrends: React.FC = () => {
  const prev = dayjs().subtract(364, 'day').format('YYYY-MM-DD');
  const next = dayjs().format('YYYY-MM-DD');
  const { t } = useLocales();

  const [defaultParams, setDefaultParams] = useState({
    agencyId: 0,
    startDate: prev,
    endDate: next,
  });

  const { trendSource, loadingTrend } = useTrend(defaultParams);
  const permission = usePermission('mdtStatistics');

  const options: ImdtChartOption = {
    ...defaultLineOption(),
    xAxis: {
      type: 'category',
      data: trendSource?.map((el) => el.month),
      axisLine: {
        show: true,
      },
      axisTick: {
        alignWithLabel: true,
      },
    },
    // 数据
    series: [
      {
        data: trendSource?.map((el) => el.total),
        type: 'line',
        lineStyle: {
          width: 2,
          color: '#76DDFB',
        },
        symbolSize: 5,
        itemStyle: {
          color: '#53A8E2',
          borderWidth: 1,
        },
        emphasis: {
          scale: 2.2,
        },
        selectedMode: 'series',
      },
    ],
  };

  return (
    <ToolCard
      agencyId={defaultParams.agencyId}
      pickerDate={[defaultParams.startDate, defaultParams.endDate]}
      agencyPermission={
        permission?.agencyDataListPage &&
        (permission?.statisticalTrend || permission?.statisticalDoctor)
      }
      permission={permission?.statisticalTrend || permission?.statisticalDoctor}
      onTime={(_, f) => {
        setDefaultParams({
          ...defaultParams,
          startDate: f[0],
          endDate: f[1],
        });
      }}
      onSelect={(v) => setDefaultParams({ ...defaultParams, agencyId: v })}
    >
      <Row>
        <Col span={17}>
          <ImdtChart
            loading={loadingTrend}
            title={t('MDT开展场次趋势')}
            titleBorder={false}
            height={370}
            option={options}
            empty={!trendSource.length || !permission?.statisticalTrend}
            permission={permission?.statisticalTrend}
          />
        </Col>
        <Col span={7}>
          <FrequencyTop
            agencyId={defaultParams?.agencyId}
            startDate={defaultParams?.startDate}
            endDate={defaultParams?.endDate}
          />
        </Col>
      </Row>
    </ToolCard>
  );
};

export default MdtTrends;
