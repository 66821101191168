import React from 'react';
import { Select } from '@sinohealth/butterfly-ui-components';
import useDictOptions from '@/hooks/dict/useDictOptions';

const TumorMarkersSelect = (props: any) => {
  const tumorMarker = useDictOptions('tumorMarker');
  return <Select placeholder="请选择指标" options={tumorMarker} {...props} />;
};

export default TumorMarkersSelect;
