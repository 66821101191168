import Empty from '@/components/Empty';
import { useLocales } from '@/Locales';
import { OcrContext } from '@/pages/patient/detail/components/PatientTabs/components/TabItem';
import { ocrImage } from '@/services/patient';
import { copyText } from '@/utils';
import { CloseOutlined } from '@ant-design/icons';
import { message, Space } from '@sinohealth/butterfly-ui-components';
import { useContext, useEffect, useState } from 'react';
import styles from './index.less';

const OcrInfo = (props: any) => {
  const { ocrConfig } = props;
  const { setOcrConfig } = useContext(OcrContext);
  const [ocrResult, setOcrResult] = useState([]);
  const [ocrResultText, setOcrResultText] = useState('');
  const { t } = useLocales();

  useEffect(() => {
    const img = ocrConfig.data;
    if (img) {
      ocrImage(img).then((res: any) => {
        const d = JSON.parse(res);
        setOcrResult(d.words_result || []);
        setOcrResultText(renderText(d.words_result));
      });
    }
  }, [ocrConfig]);

  const handleClose = () => {
    setOcrConfig({
      open: false,
    });
  };

  const handleCopy = (text: string) => {
    copyText(text);
    message.success(t('已复制'));
  };
  if (!ocrConfig.open) return null;

  const renderText = (d = []) => {
    return d.map((item: any) => item.words).join('\n');
  };
  return (
    <div id="ocrInfo" className={styles.ocrInfo}>
      <div className={styles.header}>
        <Space>
          OCR 图片识别结果
          <a onClick={() => handleCopy(ocrResultText)}>{t('复制全部')}</a>
        </Space>
        <CloseOutlined className={styles.closeBtn} onClick={handleClose} />
      </div>
      <div className={styles.body}>
        <div className={styles.res} style={{ height: props.height || 514 }}>
          <pre className={styles.item}>
            {ocrResultText}
            {/* <a onClick={() => handleCopy(ocrResultText)}>{t('点击复制')}</a> */}
          </pre>
          {ocrResult.length === 0 && <Empty style={{ marginTop: 100 }} />}
        </div>
        {/* <div className={styles.img}> */}
        {/*  <Image preview src={ocrConfig.data} /> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default OcrInfo;
