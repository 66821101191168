import React, { useEffect, useState } from 'react';
import { Modal } from '@sinohealth/butterfly-ui-components';
import { useSearchParams } from 'react-router-dom';
import { getDoctorInfo } from '@/services/doctor';
import BaseInfoCard from '@/pages/doctor/detail/components/BaseInfo';
import { useLocales } from '@/Locales';

const DoctorItem = (props: { doctorId: string}) => {
  const { doctorId } = props;
  const [open, setOpen] = useState(false);
  const [doctorInfo, setDoctorInfo] = useState<any>({});
  const [params] = useSearchParams();
  const type = params.get('type');
  const { t } = useLocales();

  useEffect(() => {
    getDoctorInfo(doctorId)
      .then((res) => {
        setDoctorInfo(res);
      });
  }, [doctorId]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <span>
      {doctorInfo.name}&nbsp;&nbsp;
      <a onClick={handleOpen}>{t('详情')}</a>
      <Modal open={open} title={t('主治医生资料')} footer={null} onCancel={handleClose}>
        <div style={{ maxHeight: 660, overflow: 'auto' }}>
          <BaseInfoCard disabled showHeader={false} showAccount={type === 'cl'} id={doctorInfo.id} />
        </div>
      </Modal>
    </span>
  );
};

export default DoctorItem;
